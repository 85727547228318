import "./profile.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getUser, resetUsers } from "../../app/features/user/userSlice";
import { resetCourses } from "../../app/features/course/courseSlice";
import { resetQuiz } from "../../app/features/quiz/quizSlice";
import { getReviews } from "../../app/features/review/reviewSlice";
import { fetchme } from "../../app/features/auth/authSlice";
import { formatEmail, userTitle } from "../../utils/formatName";
import { performance, userPerformance } from "../../constants/userPerformance";
import { checkBirthday } from "../../utils/checkBirthday";
import {
  FaPen,
  FaPlus,
  FaEllipsisV,
  FaArrowLeft,
  FaArrowRight,
  FaChevronDown,
  FaChevronUp,
  FaEnvelope,
  FaPhoneAlt,
  FaBirthdayCake,
  FaLocationArrow,
  FaMapMarkerAlt,
  FaCalendarAlt,
  FaChalkboardTeacher,
  FaLayerGroup,
  FaComments,
  FaRegComments,
  FaStar,
  FaRegStar,
  FaCircle,
  FaDoorClosed,
  FaDoorOpen,
  FaRegCheckCircle,
  FaCheckCircle,
} from "react-icons/fa";

import Flag from "../../components/Flag/Flag";
import Avatar from "../../components/Avatar/Avatar";
import Rating from "../../components/Rating/Rating";
import PublicIcon from "../../components/PublicIcon/PublicIcon";
import ReviewCard from "../../components/ReviewCard/ReviewCard";
import ExamsCard from "./ExamsCard/ExamsCard";
import RemoveUserModal from "./RemoveUserModal/RemoveUserModal";
import UpdatePhotoModal from "./UpdatePhotoModal/UpdatePhotoModal";
import TestimonialModal from "./TestimonialModal/TestimonialModal";

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { auth } = useSelector((state) => state.auth);
  const { user, exams } = useSelector((state) => state.users);
  const { reviews } = useSelector((state) => state.reviews);
  const [showExams, setShowExams] = useState(true); //initial state of the exams list
  const [showReviews, setShowReviews] = useState(false); //initial state of the reviews list
  const [currentPerformance, setCurrentPerformance] = useState({});

  let completed = 0;
  user?.firstname && completed++;
  user?.lastname && completed++;
  user?.email && completed++;
  user?.gender && completed++;
  user?.job && completed++;
  user?.company && completed++;
  user?.photo && completed++;
  user?.phone && completed++;
  user?.callcode && completed++;
  user?.address && completed++;
  user?.country && completed++;
  user?.birthday && completed++;

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(resetQuiz());
    dispatch(resetUsers());
    dispatch(resetCourses());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUser(id));
    dispatch(getReviews({ user: id, course: "" }));
  }, [id, dispatch]);

  useEffect(() => {
    if (auth?._id === user?._id) {
      dispatch(fetchme());
    }
  }, [auth?._id, user?._id, user?.photo, dispatch]);

  useEffect(() => {
    if (user && exams && reviews) {
      setCurrentPerformance(userPerformance(user, exams, reviews));
    }
  }, [user, exams, reviews]);

  return (
    <div className="container">
      <header>
        {auth && auth.isAdmin && (
          <button className="circle-btn" onClick={() => navigate("/dashboard")}>
            <FaArrowLeft />
          </button>
        )}
      </header>

      <div className="profile">
        <section className="profile-header">
          <div className="top">
            <div className="avatar">
              <Avatar
                source={user?.photo}
                first={user?.firstname}
                last={user?.lastname}
                border={true}
              />
              {user && auth && (auth.isAdmin || user._id === auth._id) && (
                <UpdatePhotoModal profile={user} />
              )}
              {user && auth && !user.isAdmin && (
                <span className="user-tag" title={currentPerformance.tag}>
                  {currentPerformance.icon}
                </span>
              )}
            </div>
            {user && auth && (
              <>
                <h1>
                  {`${user.firstname} ${user.lastname}`}{" "}
                  {user && user.isVerified && (
                    <FaRegCheckCircle color="blue" className="muted" />
                  )}
                </h1>
                {userTitle(user) && <p>{userTitle(user)}</p>}
              </>
            )}
          </div>

          {user && auth && (auth.isAdmin || user._id === auth._id) && (
            <p className={`profile-category-msg ${currentPerformance.style}`}>
              {currentPerformance.msg}
            </p>
          )}
        </section>

        <section className="profile-details">
          <div className="section-header">
            <h3>Contact info</h3>
            {(auth?.isAdmin || user?._id === auth?._id) && (
              <button
                className="circle-btn"
                onClick={() => navigate(`/editprofile/${id}`)}
                title="Edit info"
              >
                <FaPen />
              </button>
            )}
          </div>

          {user &&
            auth &&
            (auth.isAdmin ||
              auth._id === user._id ||
              (user.isPublic && user.isBirthDayPublic)) &&
            user.birthday &&
            checkBirthday(user.birthday) && (
              <p className="birthday-greeting">
                {checkBirthday(user.birthday).icon}
                {checkBirthday(user.birthday).msg}
              </p>
            )}

          <div className="detail-group">
            <FaEnvelope />
            {auth &&
              user &&
              (auth.isAdmin ||
                auth._id === user._id ||
                (user.isPublic && user.isEmailPublic)) &&
              user.email && (
                <span>
                  <Link className="link" to={`mailto:${user.email}`}>
                    {formatEmail(user.email, 24)}{" "}
                  </Link>
                </span>
              )}

            {auth &&
              user &&
              (auth.isAdmin || user._id === auth._id) &&
              user.email && (
                <PublicIcon isPublic={user.isPublic && user.isEmailPublic} />
              )}
          </div>

          <div className="detail-group">
            <FaPhoneAlt />
            {auth &&
              user &&
              (auth.isAdmin ||
                auth._id === user._id ||
                (user.isPublic && user.isPhonePublic)) &&
              user.callcode &&
              user.phone && (
                <span>
                  <Link
                    className="link"
                    to={`tel:${user.callcode}${user.phone}`}
                  >
                    {`${user.callcode}${user.phone}`}
                  </Link>
                </span>
              )}
            {auth &&
              user &&
              (auth.isAdmin || user._id === auth._id) &&
              user.callcode &&
              user.phone && (
                <PublicIcon isPublic={user.isPublic && user.isPhonePublic} />
              )}
          </div>

          <div className="detail-group">
            <FaBirthdayCake />
            {auth &&
              user &&
              (auth.isAdmin ||
                auth._id === user._id ||
                (user.isPublic && user.isBirthDayPublic)) &&
              user.birthday && (
                <span>{new Date(user.birthday).toDateString()}</span>
              )}
            {auth &&
              user &&
              (auth.isAdmin || user._id === auth._id) &&
              user.birthday && (
                <PublicIcon isPublic={user.isPublic && user.isBirthDayPublic} />
              )}
          </div>

          <div className="detail-group">
            <FaLocationArrow />
            {user && <span>{user.address}</span>}
          </div>

          <div className="detail-group">
            <FaMapMarkerAlt />
            {user && <span>{user.country}</span>}
            {user && user.country && <Flag location={user.country} />}
          </div>

          <div className="detail-group">
            <FaCalendarAlt />
            {user && (
              <span>{`Joined ${new Date(user.createdAt).toDateString()}`}</span>
            )}
          </div>

          {auth && user && (auth.isAdmin || user._id === auth._id) && (
            <div className="profile-progress">
              <p className="muted">
                {completed === 12 && <FaCheckCircle color="green" />}
                {`You completed ${(100 * (completed / 12)).toFixed(
                  0
                )}% of your profile details.`}
              </p>
              <progress max={12} value={completed}></progress>
            </div>
          )}
        </section>

        <section className="profile-about">
          <div className="section-header">
            <h3>About me</h3>
            {(auth?.isAdmin || user?._id === auth?._id) && (
              <button
                className="circle-btn"
                onClick={() => navigate(`/editprofile/${id}`)}
                title={user?.about ? "Edit info" : "Add info"}
              >
                {user?.about ? <FaPen /> : <FaPlus />}
              </button>
            )}
          </div>
          {user?.about && <p>{user.about}</p>}
        </section>

        <section className="profile-testimony">
          <div className="section-header">
            <h3>My testimony</h3>
            {user && auth && (auth.isAdmin || user._id === auth._id) && (
              <TestimonialModal user={user} />
            )}
          </div>
          {user?.testimonial && (
            <>
              <div className="testimony-rate">
                <Rating edit={false} count={5} value={user.testimonial.rate} />
                <small>
                  Reviewed on {new Date(user.testimonial.date).toLocaleString()}
                </small>
              </div>
              <p>{user.testimonial.text}</p>
            </>
          )}
        </section>

        <section className="profile-reviews">
          <div className="section-header">
            <h3>My reviews</h3>
            {reviews &&
              (reviews.length === 0 ? (
                <span className="muted">
                  <FaRegComments /> {reviews.length}
                </span>
              ) : (
                <span className="muted">
                  <FaComments /> {reviews.length}
                </span>
              ))}
            {reviews &&
              (reviews.length === 0 ? (
                <span className="muted">
                  <FaRegStar /> {reviews.length}
                </span>
              ) : (
                <span className="muted">
                  <FaStar />{" "}
                  {(
                    reviews.reduce((total, review) => total + review.rate, 0) /
                    reviews.length
                  ).toFixed(1)}
                </span>
              ))}
            {reviews && reviews.length > 0 ? (
              <button
                className="circle-btn"
                onClick={() => setShowReviews(!showReviews)}
              >
                {showReviews ? <FaChevronUp /> : <FaChevronDown />}
              </button>
            ) : auth && user && (auth.isAdmin || user._id === auth._id) ? (
              <button
                className="circle-btn"
                onClick={() => navigate("/courses")}
              >
                <FaPlus />
              </button>
            ) : (
              <button className="circle-btn">
                <FaEllipsisV />
              </button>
            )}
          </div>
          {reviews && reviews.length > 0
            ? showReviews &&
              reviews.map((review, idx) => (
                <ReviewCard review={review} user={true} key={idx} />
              ))
            : auth &&
              user &&
              (auth.isAdmin || user._id === auth._id) && (
                <Link to="/courses" className="link">
                  Watch courses and add reviews <FaArrowRight />
                </Link>
              )}
        </section>

        <section className="profile-courses">
          <div className="section-header">
            <h3>My courses</h3>

            <span className="muted">
              <FaChalkboardTeacher /> {user?.courses.length}
            </span>

            <span className="muted">
              <FaLayerGroup /> {user?.totalExams}
            </span>

            {exams && exams.length > 0 ? (
              <button
                className="circle-btn"
                onClick={() => setShowExams(!showExams)}
              >
                {showExams ? <FaChevronUp /> : <FaChevronDown />}
              </button>
            ) : auth && user && (auth.isAdmin || user._id === auth._id) ? (
              <button
                className="circle-btn"
                onClick={() => navigate("/courses")}
              >
                <FaPlus />
              </button>
            ) : (
              <button className="circle-btn">
                <FaEllipsisV />
              </button>
            )}
          </div>
          {user && exams && exams.length > 0
            ? showExams &&
              exams.map((course, idx) => (
                <ExamsCard key={idx} idx={idx} exams={course} />
              ))
            : auth &&
              user &&
              (auth.isAdmin || user._id === auth._id) && (
                <Link to="/courses" className="link">
                  Start learning a new course <FaArrowRight />
                </Link>
              )}
        </section>

        <section className="profile-status muted">
          <h3>Account status</h3>
          <p>
            We're updating our account management policies. Please ensure your
            email address is valid and active. Accounts with invalid or inactive
            email addresses may be deleted by admins. Additionally, inactive
            accounts for over a year may be deleted without notice. Remember,
            account deletion means loss of all quizzes and certifications!
          </p>
          <p>
            <span className="account-status-date">{`Created on ${new Date(
              user?.createdAt
            ).toLocaleString()}`}</span>
          </p>
          <p>
            <span className="account-status-date">{`Updated on ${new Date(
              user?.updatedAt
            ).toLocaleString()}`}</span>
          </p>
          <p>
            {user?.dateCertified ? (
              <span className="account-status-date">{`Certified on ${new Date(
                user.dateCertified
              ).toLocaleString()}`}</span>
            ) : (
              "Not certified yet!"
            )}
          </p>
          <p>
            {user?.dateLogin && (
              <span className="account-status-date">
                {`Logged In on ${new Date(user.dateLogin).toLocaleString()}`}
                <small>
                  {user.isLogin ? (
                    <FaCircle color="green" />
                  ) : (
                    <FaCircle color="lightgrey" />
                  )}
                </small>
              </span>
            )}
          </p>

          {auth?.isAdmin && (
            <p>
              {user?.dateLogout && (
                <span className="account-status-date">
                  {`Logged Out on ${new Date(
                    user.dateLogout
                  ).toLocaleString()}`}
                  <small>
                    {user.isLogin ? (
                      <FaDoorClosed color="lightgrey" />
                    ) : (
                      <FaDoorOpen color="blue" />
                    )}
                  </small>
                </span>
              )}
            </p>
          )}

          {auth?.isAdmin && <RemoveUserModal id={id} />}
        </section>

        <section className="profile-status muted">
          <h3>Performance categories</h3>
          {performance.map((item, idx) => (
            <p className="category-info" key={idx}>
              {item.icon}
              <strong>{`${item.tag}:`}</strong>
              <span>{item.info}</span>
            </p>
          ))}
        </section>
      </div>
    </div>
  );
};

export default Profile;
