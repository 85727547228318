import React from "react";
import { Link, useLocation } from "react-router-dom";

const AnalyticLink = ({ item }) => {
  const { pathname } = useLocation();
  return (
    <Link
      to={"/analytics" + item.path}
      className={pathname === "/analytics" + item.path ? "link active" : "link"}
    >
      {item.icon} {item.title}
    </Link>
  );
};

export default AnalyticLink;
