import "./reviewcard.css";
import { Link } from "react-router-dom";
import { FaTrashAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { deleteReview } from "../../app/features/review/reviewSlice";
import Rating from "../Rating/Rating";

const ReviewCard = ({ review, user }) => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state.auth);

  const handleDelete = (id) => {
    dispatch(deleteReview(id));
  };

  return (
    <div className="review">
      <h4>
        {user ? (
          <Link to={`/course/${review.course}`} className="link">
            {review.coursename}
          </Link>
        ) : review.userRemoved ? (
          review.username
        ) : (
          <Link to={`/profile/${review.user}`} className="link">
            {review.username}
          </Link>
        )}
      </h4>
      <div className="rate">
        <Rating count={5} edit={false} value={review.rate} />
        <small>{`Reviewed on ${new Date(
          review.updatedAt
        ).toDateString()}`}</small>
        <small className="delete-icon">
          {((auth && auth.isAdmin) || (auth && auth._id === review.user)) && (
            <FaTrashAlt onClick={() => handleDelete(review._id)} />
          )}
        </small>
      </div>
      {review.text && <p>{review.text}</p>}
    </div>
  );
};

export default ReviewCard;
