import { Link } from "react-router-dom";
import { FaCertificate, FaChalkboard } from "react-icons/fa";

const ExamsTable = ({ exams }) => {
  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>N°</th>
            <th>Date</th>
            <th>Time</th>
            <th>User</th>
            <th>Course</th>
            <th>Available</th>
            <th>%</th>
            <th>Certificate</th>
            <th>Expires</th>
          </tr>
        </thead>
        <tbody>
          {exams.map((exam, idx) => (
            <tr className={idx % 2 === 0 ? "even" : "odd"} key={idx}>
              <td className="icon">
                <strong>{idx + 1}</strong>
              </td>
              <td>{new Date(exam.createdAt).toLocaleDateString()}</td>
              <td>{new Date(exam.createdAt).toLocaleTimeString()}</td>
              <td>
                <Link to={`/profile/${exam.user}`} className="link">
                  {exam.username}
                </Link>
              </td>
              <td>
                <Link to={`/course/${exam.course}`} className="link">
                  {exam.coursename}
                </Link>
              </td>
              <td className="icon">
                {exam.courseRemoved ? (
                  <FaChalkboard color="red" />
                ) : (
                  <FaChalkboard color="green" />
                )}
              </td>
              <td>{`${Math.floor((100 * exam.score) / 20)}%`}</td>
              <td>
                {exam.isPass && (
                  <Link to={`/certificate/${exam._id}`} className="link">
                    <FaCertificate /> View certificate
                  </Link>
                )}
              </td>
              <td>
                {exam.isPass && new Date(exam.expiresAt).toLocaleString()}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ExamsTable;
