import "./analytics.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FaAngleLeft,
  FaAngleRight,
  FaFemale,
  FaHardHat,
  FaMale,
  FaMapMarkerAlt,
  FaUserPlus,
} from "react-icons/fa";
import { fetchUsersAnalytics } from "../../app/features/stats/statsSlice";
import { clearMessage } from "../../app/features/user/userSlice";
import { menuAnlytics } from "../../constants/menuData";
import ChartBarVerticalDual from "../../components/ChartBarVerticalDual/ChartBarVertivalDual";
import ChartBarHorizontalDual from "../../components/ChartBarHorizontalDual/ChartBarHorizontalDual";
import AnalyticLink from "./AnalyticLink";

const UsersAnalytics = () => {
  const dispatch = useDispatch();
  const initialYear = 2021;
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState(currentYear);
  const userMessage = useSelector((state) => state.users.message);
  const { usersPerJob, usersPerCountry, usersPerMonth, message } = useSelector(
    (state) => state.stats
  );

  useEffect(() => {
    if (userMessage) {
      setTimeout(() => {
        dispatch(clearMessage());
      }, 2000);
    }
  }, [userMessage, dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchUsersAnalytics(year));
  }, [dispatch, year]);

  return (
    <div className="container">
      <h1>Analytics</h1>
      <div className="analytics-links">
        {menuAnlytics.map((item, idx) => (
          <AnalyticLink item={item} key={idx} />
        ))}
      </div>
      {message && <div className="message">{message}</div>}

      <div className="buttons-navigation"></div>
      <section className="analytics">
        <div className="analytics-box">
          <div className="analytics-group">
            <div className="main-chart">
              <h2>
                <FaUserPlus /> Users count: <FaMale color="steelblue" />
                <FaFemale color="palevioletred" />
              </h2>
              <div className="chart-area">
                {usersPerMonth.map((element, idx) => (
                  <ChartBarVerticalDual
                    key={idx}
                    title={element.month}
                    value1={element.male}
                    value2={element.female}
                    max={Math.max(...usersPerMonth.map((el) => el.count))}
                  />
                ))}
              </div>
              <div className="chart-title">
                <span>{`Total users: ${usersPerMonth.reduce(
                  (total, month) => total + month.count,
                  0
                )}`}</span>
              </div>
            </div>
          </div>

          <div className="pages-nbrs">
            <button
              className="page-btn"
              disabled={year === initialYear}
              onClick={() =>
                setYear((prev) => (prev > initialYear ? prev - 1 : prev))
              }
            >
              <FaAngleLeft />
            </button>
            <strong>{year}</strong>
            <button
              className="page-btn"
              disabled={year === currentYear}
              onClick={() =>
                setYear((prev) => (prev < currentYear ? prev + 1 : prev))
              }
            >
              <FaAngleRight />
            </button>
          </div>
        </div>

        <div className="analytics-box">
          <h2>
            <FaHardHat /> Users per job functions:
          </h2>
          {usersPerJob.map((element, idx) => (
            <div className="analytics-element" key={idx}>
              <div className="analytics-name">{element.job}</div>
              <ChartBarHorizontalDual
                value1={element.female}
                value2={element.male}
                max={Math.max(...usersPerJob.map((el) => el.count))}
              />
            </div>
          ))}
        </div>

        <div className="analytics-box">
          <h2>
            <FaMapMarkerAlt /> Users per country location:
          </h2>
          {usersPerCountry.map((element, idx) => (
            <div className="analytics-element" key={idx}>
              <div className="analytics-name">
                {element.country.length > 24
                  ? `${element.country.slice(0, 24).trim()}...`
                  : element.country}
              </div>
              <ChartBarHorizontalDual
                variant="happy"
                value1={element.female}
                value2={element.male}
                max={Math.max(...usersPerCountry.map((el) => el.count))}
              />
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default UsersAnalytics;
