import "./support.css";
import { useState } from "react";
import { toPSI, toMMscfd, toInch } from "../../utils/unitConversion";
import { FaWind } from "react-icons/fa";

const Velocity = () => {
  const [rate, setRate] = useState("");
  const [rateUnit, setRateUnit] = useState("MMscfd");
  const [pressure, setPressure] = useState("");
  const [pressureUnit, setPressureUnit] = useState("psi");
  const [pipeID, setPipeID] = useState("");
  const [pipeIDUnit, setPipeIDUnit] = useState("inch");
  const [message, setMessage] = useState([]);
  const [variant, setVariant] = useState("");

  const estimatedGasRate = (e) => {
    e.preventDefault();
    let messages = [];
    if (!rate) messages.push("Gas flow rate is required.");
    if (!pressure) messages.push("Flowing pressure is required.");
    if (!pipeID) messages.push("Pipe ID is required.");

    if (messages.length > 0) {
      setVariant("msg-error w300");
      setMessage(messages);
    } else {
      if (Number(rate) < 0)
        messages.push("Gas flowrate value must be positive.");
      if (Number(pressure) < 0)
        messages.push("Flowing pressure value must be positive.");
      if (Number(pipeID) < 0) messages.push("Pipe ID value must be positive.");

      if (messages.length > 0) {
        setVariant("msg-warning w300");
        setMessage(messages);
      } else {
        const flowRate = Number(rate);
        const flowingPressure = Number(pressure);
        const ID = Number(pipeID);

        const velocity = (
          (32000 * toMMscfd(flowRate, rateUnit)) /
          (Math.pow(toInch(ID, pipeIDUnit), 2) *
            (toPSI(flowingPressure, pressureUnit) + 14.73))
        ).toFixed(1);

        if (velocity > 160) {
          setVariant("msg-warning w300");
          messages.push(
            `The recommended dry gas velocity limit is 160_ft/sec.`
          );
        } else {
          setVariant("msg-info w300");
        }
        messages.push(`The calculated dry gas velocity is ${velocity}_ft/sec.`);
        setMessage(messages);
      }
    }
  };

  return (
    <section id="velocity">
      <div className="container">
        <div className="section-title">
          <h1>
            <FaWind /> Dry Gas Velocity Calculator
          </h1>
        </div>
        <div className="service-card">
          <div className="service-image">
            <img src="../img/welltest.jpg" alt="well test" />
          </div>
          <div className="service-form">
            <form>
              <div className={variant}>
                {message.map((el, i) => (
                  <div className="message-div" key={i}>{`${i + 1}. ${el}`}</div>
                ))}
              </div>
              <div className="input-container">
                <div className="group-input">
                  <div className="div-input">
                    <input
                      type="number"
                      placeholder="Gas Flow Rate..."
                      onChange={(e) => setRate(e.target.value)}
                    />
                  </div>
                  <div className="div-input">
                    <input
                      type="number"
                      placeholder="Flowing Press..."
                      onChange={(e) => setPressure(e.target.value)}
                    />
                  </div>
                  <div className="div-input">
                    <input
                      type="number"
                      placeholder="Pipe ID..."
                      onChange={(e) => setPipeID(e.target.value)}
                    />
                  </div>
                </div>
                <div className="group-input">
                  <div className="div-input">
                    <select onChange={(e) => setRateUnit(e.target.value)}>
                      <option value="MMscfd">MMscfd</option>
                      <option value="Mm3d">Mm3d</option>
                    </select>
                  </div>
                  <div className="div-input">
                    <select onChange={(e) => setPressureUnit(e.target.value)}>
                      <option value="psi">psi</option>
                      <option value="bar">bar</option>
                      <option value="atm">atm</option>
                      <option value="Pa">Pa</option>
                      <option value="kg/cm2">kg/cm2</option>
                      <option value="mmHg">torr (mmHg)</option>
                    </select>
                  </div>
                  <div className="div-input">
                    <select onChange={(e) => setPipeIDUnit(e.target.value)}>
                      <option value="inch">inch</option>
                      <option value="foot">foot</option>
                      <option value="mm">mm</option>
                      <option value="cm">cm</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="button-container">
                <button className="btn" onClick={(e) => estimatedGasRate(e)}>
                  Calculate Gas Velocity <FaWind />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Velocity;
