import "./examsdetails.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getExams, resetExams } from "../../app/features/exam/examSlice";
import { resetQuiz } from "../../app/features/quiz/quizSlice";
import { months } from "../../constants/courseOptions";
import ExamRow from "./ExamRow/ExamRow";
import ExamProgressBar from "../../components/ExamProgressBar/ExamProgressBar";
import ChartBarVerticalDual from "../../components/ChartBarVerticalDual/ChartBarVertivalDual";
import {
  FaAngleLeft,
  FaAngleRight,
  FaArrowLeft,
  FaRegThumbsDown,
  FaRegThumbsUp,
} from "react-icons/fa";

const ExamsDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [year, setYear] = useState(new Date().getFullYear());
  const { user, course } = useParams(); //user id, course id
  const { exams, count, maximum, average, initialYear, currentYear, message } =
    useSelector((state) => state.exams);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(resetQuiz());
    dispatch(resetExams());
    dispatch(getExams({ user, course }));
  }, [dispatch, user, course]);

  useEffect(() => {
    setYear(currentYear);
  }, [currentYear]);

  return (
    <div className="container">
      <header>
        <button
          className="circle-btn"
          onClick={() => navigate(`/profile/${user}`)}
        >
          <FaArrowLeft />
        </button>
      </header>
      {message && message === "Not authorized." && (
        <div className="message">{message}</div>
      )}

      {exams.length > 0 && (
        <>
          <h2>
            {`${exams[0]?.coursename} ${
              exams[0]?.courseRemoved ? "(Course Removed)" : ""
            }`}
          </h2>
          <h3>
            {`1. Total exams: ${count} test${count > 1 ? "s" : ""} all  years`}
          </h3>
          <div className="scores-progress">
            <strong>Best score:</strong>
            <ExamProgressBar value={maximum} />
            <strong>Average:</strong>
            <ExamProgressBar value={average} />
          </div>

          <h3>{`2. Showing ${year} exams`}</h3>
          <div className="chart-container">
            <div className="main-chart">
              <span>
                <FaRegThumbsUp color="lightseagreen" />{" "}
                <FaRegThumbsDown color="tomato" />
              </span>
              <div className="chart-area">
                {months.map((month, idx) => (
                  <ChartBarVerticalDual
                    key={"pppp" + idx}
                    variant="primary"
                    title={month}
                    value1={
                      exams.filter(
                        (exam) =>
                          new Date(exam.createdAt).getFullYear() ===
                            Number(year) &&
                          new Date(exam.createdAt).getMonth() === idx
                      ).length -
                      exams.filter(
                        (exam) =>
                          new Date(exam.createdAt).getFullYear() ===
                            Number(year) &&
                          new Date(exam.createdAt).getMonth() === idx &&
                          exam.isPass
                      ).length
                    }
                    value2={
                      exams.filter(
                        (exam) =>
                          new Date(exam.createdAt).getFullYear() ===
                            Number(year) &&
                          new Date(exam.createdAt).getMonth() === idx &&
                          exam.isPass
                      ).length
                    }
                    max={Math.max(
                      ...months.map(
                        (month, idx) =>
                          exams.filter(
                            (exam) =>
                              new Date(exam.createdAt).getFullYear() ===
                                Number(year) &&
                              new Date(exam.createdAt).getMonth() === idx
                          ).length
                      )
                    )}
                  />
                ))}
              </div>
              <div className="chart-title">
                <span>{`Total exams: ${
                  exams.filter(
                    (exam) =>
                      new Date(exam.createdAt).getFullYear() === Number(year)
                  ).length
                } - Year: ${year}`}</span>
              </div>
            </div>
          </div>

          <div className="pages-nbrs">
            <button
              className="page-btn"
              disabled={year === initialYear}
              onClick={() =>
                setYear((prev) => (prev > initialYear ? prev - 1 : prev))
              }
            >
              <FaAngleLeft />
            </button>
            <strong>{year}</strong>
            <button
              className="page-btn"
              disabled={year === currentYear}
              onClick={() =>
                setYear((prev) => (prev < currentYear ? prev + 1 : prev))
              }
            >
              <FaAngleRight />
            </button>
          </div>

          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>N°</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Score %</th>
                  <th>Certificate</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {exams
                  .filter(
                    (exam) => new Date(exam.createdAt).getFullYear() === year
                  )
                  .map((exam, idx) => (
                    <ExamRow exam={exam} idx={idx} key={idx} />
                  ))}
              </tbody>
            </table>
          </div>

          <div className="course-links">
            <button
              className="primary-btn"
              disabled={exams[0].courseRemoved}
              onClick={() => navigate(`/course/${course}`)}
            >
              Review course
            </button>
            <button
              className="primary-btn"
              disabled={exams[0].courseRemoved}
              onClick={() => navigate(`/quiz/${course}`)}
            >
              Take a Quiz
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ExamsDetails;
