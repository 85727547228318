//function that return a formatted full name given first and last
export const fullName = (first, last) => {
  if (!first && !last) return "";
  if (!first) return last.toUpperCase();
  return `${first
    .split(" ")
    .filter((name) => name !== "")
    .map((name) => `${name[0].toUpperCase()}${name.slice(1).toLowerCase()}`)
    .join(" ")} ${last.toUpperCase()}`;
};

//function that capitalize the first letter of each word and removes extra spaces from a given string
export const capitalizeString = (string) => {
  if (!string) return "";
  return string
    .split(" ")
    .filter((word) => word !== "")
    .map((word) => `${word[0].toUpperCase()}${word.slice(1).toLowerCase()}`)
    .join(" ");
};

//fonctionne qui formatte un nom donné avec la premiere lettre majiscule
export const formatName = (name, max) => {
  const formattedName = name
    .trim()
    .split(" ")
    .filter((word) => word !== "")
    .map(
      (word) => word && `${word[0].toUpperCase()}${word.slice(1).toLowerCase()}`
    )
    .join(" ");
  if (formattedName.length > max)
    return formattedName.substring(0, max).trim() + "...";
  return formattedName;
};

//fonctionne qui formatte un nom donné avec la premiere lettre majiscule
export const formatFullName = (first, last, max) => {
  if (!first || !last) {
    return "";
  } else if (first && !last) {
    return formatName(first, max);
  } else if (last && !first) {
    return formatName(last, max);
  } else {
    return `${formatName(first, max - 4)} ${formatName(
      last,
      max - formatName(first, max - 4).length
    )}`;
  }
};

//function to limit the lenth of the email
export const formatEmail = (email, max) => {
  const emailArray = email.split("@");
  if (emailArray[0].length > max) {
    emailArray[0] = emailArray[0].substring(0, max) + "...";
    return emailArray.join("@");
  }
  return email;
};

// function to return the job title and company of a user
export const userTitle = (user) => {
  if (!user) return;
  if (!user.job && !user.company) return;
  if (user.job && user.company) return `${user.job} at ${user.company}`;
  if (user.job) return user.job;
  if (user.company) return user.company;
};
