const Options = ({ question, userAnswer, handleChange, isChecked }) => {
  switch (question.type) {
    case "radio":
      return (
        <>
          {question.options.map((opt, idx) => (
            <p className="question-option input" key={question._id + "-" + idx}>
              <input
                id={question._id + "-" + idx}
                name={question._id}
                type={question.type}
                value={opt.value}
                onChange={(evt) => handleChange(evt, idx)}
                disabled={isChecked}
              />
              <label htmlFor={question._id + "-" + idx}>{opt.label}</label>
            </p>
          ))}
        </>
      );
    case "text":
    case "number":
    case "checkbox":
      return (
        <>
          {question.options.map((opt, idx) => (
            <p className="question-option input" key={question._id + "-" + idx}>
              {question.type !== "checkbox" && opt.prefix && (
                <label htmlFor={question._id + "-" + idx}>{opt.prefix}</label>
              )}
              <input
                id={question._id + "-" + idx}
                type={question.type}
                onChange={(evt) => handleChange(evt, idx)}
                disabled={isChecked}
              />
              <label htmlFor={question._id + "-" + idx}>{opt.label}</label>
            </p>
          ))}
        </>
      );
    case "select":
      return (
        <>
          {question.options.map((opt, idx) => (
            <p className="question-option" key={question._id + "-" + idx}>
              {opt.prefix && (
                <label htmlFor={question._id + "-" + idx}>
                  {opt.prefix + " "}
                </label>
              )}
              <select
                id={question._id + "-" + idx}
                value={userAnswer[idx]}
                onChange={(evt) => handleChange(evt, idx)}
                disabled={isChecked}
              >
                <option value=""></option>
                {opt.select.map((el, i) => (
                  <option value={el} key={question._id + "-" + idx + "-" + i}>
                    {el}
                  </option>
                ))}
              </select>
              <label htmlFor={question._id + "-" + idx}>{opt.label}</label>
            </p>
          ))}
        </>
      );
    case "phrase":
      return (
        <p className="question-phrase">
          {question.options.map((opt, idx) => (
            <span key={question._id + "-" + idx}>
              {opt.prefix && (
                <label htmlFor={question._id + "-" + idx}>
                  {opt.prefix + " "}
                </label>
              )}
              <select
                id={question._id + "-" + idx}
                value={userAnswer[idx]}
                onChange={(evt) => handleChange(evt, idx)}
                disabled={isChecked}
              >
                <option value=""></option>
                {opt.select.map((el, i) => (
                  <option value={el} key={question._id + "-" + idx + "-" + i}>
                    {el}
                  </option>
                ))}
              </select>
              <label htmlFor={question._id + "-" + idx}>{opt.label}</label>
            </span>
          ))}
        </p>
      );
    default:
      break;
  }
};

export default Options;
