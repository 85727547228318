import "./dashcard.css";

const DashCard = ({ dash }) => {
  return (
    <div className="dash-card">
      <div className="dash-main">
        <div className="card-icon">{dash.icon}</div>
        <div className="card-info">
          <h3>
            {dash.value?.toLocaleString("en", {
              useGrouping: true,
            })}
          </h3>
          <p>{dash.text}</p>
        </div>
      </div>
      {dash.extra && (
        <div className="dash-extra">
          {dash.extra.map((extra, idx) => (
            <div key={idx} className="card-extra">
              <span>{extra.icon}</span>
              <span>
                {extra.value?.toLocaleString("en", {
                  useGrouping: true,
                })}
              </span>
              <span>{extra.text}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DashCard;
