import "./home.css";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { FaLayerGroup, FaAward, FaUsers } from "react-icons/fa";
import TestimonialCard from "./TestimonialCard/TestimonialCard";
import smilingMan from "../../resources/smiling_man.png";
import CourseCard from "./CourseCard/CourseCard";

const Home = () => {
  const navigate = useNavigate();
  const { auth } = useSelector((state) => state.auth);
  const { testimonies, totalUsers, totalTests, totalAwards, popularCourses } =
    useSelector((state) => state.stats);

  const formatCount = (count) => {
    if (count < 1000) return count;
    else if (count < 1000000) return `${Math.floor(count / 100) / 10}K+`;
    else return `${Math.floor(count / 100000) / 10}M+`;
  };

  const statsData = [
    { icon: <FaUsers />, value: totalUsers, text: "Students" },
    {
      icon: <FaLayerGroup />,
      value: totalTests,
      text: "Quizzes",
    },
    { icon: <FaAward />, value: totalAwards, text: "Certificates" },
  ];

  return (
    <div className="home-page">
      <section className="showcase">
        <img src="/img/showcase.jpeg" alt="showcase" loading="lazy" />
        <div className="text">
          <div>
            <h1>
              <strong>Learn</strong> Free, <strong>Earn</strong> Fast!
            </h1>
            <h2>Web Solutions Empowering Oil & Gas Professionals</h2>
            <p>
              No Cost, No Limits: <strong>{process.env.REACT_APP_NAME}</strong>{" "}
              eLearning, Your Gateway to Free Learning Experiences.
            </p>
            <button className="btn" onClick={() => navigate("/courses")}>
              Start Learning Now!
            </button>
            <div className="stats">
              {statsData.map((stat, idx) => (
                <div className="stat" key={idx}>
                  {stat.icon}
                  <h2>{formatCount(stat.value)}</h2>
                  <span>{stat.text}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="popular">
        <h2>Most Popular Courses</h2>
        <div className="popular-cards">
          {popularCourses.map((course, idx) => (
            <CourseCard course={course} key={idx} />
          ))}
        </div>
      </section>

      <section className="testimonies">
        <h2>Member Exclusive Benefits</h2>
        <p>
          Get certified from the comfort of your home! Stay relaxed and earn
          your certification.
        </p>
        <h3>Get the Inside Scoop: Hear stories from our Members!</h3>
        <br />

        <div className="testimonial-cards">
          {testimonies.length > 0 &&
            testimonies.map((user, idx) => (
              <TestimonialCard user={user} key={idx} />
            ))}
        </div>
      </section>

      <section className="courses">
        <h2>Courses Categories</h2>
        <div className="categories">
          <Link to="/courses" state={{ category: "HSE" }}>
            <img
              src="/img/hse.png"
              alt="health Safety and Environment"
              loading="lazy"
            />
          </Link>
          <Link to="/courses" state={{ category: "Operation" }}>
            <img
              src="/img/wt_ops.png"
              alt="Well Testing Operations"
              loading="lazy"
            />
          </Link>
          <Link to="/courses" state={{ category: "Equipment" }}>
            <img
              src="/img/swt_eqt.png"
              alt="Surface Well Testing Equipment"
              loading="lazy"
            />
          </Link>
        </div>
      </section>

      <section className="slogan">
        <div className="text">
          <h2>Unlock Your Success with Our Exclusive Features!</h2>
          <ul>
            <li>100% online access.</li>
            <li>24/7 technical support.</li>
            <li>Private personal dashboard.</li>
            <li>Same day electronic certificate.</li>
            <li>Unlimited trials on online quizzes.</li>
            <li>Free access to online training courses.</li>
          </ul>
          {!auth && (
            <button className="btn" onClick={() => navigate("/register")}>
              Join for Free
            </button>
          )}
        </div>

        <img src={smilingMan} alt="Student" />
      </section>
    </div>
  );
};

export default Home;
