import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

const initialState = {
  count: null,
  message: "",
  question: null,
  courses: [],
  questions: [],
  isLoading: false,
};

export const getQuestions = createAsyncThunk(
  "questions/fetch",
  async ({ page, limit, search }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const config = { headers: { authorization: token } };
      const response = await axios.get(
        `${API_URL}/api/questions?page=${page}&limit=${limit}&search=${
          search || ""
        }`,
        config
      );
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addQuestion = createAsyncThunk(
  "question/add",
  async (questionData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const config = { headers: { authorization: token } };
      const response = await axios.post(
        `${API_URL}/api/questions`,
        questionData,
        config
      );
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getQuestion = createAsyncThunk(
  "question/fetch",
  async (id, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const config = { headers: { authorization: token } };
      const response = await axios.get(
        `${API_URL}/api/questions/${id}`,
        config
      );
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateQuestion = createAsyncThunk(
  "question/update",
  async ({ id, questionData }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const config = { headers: { authorization: token } };
      const response = await axios.put(
        `${API_URL}/api/questions/${id}`,
        questionData,
        config
      );
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const removeQuestion = createAsyncThunk(
  "question/remove",
  async (id, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const config = { headers: { authorization: token } };
      const response = await axios.delete(
        `${API_URL}/api/questions/${id}`,
        config
      );
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const questionSlice = createSlice({
  name: "question",
  initialState,
  reducers: {
    resetQuestions: (state) => {
      state.message = "";
      state.question = null;
      state.questions = [];
      state.isLoading = false;
    },
    clearMessage: (state) => {
      state.message = "";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getQuestions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getQuestions.fulfilled, (state, action) => {
        state.count = action.payload.count;
        state.message = action.payload.message;
        state.questions = [...action.payload.questions];
        state.isLoading = false;
      })
      .addCase(getQuestions.rejected, (state, action) => {
        state.message = action.payload;
        state.isLoading = false;
      })
      .addCase(addQuestion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addQuestion.fulfilled, (state, action) => {
        state.message = action.payload.message;
        state.question = action.payload.question;
        state.isLoading = false;
      })
      .addCase(addQuestion.rejected, (state, action) => {
        state.message = action.payload;
        state.isLoading = false;
      })
      .addCase(getQuestion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getQuestion.fulfilled, (state, action) => {
        state.message = action.payload.message;
        state.question = action.payload.question;
        state.courses = action.payload.courses;
        state.isLoading = false;
      })
      .addCase(getQuestion.rejected, (state, action) => {
        state.message = action.payload;
        state.isLoading = false;
      })
      .addCase(updateQuestion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateQuestion.fulfilled, (state, action) => {
        state.message = action.payload.message;
        state.question = action.payload.question;
        state.isLoading = false;
      })
      .addCase(updateQuestion.rejected, (state, action) => {
        state.message = action.payload;
        state.isLoading = false;
      })
      .addCase(removeQuestion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(removeQuestion.fulfilled, (state, action) => {
        state.questions = [...state.questions].filter(
          (question) => question._id !== action.payload.id
        );
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(removeQuestion.rejected, (state, action) => {
        state.message = action.payload;
        state.isLoading = false;
      });
  },
});

export const { resetQuestions, clearMessage } = questionSlice.actions;
export default questionSlice.reducer;
