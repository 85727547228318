import "./support.css";
import { useState } from "react";
import { toDegC } from "../../utils/unitConversion";
import { FaTemperatureHigh } from "react-icons/fa";

const Gamma = () => {
  const [model, setModel] = useState("");
  const [serial, setSerial] = useState("");
  const [minAmbTemp, setMinAmbTemp] = useState("");
  const [minAmbTempUnit, setMinAmbTempUnit] = useState("C");
  const [maxAmbTemp, setMaxAmbTemp] = useState("");
  const [maxAmbTempUnit, setMaxAmbTempUnit] = useState("C");
  const [flowingTemp, setFlowingTemp] = useState("");
  const [flowingTempUnit, setFlowingTempUnit] = useState("C");
  const [message, setMessage] = useState([]);
  const [variant, setVariant] = useState("");

  const gammaDetector = (e) => {
    e.preventDefault();
    let messages = [];
    if (!model && !serial)
      messages.push(
        "Gamma detector serial number or model number is required."
      );
    if (!minAmbTemp) messages.push("Minimum ambiant temperature is required.");
    if (!maxAmbTemp) messages.push("Maximum ambiant temperature is required.");
    if (!flowingTemp)
      messages.push("Actual well flowing temperature is required.");
    if (messages.length > 0) {
      setVariant("msg-error w300");
      setMessage(messages);
    } else {
      const min = toDegC(Number.parseFloat(minAmbTemp), minAmbTempUnit);
      const max = toDegC(Number.parseFloat(maxAmbTemp), maxAmbTempUnit);
      const flow = toDegC(Number.parseFloat(flowingTemp), flowingTempUnit);
      let detector = {
        model: model,
        serial: Number.parseFloat(serial),
      };
      if (detector.serial < 1000 || detector.model === "769") {
        setModel("769");
        detector.model = "769";
        detector.deltaMin = 15;
        detector.deltaMax = 40;
        detector.Temp = [40, 60, 85];
        detector.minAmbTemp = 0;
        detector.maxAmbTemp = 65;
      }
      if (detector.serial >= 1000 || detector.model === "770") {
        setModel("770");
        detector.model = "770";
        detector.deltaMin = 15;
        detector.deltaMax = 70;
        detector.Temp = [40, 60, 85, 100];
        detector.minAmbTemp = -20;
        detector.maxAmbTemp = 85;
      }

      if (min > max)
        messages.push(
          "The minimum ambiant temperature must be less than or equal the maximum ambiant temperature !"
        );
      if (min < detector.minAmbTemp || min > detector.maxAmbTemp)
        messages.push(
          `Min Amb Temp = ${min.toFixed(
            1
          )}°C ! Minimum ambiant temperature must be in the range of the gamma detector Model ${
            detector.model
          } [${detector.minAmbTemp}°C / ${detector.maxAmbTemp}°C].`
        );
      if (max < detector.minAmbTemp || max > detector.maxAmbTemp)
        messages.push(
          `Max Amb Temp = ${max.toFixed(
            1
          )}°C ! Maximum ambiant temperature must be in the range of the gamma detector Model ${
            detector.model
          } [${detector.minAmbTemp}°C / ${detector.maxAmbTemp}°C].`
        );
      if (flow < -46 || flow > 121)
        messages.push(
          `Flowing Temp = ${flow.toFixed(
            1
          )}°C ! Flowing temperature must be within the specs. of the Vx multiphase flow meter [-46°C / 121°C] or [-50°F / 250°F].`
        );
      if (messages.length > 0) {
        setVariant("msg-warning w300");
        setMessage(messages);
      } else {
        const TsLow = max + (flow - max) / 5 + detector.deltaMin;
        const TsHigh = min + (flow - min) / 5 + detector.deltaMax;
        messages.push(
          `The calculated stabilization temperature range for this gamma detector Model ${
            detector.model
          } is: [${TsLow.toFixed(1)}°C / ${TsHigh.toFixed(1)}°C].`
        );
        let Ts = [];
        for (let i = 0; i < detector.Temp.length; i++) {
          if (detector.Temp[i] >= TsLow && detector.Temp[i] <= TsHigh) {
            Ts.push(detector.Temp[i]);
          }
        }
        if (Ts.length === 0) {
          for (let i = 0; i < detector.Temp.length; i++) {
            if (detector.Temp[i] > TsHigh) {
              Ts.push(detector.Temp[i]);
            }
          }
        }
        messages.push(
          `The recommended stabilization temperature for this gamma detector Model ${detector.model} is: ${Ts[0]}°C.`
        );
        setVariant("msg-info w300");
        setMessage(messages);
      }
    }
  };

  return (
    <section id="gamma">
      <div className="container">
        <div className="section-title">
          <h1>
            <FaTemperatureHigh /> Gamma Detector Stab. Temp. Selector
          </h1>
        </div>
        <div className="service-card">
          <div className="service-image">
            <img src="../img/detector.jpg" alt="gamma detector" />
          </div>
          <div className="service-form">
            <form>
              <div className={variant}>
                {message.map((el, i) => (
                  <div className="message-div" key={i}>{`${i + 1}. ${el}`}</div>
                ))}
              </div>
              <div className="input-container">
                <div className="group-input">
                  <div className="div-input">
                    <input
                      type="number"
                      placeholder="Serial Number"
                      onChange={(e) => setSerial(e.target.value)}
                    />
                  </div>
                  <div className="div-input">
                    <input
                      type="number"
                      placeholder="Minimum Amb. Temp."
                      onChange={(e) => setMinAmbTemp(e.target.value)}
                    />
                  </div>
                  <div className="div-input">
                    <input
                      type="number"
                      placeholder="Maximum Amb. Temp."
                      onChange={(e) => setMaxAmbTemp(e.target.value)}
                    />
                  </div>
                  <div className="div-input">
                    <input
                      type="number"
                      placeholder="Flowing Temp."
                      onChange={(e) => setFlowingTemp(e.target.value)}
                    />
                  </div>
                </div>
                <div className="group-input">
                  <div className="div-input">
                    <select
                      value={model}
                      onChange={(e) => setModel(e.target.value)}
                    >
                      <option value="">Model</option>
                      <option value="769">769</option>
                      <option value="770">770</option>
                    </select>
                  </div>
                  <div className="div-input">
                    <select onChange={(e) => setMinAmbTempUnit(e.target.value)}>
                      <option value="C">°C</option>
                      <option value="F">°F</option>
                      <option value="K">°K</option>
                    </select>
                  </div>
                  <div className="div-input">
                    <select onChange={(e) => setMaxAmbTempUnit(e.target.value)}>
                      <option value="C">°C</option>
                      <option value="F">°F</option>
                      <option value="K">°K</option>
                    </select>
                  </div>
                  <div className="div-input">
                    <select
                      onChange={(e) => setFlowingTempUnit(e.target.value)}
                    >
                      <option value="C">°C</option>
                      <option value="F">°F</option>
                      <option value="K">°K</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="button-container">
                <button className="btn" onClick={(e) => gammaDetector(e)}>
                  Simulate Temperature <FaTemperatureHigh />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Gamma;
