import "./pagination.css";
import { useState } from "react";
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaAngleLeft,
  FaAngleRight,
  FaEllipsisH,
} from "react-icons/fa";

const Pagination = ({ limit, count, page, paginate }) => {
  // how many number to display on the pagination bar
  const pageNumberLimit = 5;
  const [minPageNumberLimit, setminPageNumberLimit] = useState(1);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(pageNumberLimit);
  const totalPages = Math.ceil(count / limit);
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }
  // Go first page number 1
  const paginateFirst = () => {
    paginate(1);
    setminPageNumberLimit(1);
    setmaxPageNumberLimit(pageNumberLimit);
  };
  // Go previous page
  const paginatePrevious = () => {
    paginate(page - 1);
    if ((page - 1) % pageNumberLimit === 0) {
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
    }
  };
  // Go next page
  const paginateNext = () => {
    paginate(page + 1);
    if (page % pageNumberLimit === 0) {
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
    }
  };
  // Go to last page
  const paginateLast = () => {
    const maxLastPage =
      Math.ceil(totalPages / pageNumberLimit) * pageNumberLimit;
    paginate(totalPages);
    setminPageNumberLimit(maxLastPage - pageNumberLimit + 1);
    setmaxPageNumberLimit(maxLastPage);
  };

  return (
    <div className="pages-nbrs">
      <button
        className="page-btn"
        onClick={paginateFirst}
        disabled={page === 1}
      >
        <FaAngleDoubleLeft />
      </button>

      <button
        className="page-btn"
        onClick={paginatePrevious}
        disabled={page === 1}
      >
        <FaAngleLeft />
      </button>

      {minPageNumberLimit > 1 && (
        <button className="page-btn" onClick={paginatePrevious}>
          <FaEllipsisH />
        </button>
      )}

      {pageNumbers.map(
        (pageNumber, idx) =>
          pageNumber >= minPageNumberLimit &&
          pageNumber <= maxPageNumberLimit && (
            <button
              key={idx}
              onClick={() => paginate(pageNumber)}
              className={pageNumber === page ? "page-btn active" : "page-btn"}
            >
              {pageNumber}
            </button>
          )
      )}

      {maxPageNumberLimit < totalPages && (
        <button className="page-btn" onClick={paginateNext}>
          <FaEllipsisH />
        </button>
      )}

      <button
        className="page-btn"
        onClick={paginateNext}
        disabled={page === totalPages}
      >
        <FaAngleRight />
      </button>

      <button
        className="page-btn"
        onClick={paginateLast}
        disabled={page === totalPages}
      >
        <FaAngleDoubleRight />
      </button>
    </div>
  );
};

export default Pagination;
