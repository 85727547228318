import "./avatar.css";

//size sm, md,

const Avatar = ({ source, first, last, size, border }) => {
  const avatarBackground = (firstName, lastName) => {
    if (!firstName || !lastName) {
      return "#f5f5f5";
    }
    const red = Math.pow(firstName.toUpperCase().charCodeAt(0), 7) % 200;
    const green = Math.pow(lastName.toUpperCase().charCodeAt(0), 7) % 200;
    const blue = (10 * (red + green)) % 200;
    return `rgb(${red}, ${green}, ${blue})`;
  };

  let photoClass = "";
  let initialsCalss = "";

  switch (size) {
    case "sm":
      if (border) {
        photoClass = "photo small border";
        initialsCalss = "initials small border";
      } else {
        photoClass = "photo small";
        initialsCalss = "initials small";
      }
      break;
    case "md":
      if (border) {
        photoClass = "photo medium border";
        initialsCalss = "initials medium border";
      } else {
        photoClass = "photo medium";
        initialsCalss = "initials medium";
      }
      break;

    default:
      if (border) {
        photoClass = "photo border";
        initialsCalss = "initials border";
      } else {
        photoClass = "photo";
        initialsCalss = "initials";
      }
      break;
  }

  if (source && first && last)
    return (
      <img
        src={source}
        className={photoClass}
        alt={`${first[0]}${last[0]}`.toUpperCase()}
      />
    );

  return (
    <span
      style={{ backgroundColor: `${avatarBackground(first, last)}` }}
      className={initialsCalss}
    >
      {`${(first && first[0]) || ""}${(last && last[0]) || ""}`.toUpperCase()}
    </span>
  );
};

export default Avatar;
