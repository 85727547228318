import "./TestimonialCard.css";
import Avatar from "../../../components/Avatar/Avatar";
import Rating from "../../../components/Rating/Rating";
import { FaQuoteLeft } from "react-icons/fa";

const TestimonialCard = ({ user }) => {
  return (
    <div className="user-testimonial">
      <div className="user-avatar">
        <Avatar
          source={user.photo}
          first={user.firstname}
          last={user.lastname}
          size={"md"}
        />
      </div>
      <div className="user-review">
        <Rating count={5} edit={false} value={user.testimonial.rate} />
        <blockquote>
          <FaQuoteLeft />
          <p>{user.testimonial.text}</p>
          <cite>
            <span>{`- ${user.firstname} ${user.lastname}`}</span>
            {user.job && (
              <span className="muted">
                {`${user.job}${user.company && ` at ${user.company}`}`}
              </span>
            )}
            <span className="muted">{user.country}</span>
          </cite>
        </blockquote>
      </div>
    </div>
  );
};

export default TestimonialCard;
