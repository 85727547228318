import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

const initialState = {
  auth: null,
  reset: false,
  message: "",
  isLoading: false,
};

//Register user
export const registerUser = createAsyncThunk(
  "auth/register",
  async (user, thunkAPI) => {
    try {
      const response = await axios.post(`${API_URL}/api/auth/register`, user);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Verify user account
export const verifyUser = createAsyncThunk(
  "auth/verify",
  async ({ id, token }, thunkAPI) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/auth/verify/${id}/${token}`
      );
      if (response.data?.token) {
        localStorage.setItem("token", `Bearer ${response.data.token}`);
      }
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Verify user account
export const resendVerification = createAsyncThunk(
  "auth/verify-email",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/auth/verify-email/${id}`
      );
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Login user
export const loginUser = createAsyncThunk(
  "auth/login",
  async (user, thunkAPI) => {
    try {
      const response = await axios.post(`${API_URL}/api/auth/login`, user);
      if (response.data) {
        localStorage.setItem("token", `Bearer ${response.data.token}`);
      }
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Log out user
export const logoutUser = createAsyncThunk(
  "auth/logout",
  async (id, thunkAPI) => {
    try {
      localStorage.removeItem("token");
      const response = await axios.get(`${API_URL}/api/auth/logout/${id}`);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Forgot password
export const forgotPassword = createAsyncThunk(
  "auth/forgotpass",
  async (email, thunkAPI) => {
    try {
      const response = await axios.post(
        `${API_URL}/api/auth/forgotpass`,
        email
      );
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Reset password
export const resetPassword = createAsyncThunk(
  "auth/resetpass",
  async ({ resetToken, password }, thunkAPI) => {
    try {
      const response = await axios.put(
        `${API_URL}/api/auth/resetpass/${resetToken}`,
        password
      );
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Fetch current user
export const fetchme = createAsyncThunk("auth/fetchme", async (thunkAPI) => {
  try {
    const token = localStorage.getItem("token");
    const config = { headers: { authorization: token } };
    const response = await axios.get(`${API_URL}/api/auth/me`, config);
    return response.data;
  } catch (error) {
    localStorage.removeItem("token");
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuth: (state) => {
      state.auth = null;
      state.reset = false;
      state.message = "";
      state.isLoading = false;
    },
    clearMessage: (state) => {
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.auth = null;
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.auth = null;
        state.message = action.payload;
        state.isLoading = false;
      })
      .addCase(verifyUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyUser.fulfilled, (state, action) => {
        state.auth = action.payload.auth;
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(verifyUser.rejected, (state, action) => {
        state.auth = null;
        state.message = action.payload;
        state.isLoading = false;
      })
      .addCase(resendVerification.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resendVerification.fulfilled, (state, action) => {
        state.auth = null;
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(resendVerification.rejected, (state, action) => {
        state.auth = null;
        state.message = action.payload;
        state.isLoading = false;
      })
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.auth = action.payload.auth;
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.auth = null;
        state.message = action.payload;
        state.isLoading = false;
      })
      .addCase(forgotPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.auth = null;
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.auth = null;
        state.message = action.payload;
        state.isLoading = false;
      })
      .addCase(resetPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.auth = null;
        state.reset = true;
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.auth = null;
        state.message = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchme.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchme.fulfilled, (state, action) => {
        state.auth = action.payload.auth;
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(fetchme.rejected, (state, action) => {
        state.auth = null;
        state.message = action.payload;
        state.isLoading = false;
      })
      .addCase(logoutUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.auth = null;
        state.message = "";
        state.isLoading = false;
      });
  },
});

export const { resetAuth, clearMessage } = authSlice.actions;
export default authSlice.reducer;
