import "./questioncard.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { answer } from "../../app/features/quiz/quizSlice";
import { FaCheck, FaEdit } from "react-icons/fa";
import Options from "./Options";
import axios from "axios";
import { Link } from "react-router-dom";
const API_URL = process.env.REACT_APP_API_URL;

const QuestionCard = ({ question, i }) => {
  const dispatch = useDispatch();
  const size = question.options.length;
  const [variant, setVariant] = useState("");
  const [message, setMessage] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userAnswer, setUserAnswer] = useState([]);
  const { auth } = useSelector((state) => state.auth);

  useEffect(() => {
    setUserAnswer(
      question.type === "radio"
        ? [""]
        : new Array(size).fill(question.type === "checkbox" ? "false" : "")
    );
  }, [size, question.type]);

  const handleChange = (evt, idx) => {
    const { type, value, checked } = evt.target;
    const answer = [...userAnswer];
    if (type === "radio") {
      answer[0] = value;
    } else {
      answer[idx] = type === "checkbox" ? String(checked) : value;
    }
    setUserAnswer(answer);
  };

  const handleAnswer = async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem("token");
      const config = { headers: { authorization: token } };
      const res = await axios.post(
        `${API_URL}/api/quiz/${question._id}`,
        { userAnswer },
        config
      );
      setVariant(res.data.variant);
      setMessage(res.data.message);
      setIsChecked(res.data.isChecked);
      setIsLoading(false);
      dispatch(answer({ score: res.data.score }));
    } catch (error) {
      console.log(error.message);
      setIsLoading(false);
    }
  };

  return (
    <div className="question">
      <div className="question-header">
        <h3>{`Question ${i + 1}`}</h3>
        <span>
          {auth?.isAdmin && (
            <Link to={`/question/${question._id}`} className="link nav-link">
              Edit <FaEdit />
            </Link>
          )}
        </span>
      </div>
      <div className="question-body">
        {question.statement && (
          <p className="question-statement">{question.statement}</p>
        )}
        {question.image && <img src={question.image} alt={question._id} />}
        {question.extra && <p className="question-extra">{question.extra}</p>}
        {question.inputs.length > 0 && (
          <ul>
            {question.inputs.map((data, idx) => (
              <li key={question.id + "-input-" + idx}>{data}</li>
            ))}
          </ul>
        )}
        {question.note && <p className="muted">{question.note}</p>}
        {message && <p className={variant}>{message}</p>}
        {question.options.length > 0 && (
          <Options
            question={question}
            userAnswer={userAnswer}
            handleChange={handleChange}
            isChecked={isChecked}
          />
        )}
      </div>
      <div className="question-footer">
        <button
          onClick={handleAnswer}
          className={isLoading ? "primary-btn loading " : "primary-btn "}
          disabled={isChecked || isLoading || question._id === "new"}
        >
          {isLoading ? (
            "Checking..."
          ) : isChecked ? (
            <span>
              Checked <FaCheck />
            </span>
          ) : (
            "Check my answer!"
          )}
        </button>
      </div>
    </div>
  );
};

export default QuestionCard;
