import { useEffect } from "react";
import { FaComments, FaStar } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getReviews } from "../../app/features/review/reviewSlice";
import DashCard from "../../components/DashCard/DashCard";
import ReviewsTable from "./ReviewsTable/ReviewsTable";

const ReviewsList = () => {
  const dispatch = useDispatch();
  const { reviews, count, average, message } = useSelector(
    (state) => state.reviews
  );
  const dashCards = [
    { icon: <FaComments />, value: count, text: "Total Reviews" },
    { icon: <FaStar />, value: average?.toFixed(2), text: "Average Rate" },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getReviews({ user: "", course: "" }));
  }, [dispatch]);

  return (
    <div className="container">
      <h1>Reviews List</h1>
      {message && <div className="message">{message}</div>}

      <section className="dash-cards">
        {dashCards.map((dash, idx) => (
          <DashCard dash={dash} key={idx} />
        ))}
      </section>

      <section>
        {reviews && reviews.length > 0 && <ReviewsTable reviews={reviews} />}
      </section>
    </div>
  );
};

export default ReviewsList;
