import "./support.css";
import { Link } from "react-router-dom";
import { FaForward } from "react-icons/fa";
import { supportList } from "./supportList";

const Support = () => {
  return (
    <div className="container">
      <article className="support">
        {supportList.map((service, idx) => (
          <div className="support-card" key={idx}>
            <Link to={service.link}>
              <img src={service.photo} alt={service.title} />
            </Link>
            <h1>
              {service.icon} {service.title}
            </h1>
            <div className="card-description">
              <p>{service.description}</p>
              <Link to={service.link} className="link">
                {service.shortDesc}
                <FaForward className="icon" />
              </Link>
            </div>
          </div>
        ))}
      </article>
    </div>
  );
};

export default Support;
