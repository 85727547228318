import "./course.css";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  FaFilm,
  FaClock,
  FaGlobe,
  FaThLarge,
  FaArrowLeft,
  FaQuoteLeft,
  FaLayerGroup,
  FaCalendarDay,
  FaUsers,
} from "react-icons/fa";
import { resetUsers } from "../../app/features/user/userSlice";
import { getCourse, resetCourses } from "../../app/features/course/courseSlice";
import {
  addReview,
  getReviews,
  updateReview,
  clearMessage,
} from "../../app/features/review/reviewSlice";
import Rating from "../../components/Rating/Rating";
import ReviewCard from "../../components/ReviewCard/ReviewCard";
import { formatNumber, truncateToOneDecimal } from "../../utils/formatNumber";

const Course = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { auth } = useSelector((state) => state.auth);
  const { course } = useSelector((state) => state.courses);
  const { review, reviews, message } = useSelector((state) => state.reviews);
  const [text, setText] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(resetUsers());
    dispatch(resetCourses());
  }, [dispatch]);

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        dispatch(clearMessage());
      }, 2000);
    }
  }, [message, dispatch]);

  useEffect(() => {
    dispatch(getCourse(id));
  }, [id, reviews, dispatch]);

  useEffect(() => {
    dispatch(getReviews({ user: "", course: id }));
  }, [id, dispatch]);

  useEffect(() => {
    setText(review?.text || "");
  }, [review]);

  const handleNewRating = (rate) => {
    if (review) {
      dispatch(updateReview({ id: review._id, review: { rate } }));
    } else {
      dispatch(
        addReview({ rate, text, course: id, coursename: course?.title })
      );
    }
  };

  const handleNewText = (e) => {
    e.preventDefault();
    if (review) {
      dispatch(updateReview({ id: review._id, review: { text } }));
    } else {
      dispatch(addReview({ text, course: id, coursename: course?.title }));
    }
  };

  return (
    <div className="container">
      <header>
        <button className="circle-btn" onClick={() => navigate("/courses")}>
          <FaArrowLeft />
        </button>
      </header>

      {course && (
        <div className="course-details">
          <div className="video-container">
            <div className="video-wrapper">
              <iframe
                src={`https://www.youtube.com/embed/${course.youtube}?autoplay=0&rel=0`}
                title="Welcome to Kcs eLearning"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              ></iframe>
            </div>
          </div>

          <div className="top">
            <h1>
              {course.price.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </h1>
            <h2>{course.title}</h2>
            <p className="info">
              <span>
                <FaClock />
                {course.duration}
              </span>
              <span>
                <FaGlobe />
                {course.language}
              </span>
              <span>
                <FaThLarge />
                {course.category}
              </span>
              {course.isQuiz && (
                <span>
                  <FaLayerGroup />
                  Quiz
                </span>
              )}
            </p>
            <p className="description">{course.descr}</p>

            <p className="muted">
              <FaCalendarDay /> Last updated{" "}
              {new Date(course.updatedAt).toLocaleDateString()}
            </p>

            <h3>Skills you'll gain</h3>
            <p className="skills">
              {course.skills.map((skill, idx) => (
                <span key={idx}>{skill}</span>
              ))}
            </p>
          </div>

          {course.isQuiz && (
            <div className="middle">
              <div className="left">
                <img src="/img/LI-In-Bug.png" alt="LinkedIn" />
              </div>
              <div className="right">
                <p>
                  <strong>Shareable certificate</strong>
                </p>
                <p>
                  <small>Add to your LinkedIn profile</small>
                </p>
              </div>
            </div>
          )}

          <div className="bottom">
            {reviews.length > 0 && (
              <div className="right">
                <strong>
                  {course &&
                    course.totalReviews > 0 &&
                    truncateToOneDecimal(
                      course.totalRates / course.totalReviews
                    )}
                </strong>
                <span>
                  <Rating
                    count={5}
                    edit={false}
                    value={course.totalRates / course.totalReviews}
                  />
                </span>
                <small>
                  {`${formatNumber(course.totalReviews)} review${
                    course.totalReviews > 1 ? "s" : ""
                  }`}
                </small>
              </div>
            )}

            <div className="left">
              <Link
                to={`https://youtu.be/${course.youtube}`}
                className="link"
                target="_blank"
                rel="noreferrer"
              >
                <FaFilm />
              </Link>
            </div>
          </div>

          {reviews.length > 0 && (
            <div className="reviews-mapping">
              {[5, 4, 3, 2, 1].map((rate) => (
                <div className="course-review" key={rate}>
                  <strong>{`${rate} star${rate > 1 ? "s" : ""}`}</strong>
                  <progress
                    max={reviews.length}
                    value={
                      reviews.filter((review) => review.rate === rate).length
                    }
                  ></progress>
                  <span>
                    {`${(
                      (100 *
                        reviews.filter((review) => review.rate === rate)
                          .length) /
                      reviews.length
                    ).toFixed(2)}%`}
                  </span>
                </div>
              ))}
            </div>
          )}

          {course.users.length > 0 && (
            <div className="course-users">
              <FaUsers />
              <span>{`${course.users.length.toLocaleString("en", {
                useGrouping: true,
              })} student${course.users.length > 1 ? "s" : ""} ${
                course.users.length > 1 ? "have" : "has"
              } already taken this Quiz`}</span>
            </div>
          )}
        </div>
      )}

      {course?.isQuiz && (
        <button className="btn" onClick={() => navigate(`/quiz/${course._id}`)}>
          Take a Quiz
        </button>
      )}

      <section className="add-review">
        {auth && <h3>{`Hi ${auth.firstname} ${auth.lastname}`}</h3>}
        {review ? (
          <p>
            You can change your review by choosing a new stars rate and adding a
            new comment!
          </p>
        ) : (
          <p>
            Please add your stars rating and comment to this course. Thank you!
          </p>
        )}
        <br />
        <h3>{review ? "Edit your review" : "Add a review"}</h3>
        {message && <div className="message">{message}</div>}
        <form className="auth-form" onSubmit={handleNewText}>
          <div className="video-rating">
            <Rating
              count={5}
              edit={true}
              value={review?.rate}
              setNewRating={handleNewRating}
            />
          </div>
          <div className="input-group">
            <label htmlFor="text">
              <FaQuoteLeft />
            </label>
            <textarea
              id="text"
              name="text"
              placeholder="Write your review comment here..."
              rows="5"
              minLength={30}
              maxLength={300}
              required
              value={text}
              onChange={(e) => setText(e.target.value)}
            ></textarea>
          </div>

          <button
            type="submit"
            className="btn"
            disabled={
              text === review?.text ||
              text?.length > 300 ||
              text?.length < 30 ||
              !review
            }
          >
            Save my comment
          </button>
          <p className="user-message">
            <span>
              {text?.length > 0 && text?.length < 30
                ? `Please add at least ${30 - text?.length} caracter${
                    30 - text?.length > 1 ? "s" : ""
                  } to your comment text.`
                : text?.length > 300
                ? `Please remove at least ${text?.length - 300} caracter${
                    text?.length - 300 > 1 ? "s" : ""
                  } from your comment text. `
                : ""}
            </span>
            <span>
              {text?.length > 0 && !review && "Please add your Stars rating."}
            </span>
          </p>
        </form>
      </section>

      {reviews && reviews.filter((review) => review.text !== "").length > 0 && (
        <section className="course-reviews">
          <h3>Course reviews</h3>
          <p>Showing latest students comments</p>
          <br />
          {reviews
            .filter((review) => review.text !== "")
            .map((review, idx) => (
              <ReviewCard review={review} user={false} key={idx} />
            ))}
        </section>
      )}
    </div>
  );
};

export default Course;
