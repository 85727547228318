import "./navbar.css";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../app/features/auth/authSlice";
import { resetQuiz } from "../../app/features/quiz/quizSlice";
import { resetUsers } from "../../app/features/user/userSlice";
import { resetExams } from "../../app/features/exam/examSlice";
import { resetReviews } from "../../app/features/review/reviewSlice";
import { menuData } from "../../constants/menuData";
import { formatFullName } from "../../utils/formatName";
import {
  FaUser,
  FaSignInAlt,
  FaSignOutAlt,
  FaBars,
  FaTimes,
  FaCoins,
} from "react-icons/fa";
import Logo from "../Logo/Logo";
import Avatar from "../Avatar/Avatar";

const Navbar = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { auth } = useSelector((state) => state.auth);
  const { score, totalPoints } = useSelector((state) => state.quiz);
  const authLoading = useSelector((state) => state.auth.isLoading);
  const quizLoading = useSelector((state) => state.quiz.isLoading);
  const statsLoading = useSelector((state) => state.stats.isLoading);
  const usersLoading = useSelector((state) => state.users.isLoading);
  const examsLoading = useSelector((state) => state.exams.isLoading);
  const coursesLoading = useSelector((state) => state.courses.isLoading);
  const reviewsLoading = useSelector((state) => state.reviews.isLoading);
  const questionsLoading = useSelector((state) => state.questions.isLoading);
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(false);

  const handleLogout = () => {
    setOpen(false);
    setActive(false);
    dispatch(logoutUser(auth._id));
    dispatch(resetQuiz());
    dispatch(resetUsers());
    dispatch(resetExams());
    dispatch(resetReviews());
  };

  return (
    <nav>
      <div
        className={active || open ? "overlay open" : "overlay"}
        onClick={() => {
          setOpen(false);
          setActive(false);
        }}
      ></div>

      <div
        className={
          authLoading ||
          quizLoading ||
          statsLoading ||
          usersLoading ||
          examsLoading ||
          coursesLoading ||
          reviewsLoading ||
          questionsLoading
            ? "spinner-overlay open"
            : "spinner-overlay"
        }
      >
        <div className="spinner"></div>
      </div>

      <div className="navigation">
        <div className="left">
          <ul>
            <li className="toggle">
              <Link
                className="link nav-link"
                to="#"
                onClick={() => {
                  setOpen(false);
                  setActive(true);
                }}
              >
                <FaBars />
              </Link>
            </li>

            <li className="logo">
              <Link className="link nav-link" to="/">
                <Logo />
              </Link>
            </li>
          </ul>
          <ul className={active ? "main-menu active" : "main-menu"}>
            <li className="close">
              <Link
                className="link nav-link"
                to="#"
                onClick={() => setActive(false)}
              >
                <FaTimes />
              </Link>
            </li>
            {auth && (
              <li className="avatar">
                <Avatar
                  source={auth.photo}
                  first={auth.firstname}
                  last={auth.lastname}
                  border={true}
                />
                <span>{formatFullName(auth.firstname, auth.lastname, 20)}</span>
              </li>
            )}

            {menuData
              .filter((item) =>
                auth && auth.isAdmin
                  ? item
                  : auth
                  ? item.access === "private" || item.access === "public"
                  : item.access === "public"
              )
              .map((item, idx) => (
                <li
                  key={idx}
                  className={pathname === item.path ? "item active" : "item"}
                >
                  <Link
                    to={item.path}
                    className="link nav-link"
                    onClick={() => setActive(false)}
                  >
                    {item.icon} {item.title}
                  </Link>
                </li>
              ))}

            {auth ? (
              <li className={pathname === "#" ? "item active" : "item"}>
                <Link to="#" className="link nav-link" onClick={handleLogout}>
                  <FaSignOutAlt /> Log out
                </Link>
              </li>
            ) : (
              <>
                <li
                  className={pathname === "/register" ? "item active" : "item"}
                >
                  <Link
                    to="/register"
                    className="link nav-link"
                    onClick={() => setActive(false)}
                  >
                    <FaUser /> Sign up
                  </Link>
                </li>
                <li className={pathname === "/login" ? "item active" : "item"}>
                  <Link
                    to="/login"
                    className="link nav-link"
                    onClick={() => setActive(false)}
                  >
                    <FaSignInAlt /> Log in
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
        <div className="middle">
          {auth && totalPoints && totalPoints !== 0 && score && score !== 0 ? (
            <span>
              <FaCoins /> {`${Math.floor((100 * score) / totalPoints)}%`}
            </span>
          ) : (
            ""
          )}
        </div>
        <div className="right">
          {auth ? (
            <>
              {!auth.isVerified && (
                <Link to="/verification-error" className="verify-link">
                  Verify Account
                </Link>
              )}
              <Link
                to="#"
                title={`${auth.firstname} ${auth.lastname}`}
                className="link nav-link"
                onClick={() => {
                  setOpen(!open);
                  setActive(false);
                }}
              >
                <Avatar
                  source={auth.photo}
                  first={auth.firstname}
                  last={auth.lastname}
                  size={"sm"}
                  border={true}
                />
              </Link>
              <ul className={open ? "profile-menu open" : "profile-menu"}>
                <li>
                  <Link
                    to={`/profile/${auth._id}`}
                    className="link"
                    onClick={() => setOpen(false)}
                  >
                    <FaUser /> My Profile
                  </Link>
                </li>
                <li>
                  <Link to="#" onClick={handleLogout} className="link">
                    <FaSignOutAlt /> Log out
                  </Link>
                </li>
              </ul>
            </>
          ) : (
            <ul>
              <li>
                <Link to="/login" className="link nav-link login-btn">
                  Log in
                </Link>
              </li>
              <li>
                <Link to="/register" className="link nav-link register-btn">
                  Sign up
                </Link>
              </li>
            </ul>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
