import "./examprogressbar.css";

const ExamProgressBar = ({ value, max = 20 }) => {
  let color = "";

  if (value < 0.2 * max) color = "red";
  else if (value < 0.4 * max) color = "orangered";
  else if (value < 0.6 * max) color = "orange";
  else if (value < 0.8 * max) color = "yellowgreen";
  else color = "green";

  return (
    <div className="score-outer">
      <div
        className={`score-inner ${color}`}
        style={{ width: `${(100 * value) / max}%` }}
      >
        {value > 0 && `${Math.floor((100 * value) / max)}%`}
      </div>
    </div>
  );
};

export default ExamProgressBar;
