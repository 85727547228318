import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { fetchme } from "./app/features/auth/authSlice";
import { fetchstats } from "./app/features/stats/statsSlice";
import PrivateRoute from "./router/PrivateRoute";
import Navbar from "./components/Navbar/Navbar";
import Home from "./pages/Home/Home";
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import VerifyAccount from "./pages/Auth/VerifyAccount";
import Forgotpass from "./pages/Auth/Forgotpass";
import Resetpass from "./pages/Auth/Resetpass";
import Profile from "./pages/Profile/Profile";
import AddEditCourse from "./pages/AddEditCourse/AddEditCourse";
import Dashboard from "./pages/Dashboard/Dashboard";
import Course from "./pages/Course/Course";
import Contact from "./pages/Contact/Contact";
import CoursesList from "./pages/CoursesList/CoursesList";
import Footerbar from "./components/Footerbar/Footerbar";
import EditProfile from "./pages/EditProfile/EditProfile";
import ExamPaper from "./pages/ExamPaper/ExamPaper";
import ExamsDetails from "./pages/ExamsDetails/ExamsDetails";
import Certificate from "./pages/Certificate/Certificate";
import AddEditQuestion from "./pages/AddEditQuestion/AddEditQuestion";
import QuestionsList from "./pages/QuestionsList/QuestionsList";
import ReviewsList from "./pages/ReviewsList/ReviewsList";
import ExamsList from "./pages/ExamsList/ExamsList";
import UsersAnalytics from "./pages/Analytics/UsersAnalytics";
import ExamsAnalytics from "./pages/Analytics/ExamsAnalytics";
import Birthdays from "./pages/Analytics/Birtdays";
import Support from "./pages/Support/Support";
import Barium from "./pages/Support/Barium";
import Gamma from "./pages/Support/Gamma";
import H2S from "./pages/Support/H2S";
import TexasGasEst from "./pages/Support/TexasGasEst";
import Velocity from "./pages/Support/Velocity";
import About from "./pages/About/About";
import Verification from "./pages/Certificate/Verification";
import VerificationSent from "./pages/Auth/VerificationSent";
import VerificationError from "./pages/Auth/VerificationError";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchme());
    dispatch(fetchstats());
  }, [dispatch]);

  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/*" element={<Home />} />
        <Route exact path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route
          path="/verify-email/:userId/:verifyToken"
          element={<VerifyAccount />}
        />
        <Route
          path="/verification-error"
          element={<PrivateRoute children={<VerificationError />} />}
        />
        <Route path="/verification-sent" element={<VerificationSent />} />
        <Route path="/forgotpass" element={<Forgotpass />} />
        <Route path="/resetpass/:resetToken" element={<Resetpass />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/certificate/:id" element={<Certificate />} />
        <Route path="/verify/:id" element={<Verification />} />
        <Route
          path="/dashboard"
          element={<PrivateRoute children={<Dashboard />} />}
        />
        <Route
          path="/profile/:id"
          element={<PrivateRoute children={<Profile />} />}
        />
        <Route
          path="/editProfile/:id"
          element={<PrivateRoute children={<EditProfile />} />}
        />
        <Route
          exact
          path="/courses"
          element={<PrivateRoute children={<CoursesList />} />}
        />
        <Route
          path="/courses/add"
          element={<PrivateRoute children={<AddEditCourse />} />}
        />
        <Route
          path="/courses/edit/:id"
          element={<PrivateRoute children={<AddEditCourse />} />}
        />
        <Route
          path="/course/:id"
          element={<PrivateRoute children={<Course />} />}
        />
        <Route
          path="/quiz/:id"
          element={<PrivateRoute children={<ExamPaper />} />}
        />
        <Route
          exact
          path="/questions"
          element={<PrivateRoute children={<QuestionsList />} />}
        />
        <Route
          path="/question"
          element={<PrivateRoute children={<AddEditQuestion />} />}
        />
        <Route
          path="/question/:id"
          element={<PrivateRoute children={<AddEditQuestion />} />}
        />
        <Route
          exact
          path="/exams"
          element={<PrivateRoute children={<ExamsList />} />}
        />
        <Route
          path="/exams/:user/:course"
          element={<PrivateRoute children={<ExamsDetails />} />}
        />
        <Route
          exact
          path="/reviews"
          element={<PrivateRoute children={<ReviewsList />} />}
        />
        <Route
          path="/analytics/users"
          element={<PrivateRoute children={<UsersAnalytics />} />}
        />
        <Route
          path="/analytics/exams"
          element={<PrivateRoute children={<ExamsAnalytics />} />}
        />
        <Route
          path="/analytics/birthday"
          element={<PrivateRoute children={<Birthdays />} />}
        />
        <Route path="/support" element={<Support />} />
        <Route path="/barium" element={<Barium />} />
        <Route path="/gamma" element={<Gamma />} />
        <Route path="/h2spp" element={<H2S />} />
        <Route path="/texas" element={<TexasGasEst />} />
        <Route path="/velocity" element={<Velocity />} />
      </Routes>
      <Footerbar />
    </BrowserRouter>
  );
}

export default App;
