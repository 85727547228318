import { FaCakeCandles } from "react-icons/fa6";

export const checkBirthday = (dateString) => {
  const suffixes = ["th", "st", "nd", "rd"];
  const today = new Date();
  const birthday = new Date(dateString);
  // age calculation
  const age = today.getFullYear() - birthday.getFullYear();
  // ordinal number suffix
  const lastDigit = age % 10;
  const lastTwoDigits = age % 100;

  if (
    today.getMonth() === birthday.getMonth() &&
    today.getDate() === birthday.getDate()
  ) {
    return {
      msg: `Happy ${age}${
        lastTwoDigits >= 11 && lastTwoDigits <= 13
          ? "th"
          : suffixes[lastDigit] || "th"
      } Birthday!`,
      icon: <FaCakeCandles />,
    };
  }
  return;
};
