export const categories = ["Equipment", "Operation", "HSE"];

export const languages = ["French", "English"];

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const oneYear = 365 * 24 * 60 * 60 * 1000;

export const nintyDays = 90 * 24 * 60 * 60 * 1000;
