import "./dashboard.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetCourses } from "../../app/features/course/courseSlice";
import { fetchstats } from "../../app/features/stats/statsSlice";
import {
  getUsers,
  resetUsers,
  clearMessage,
} from "../../app/features/user/userSlice";
import {
  FaAward,
  FaCertificate,
  FaCircle,
  FaFemale,
  FaLayerGroup,
  FaMale,
  FaRegCheckCircle,
  FaSearch,
  FaStar,
  FaUserPlus,
  FaUsers,
} from "react-icons/fa";
import Rating from "../../components/Rating/Rating";
import DashCard from "../../components/DashCard/DashCard";
import Pagination from "../../components/Pagination/Pagination";
import UsersTable from "./UsersTable/UsersTable";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { auth } = useSelector((state) => state.auth);
  const { testimonies } = useSelector((state) => state.stats);
  const { users, message, count } = useSelector((state) => state.users);
  const {
    averageRate,
    totalRates,
    newPast24h,
    totalUsers,
    totalMen,
    totalWomen,
    totalLogin,
    totalVerified,
    totalTests,
    totalAwards,
    totalValid,
  } = useSelector((state) => state.stats);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const dashCards = [
    {
      icon: <FaUserPlus />,
      value: newPast24h,
      text: "New Last 24hrs",
      extra: [
        {
          icon: <FaCircle />,
          value: totalLogin,
          text: "Login",
        },
        {
          icon: <FaRegCheckCircle />,
          value: totalVerified,
          text: "Verified",
        },
      ],
    },
    {
      icon: <FaUsers />,
      value: totalUsers,
      text: "Total Students",
      extra: [
        { icon: <FaFemale />, value: totalWomen, text: "Female" },
        { icon: <FaMale />, value: totalMen, text: "Male" },
      ],
    },
    {
      icon: <FaLayerGroup />,
      value: totalTests,
      text: "Total Quizzes",
      extra: [
        { icon: <FaAward />, value: totalAwards, text: "Certificates" },
        {
          icon: <FaCertificate />,
          value: totalValid,
          text: "Valid",
        },
      ],
    },
    {
      icon: <FaStar />,
      value: averageRate?.toFixed(2),
      text: `${totalRates || ""} Total App. Reviews`,
    },
  ];
  const handleChange = (e) => {
    setPage(1);
    setSearch(e.target.value);
  };

  useEffect(() => {
    if (auth && !auth.isAdmin) {
      navigate("/");
    }
  }, [auth, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchstats());
    dispatch(resetUsers());
    dispatch(resetCourses());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUsers({ search, page, limit }));
  }, [dispatch, page, limit, search]);

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        dispatch(clearMessage());
      }, 2000);
    }
  }, [message, dispatch]);

  return (
    <div className="container">
      <h1>Dashboard</h1>

      <header>
        {auth && auth.isAdmin && (
          <button className="primary-btn" onClick={() => navigate("/register")}>
            <FaUserPlus /> Add new user
          </button>
        )}
      </header>

      <section className="dash-cards">
        {dashCards.map((dash, idx) => (
          <DashCard dash={dash} key={idx} />
        ))}
      </section>

      <section className="dash-search">
        <form className="auth-form">
          <div className="input-group">
            <label htmlFor="search">
              <FaSearch />
            </label>
            <input
              type="search"
              name="search"
              id="search"
              placeholder="Search users..."
              onChange={(e) => handleChange(e)}
            />
          </div>
        </form>
        {search && <p>Total users found: {count}</p>}
      </section>

      <section className="users">
        {users && users.length > 0 && (
          <>
            <UsersTable users={users} page={page} limit={limit} />

            <select
              name="limit"
              id="limit"
              value={limit}
              onChange={(e) => {
                setLimit(Number(e.target.value));
                setPage(1);
              }}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>

            {users && (
              <Pagination
                page={page}
                limit={limit}
                count={count}
                paginate={setPage}
              />
            )}
          </>
        )}
      </section>

      <section className="users-testimonies">
        <h2>Testimonies</h2>
        {testimonies &&
          testimonies.map((testimony, idx) => (
            <div key={idx}>
              <h3>{`${idx + 1} - ${testimony.firstname} ${
                testimony.lastname
              }`}</h3>
              <div className="user-testimony">
                <Rating
                  count={5}
                  edit={false}
                  value={testimony.testimonial.rate}
                />
                <small>
                  {new Date(
                    Date.parse(testimony.testimonial.date)
                  ).toLocaleString()}
                </small>
              </div>
              <p>{testimony.testimonial.text}</p>
              <small>{`${testimony.job ? testimony.job : ""} ${
                testimony.company ? `at ${testimony.company}` : ""
              }`}</small>
            </div>
          ))}
      </section>
    </div>
  );
};

export default Dashboard;
