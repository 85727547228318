import "./support.css";
import { useState } from "react";
import { toPSI, toDegF, toSG } from "../../utils/unitConversion";
import { FaWind } from "react-icons/fa";

const TexasGasEst = () => {
  const [ucp, setUCP] = useState("");
  const [ucpUnit, setUCPUnit] = useState("psi");
  const [uct, setUCT] = useState("");
  const [uctUnit, setUCTUnit] = useState("F");
  const [choke, setChoke] = useState("");
  const [gasSG, setGasSG] = useState("");
  const [sgUnit, setSGUnit] = useState("SG");
  const [message, setMessage] = useState([]);
  const [variant, setVariant] = useState("");

  const estimatedGasRate = (e) => {
    e.preventDefault();
    let messages = [];
    if (!choke) messages.push("Choke size (/64th) is required.");
    if (!ucp) messages.push("Upstream choke pressure is required.");
    if (!uct) messages.push("Upstream choke temperature is required.");
    if (!gasSG) messages.push("Gas specific gravity is required.");
    if (messages.length > 0) {
      setVariant("msg-error w300");
      setMessage(messages);
    } else {
      if (Number(choke) < 0)
        messages.push("Choke size (/64th) must be positive.");
      if (Number(choke) === 0)
        messages.push(
          "Choke size = 0/64th ! Do you mean that the choke is fully closed, hence no flow ?"
        );
      if (Number(ucp) < 0)
        messages.push("Upstream choke pressure must be positive.");
      if (Number(ucp) === 0)
        messages.push(
          "Upstream choke pressure = 0 psi ! Do you mean that the well is shut in or dead, hence no flow ?"
        );
      if (toSG(Number(gasSG), sgUnit) <= 0)
        messages.push(
          `Gas SG = ${toSG(
            Number(gasSG),
            sgUnit
          )} [air=1] ! Gas specific gravity [air=1] must be greater than zero.`
        );
      if (messages.length > 0) {
        setVariant("msg-warning w300");
        setMessage(messages);
      } else {
        const pressure = toPSI(Number(ucp), ucpUnit);
        const temperature = toDegF(Number(uct), uctUnit);
        const gravity = toSG(Number(gasSG), sgUnit);
        const texasEst = (
          (0.60537 *
            0.83 *
            Math.pow(Number(choke) / 64 / 2, 2) *
            Math.PI *
            pressure) /
          Math.sqrt(gravity * (temperature + 460))
        ).toFixed(6);
        messages.push(
          "Note - Calculation valid only if critical flow is achieved across choke."
        );
        messages.push(
          `The Texas A&I estimated gas rate through the choke = ${texasEst} MMscf/d.`
        );
        setVariant("msg-info w300");
        setMessage(messages);
      }
    }
  };

  return (
    <section id="texas">
      <div className="container">
        <div className="section-title">
          <h1>
            <FaWind /> Texas A&I Gas Estimate Calculator
          </h1>
        </div>
        <div className="service-card">
          <div className="service-image">
            <img src="../img/choke.jpg" alt="choke manifold" />
          </div>
          <div className="service-form">
            <form>
              <div className={variant}>
                {message.map((el, i) => (
                  <div className="message-div" key={i}>{`${i + 1}. ${el}`}</div>
                ))}
              </div>
              <div className="input-container">
                <div className="group-input">
                  <div className="div-input">
                    <input
                      type="number"
                      placeholder="Choke Size..."
                      onChange={(e) => setChoke(e.target.value)}
                    />
                  </div>
                  <div className="div-input">
                    <input
                      type="number"
                      placeholder="Upstream Choke Press..."
                      onChange={(e) => setUCP(e.target.value)}
                    />
                  </div>
                  <div className="div-input">
                    <input
                      type="number"
                      placeholder="Upstream Choke Temp..."
                      onChange={(e) => setUCT(e.target.value)}
                    />
                  </div>
                  <div className="div-input">
                    <input
                      type="number"
                      placeholder="Gas Specific Gravity..."
                      onChange={(e) => setGasSG(e.target.value)}
                    />
                  </div>
                </div>
                <div className="group-input">
                  <div className="div-input">
                    <select>
                      <option>/64th</option>
                    </select>
                  </div>
                  <div className="div-input">
                    <select onChange={(e) => setUCPUnit(e.target.value)}>
                      <option value="psi">psi</option>
                      <option value="bar">bar</option>
                      <option value="atm">atm</option>
                      <option value="Pa">Pa</option>
                      <option value="kg/cm2">kg/cm2</option>
                      <option value="mmHg">torr (mmHg)</option>
                    </select>
                  </div>
                  <div className="div-input">
                    <select onChange={(e) => setUCTUnit(e.target.value)}>
                      <option value="F">°F</option>
                      <option value="C">°C</option>
                      <option value="K">°K</option>
                    </select>
                  </div>
                  <div className="div-input">
                    <select onChange={(e) => setSGUnit(e.target.value)}>
                      <option value="SG">SG [air=1]</option>
                      <option value="API">°API</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="button-container">
                <button className="btn" onClick={(e) => estimatedGasRate(e)}>
                  Estimate Gas Rate <FaWind />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TexasGasEst;
