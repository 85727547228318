import "./auth.css";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  clearMessage,
  forgotPassword,
} from "../../app/features/auth/authSlice";
import { FaEnvelope } from "react-icons/fa";

const Forgotpass = () => {
  const dispatch = useDispatch();
  const { message } = useSelector((state) => state.auth);
  const [email, setEmail] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(clearMessage());
  }, [dispatch]);

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    dispatch(forgotPassword({ email }));
  };

  return (
    <div className="auth-container">
      <form className="auth-form" onSubmit={handleForgotPassword}>
        <h2>Forgot your password?</h2>
        <p>
          Enter your email address and we will send you a reset link to set up a
          new password.
        </p>
        {message && <div className="message">{message}</div>}
        <div className="input-group">
          <label htmlFor="email">
            <FaEnvelope />
          </label>
          <input
            type="email"
            id="email"
            name="email"
            required
            placeholder="Enter your email address..."
            value={email}
            onChange={(e) => setEmail(e.target.value.toLowerCase())}
          />
        </div>
        <button type="submit" className="btn">
          Send reset link
        </button>
        <p>
          <Link className="link" to="/login">
            Back to Log in...
          </Link>
        </p>
        <p>
          <Link className="link" to="/register">
            Dont have an account? create account...
          </Link>
        </p>
      </form>
    </div>
  );
};

export default Forgotpass;
