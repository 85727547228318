import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

const initialState = {
  review: null,
  count: null,
  average: null,
  reviews: [],
  message: "",
  isLoading: false,
};

// Fetch reviews
export const getReviews = createAsyncThunk(
  "reviews/fetch",
  async ({ user, course }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const config = { headers: { authorization: token } };
      const response = await axios.get(
        `${API_URL}/api/reviews?user=${user}&course=${course}`,
        config
      );
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addReview = createAsyncThunk(
  "review/add",
  async (review, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const config = { headers: { authorization: token } };
      const response = await axios.post(
        `${API_URL}/api/reviews`,
        review,
        config
      );
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateReview = createAsyncThunk(
  "review/update",
  async ({ id, review }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const config = { headers: { authorization: token } };
      const response = await axios.put(
        `${API_URL}/api/reviews/${id}`,
        review,
        config
      );
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteReview = createAsyncThunk(
  "review/delete",
  async (id, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const config = { headers: { authorization: token } };
      const response = await axios.delete(
        `${API_URL}/api/reviews/${id}`,
        config
      );
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const reviewSlice = createSlice({
  name: "review",
  initialState,
  reducers: {
    resetReviews: (state) => {
      state.review = null;
      state.reviews = [];
      state.message = "";
      state.isLoading = false;
    },
    clearMessage: (state) => {
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReviews.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getReviews.fulfilled, (state, action) => {
        state.review = action.payload.review || null;
        state.count = action.payload.count;
        state.average = action.payload.average;
        state.reviews = [...action.payload.reviews];
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(getReviews.rejected, (state, action) => {
        state.review = null;
        state.reviews = [];
        state.message = action.payload;
        state.isLoading = false;
      })
      .addCase(addReview.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addReview.fulfilled, (state, action) => {
        state.review = action.payload.review;
        state.reviews = [action.payload.review, ...state.reviews];
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(addReview.rejected, (state, action) => {
        state.review = null;
        state.reviews = [];
        state.message = action.payload;
        state.isLoading = false;
      })
      .addCase(updateReview.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateReview.fulfilled, (state, action) => {
        state.review = action.payload.review;
        state.reviews = [
          action.payload.review,
          ...state.reviews.filter(
            (review) => review._id !== action.payload.review._id
          ),
        ];
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(updateReview.rejected, (state, action) => {
        state.review = null;
        state.reviews = [];
        state.message = action.payload;
        state.isLoading = false;
      })
      .addCase(deleteReview.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteReview.fulfilled, (state, action) => {
        state.review = null;
        state.reviews = state.reviews.filter(
          (review) => review._id !== action.payload.id
        );
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(deleteReview.rejected, (state, action) => {
        state.review = null;
        state.reviews = [];
        state.message = action.payload;
        state.isLoading = false;
      });
  },
});

export const { resetReviews, clearMessage } = reviewSlice.actions;
export default reviewSlice.reducer;
