import { useEffect } from "react";
import { FaAward, FaLayerGroup, FaPercentage } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getExams } from "../../app/features/exam/examSlice";
import DashCard from "../../components/DashCard/DashCard";
import ExamsTable from "./ExamsTable/ExamsTable";

const ExamsList = () => {
  const dispatch = useDispatch();
  const { exams, count, passed, message } = useSelector((state) => state.exams);
  const dashCards = [
    { icon: <FaLayerGroup />, value: count, text: "Total Exams" },
    { icon: <FaAward />, value: passed, text: "Passed Quiz" },
    {
      icon: <FaPercentage />,
      value: passed && count ? `${((100 * passed) / count).toFixed(2)}%` : "",
      text: "Success Rate",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getExams({ user: "", course: "" }));
  }, [dispatch]);

  return (
    <div className="container">
      <h1>Exams List</h1>

      {message && <div className="message">{message}</div>}

      <section className="dash-cards">
        {dashCards.map((dash, idx) => (
          <DashCard dash={dash} key={idx} />
        ))}
      </section>

      <section>
        {exams && exams.length > 0 && <ExamsTable exams={exams} />}
      </section>
    </div>
  );
};

export default ExamsList;
