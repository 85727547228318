import React from "react";

const ExamHerader = ({ auth, score, totalPoints }) => {
  const formatName = (name) => {
    const arr = name.toUpperCase().split(""); //name converted to array of capital letters
    let arr2 = [];
    let size = 10;
    if (window.screen.width <= 280) {
      size = 9;
    } else if (window.screen.width <= 320) {
      size = 11;
    } else if (window.screen.width <= 375) {
      size = 12;
    } else if (window.screen.width <= 414) {
      size = 14;
    } else {
      size = 20;
    }
    for (let i = 0; i < size; i++) arr[i] ? arr2.push(arr[i]) : arr2.push("");
    return arr2;
  };

  let firstName;
  let lastName;
  if (auth) {
    firstName = formatName(auth.firstname); //firstname converted to array of capital letters of size 13
    lastName = formatName(auth.lastname); //lastname converted to array of capital letters of size 13
  }

  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0").split(""); //day converted to array of digits
  const mm = String(today.getMonth() + 1)
    .padStart(2, "0")
    .split(""); //month converted to array of digits
  const yyyy = String(today.getFullYear()).split(""); //year converted to array of digits

  return (
    <div className="flex-box">
      <div>
        <div>
          <div>
            <strong>Exam Date:</strong>{" "}
            <small className="user-guidance">
              write the actual exam date in clear numbers.
            </small>
          </div>
          <div className="flex-name">
            {dd.map((el, i) => (
              <div className="letter-box" key={i}>
                {el}
              </div>
            ))}
            <div className="slash-box">
              <b>/</b>
            </div>
            {mm.map((el, i) => (
              <div className="letter-box" key={i}>
                {el}
              </div>
            ))}
            <div className="slash-box">
              <b>/</b>
            </div>
            {yyyy.map((el, i) => (
              <div className="letter-box" key={i}>
                {el}
              </div>
            ))}
          </div>
        </div>
        <div>
          <div>
            <strong>First Name:</strong>{" "}
            <small className="user-guidance">
              write your first name in clear capital letters.
            </small>
          </div>
          <div className="flex-name">
            {firstName?.map((el, i) => (
              <div className="letter-box" key={i}>
                {el}
              </div>
            ))}
          </div>
        </div>
        <div>
          <div>
            <strong>Last Name:</strong>{" "}
            <small className="user-guidance">
              write your last name in clear capital letters.
            </small>
          </div>
          <div className="flex-name">
            {lastName?.map((el, i) => (
              <div className="letter-box" key={i}>
                {el}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="scores">
        <div className="score">
          <div className="title">Score:</div>
          <div className="score-nr">{`${((20 * score) / totalPoints).toFixed(
            2
          )}/20`}</div>
        </div>
        <div className="score">
          <div className="title">Percentage:</div>
          <div className="score-nr">
            {totalPoints > 0
              ? `${Math.floor((100 * score) / totalPoints)}%`
              : "0%"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExamHerader;
