import "./chartbarverticaldual.css";

const ChartBarVerticalDual = ({ title, value1, value2, max, variant }) => {
  //variant primary
  return (
    <div className="chart-bar">
      <div className="count-bar">
        <div
          className={`count-bar-inner top ${variant || ""}`}
          style={{
            height: `${max > 0 ? (100 * value1) / max : 0}%`,
          }}
        >
          <span>{value1 > 0 && value1}</span>
        </div>
        <div
          className={`count-bar-inner bottom ${variant || ""}`}
          style={{
            height: `${max > 0 ? (100 * value2) / max : 0}%`,
          }}
        >
          <span>{value2 > 0 && value2}</span>
        </div>
      </div>
      <div className="count-title">{title}</div>
    </div>
  );
};

export default ChartBarVerticalDual;
