import "./analytics.css";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { FaBirthdayCake } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { fetchBirthdays } from "../../app/features/stats/statsSlice";
import { happyBirthday, clearMessage } from "../../app/features/user/userSlice";
import { menuAnlytics } from "../../constants/menuData";
import AnalyticLink from "./AnalyticLink";

const Birthdays = () => {
  const dispatch = useDispatch();
  const userMessage = useSelector((state) => state.users.message);
  const { celebratingBirthday, message } = useSelector((state) => state.stats);

  useEffect(() => {
    if (userMessage) {
      setTimeout(() => {
        dispatch(clearMessage());
      }, 2000);
    }
  }, [userMessage, dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchBirthdays());
  }, [dispatch]);

  const handleHappyBirthday = (id) => {
    dispatch(happyBirthday({ id }));
  };

  const age = (birthDate) => {
    const todayYear = new Date().getFullYear();
    const birthYear = new Date(birthDate).getFullYear();
    const userAge = todayYear - birthYear;
    return `${userAge} year${userAge > 1 ? "s" : ""}`;
  };

  return (
    <div className="container">
      <h1>Analytics</h1>
      <div className="analytics-links">
        {menuAnlytics.map((item, idx) => (
          <AnalyticLink item={item} key={idx} />
        ))}
      </div>
      {message && <div className="message">{message}</div>}
      <section className="analytics">
        <div className="analytics-box">
          <h2>
            <FaBirthdayCake /> Happy Birthday!:
          </h2>
          <div className="celebrate-birthday">
            {userMessage && <div className="message">{userMessage}</div>}
            {celebratingBirthday?.map((user, idx) => (
              <p key={idx}>
                <Link to={`/profile/${user._id}`}>
                  {user.photo && <img src={user.photo} alt={user.firstname} />}{" "}
                  {`${user.firstname} ${user.lastname}`}
                </Link>
                <span>{age(user.birthday)}</span>
                <button
                  onClick={() => handleHappyBirthday(user._id)}
                  className="primary-btn"
                >
                  Cheers!
                </button>
              </p>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Birthdays;
