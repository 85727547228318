import "./rating.css";
import { useEffect, useState } from "react";
import { FaStar, FaRegStar, FaStarHalfAlt } from "react-icons/fa";

const Rating = ({ value, count, edit, setNewRating }) => {
  const [newValue, setNewValue] = useState(value);
  const [newClick, setNewClick] = useState(value);

  useEffect(() => {
    setNewValue(value);
    setNewClick(value);
  }, [value]);

  return (
    <div className="stars-rating">
      {new Array(count).fill(undefined).map((element, idx) => (
        <span
          key={idx}
          className={edit ? "star edit" : "star"}
          onClick={() => {
            edit && setNewValue(idx + 1);
            edit && setNewClick(idx + 1);
            edit && setNewRating(idx + 1);
          }}
          onMouseEnter={() => edit && setNewValue(idx + 1)}
          onMouseLeave={() => edit && setNewValue(newClick)}
        >
          {newValue >= idx + 1 ? (
            <FaStar />
          ) : newValue >= idx + 0.5 ? (
            <FaStarHalfAlt />
          ) : (
            <FaRegStar />
          )}
        </span>
      ))}
    </div>
  );
};

export default Rating;
