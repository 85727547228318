import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

const initialState = {
  count: null,
  averageRate: null,
  coursesQuiz: null,
  totalReviews: null,
  course: null,
  courses: [],
  message: "",
  isLoading: false,
};

export const getCourses = createAsyncThunk(
  "courses/fetchall",
  async ({ cat, lang, quiz, page, limit, search }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const config = { headers: { authorization: token } };
      const response = await axios.get(
        `${API_URL}/api/courses?cat=${cat}&lang=${lang}&quiz=${quiz}&page=${page}&limit=${limit}&search=${search}`,
        config
      );
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addCourse = createAsyncThunk(
  "course/add",
  async (course, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const config = { headers: { authorization: token } };
      const response = await axios.post(
        `${API_URL}/api/courses`,
        course,
        config
      );
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getCourse = createAsyncThunk(
  "course/getone",
  async (id, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const config = { headers: { authorization: token } };
      const response = await axios.get(`${API_URL}/api/courses/${id}`, config);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateCourse = createAsyncThunk(
  "course/update",
  async ({ id, course }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const config = { headers: { authorization: token } };
      const response = await axios.put(
        `${API_URL}/api/courses/${id}`,
        course,
        config
      );
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const removeCourse = createAsyncThunk(
  "course/remove",
  async (id, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const config = { headers: { authorization: token } };
      const response = await axios.delete(
        `${API_URL}/api/courses/${id}`,
        config
      );
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const courseSlice = createSlice({
  name: "course",
  initialState,
  reducers: {
    resetCourses: (state) => {
      state.course = null;
      state.courses = [];
      state.message = "";
      state.isLoading = false;
    },
    clearMessage: (state) => {
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCourses.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCourses.fulfilled, (state, action) => {
        state.courses = [...action.payload.courses];
        state.message = action.payload.message;
        state.averageRate = action.payload.averageRate;
        state.coursesQuiz = action.payload.coursesQuiz;
        state.totalReviews = action.payload.totalReviews;
        state.count = action.payload.count;
        state.page = action.payload.page;
        state.limit = action.payload.limit;
        state.isLoading = false;
      })
      .addCase(getCourses.rejected, (state, action) => {
        state.courses = [];
        state.message = action.payload;
        state.isLoading = false;
      })
      .addCase(addCourse.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addCourse.fulfilled, (state, action) => {
        state.course = action.payload.course;
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(addCourse.rejected, (state, action) => {
        state.message = action.payload;
        state.isLoading = false;
      })
      .addCase(getCourse.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCourse.fulfilled, (state, action) => {
        state.course = action.payload.course;
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(getCourse.rejected, (state, action) => {
        state.courses = [];
        state.message = action.payload;
        state.isLoading = false;
      })
      .addCase(updateCourse.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateCourse.fulfilled, (state, action) => {
        state.course = action.payload.course;
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(updateCourse.rejected, (state, action) => {
        state.message = action.payload;
        state.isLoading = false;
      })
      .addCase(removeCourse.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(removeCourse.fulfilled, (state, action) => {
        state.courses = [...state.courses].filter(
          (course) => course._id !== action.payload.course._id
        );
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(removeCourse.rejected, (state, action) => {
        state.message = action.payload;
        state.isLoading = false;
      });
  },
});

export const { resetCourses, clearMessage } = courseSlice.actions;
export default courseSlice.reducer;
