import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

const initialState = {
  user: null,
  exams: [],
  examsCount: null,
  count: null,
  pages: null,
  limit: null,
  page: null,
  prev: null,
  next: null,
  users: [],
  message: "",
  isLoading: false,
};

//Get users
export const getUsers = createAsyncThunk(
  "users/getall",
  async ({ page, limit, search }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const config = { headers: { authorization: token } };
      const response = await axios.get(
        `${API_URL}/api/users?search=${search}&page=${page}&limit=${limit}`,
        config
      );
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Create one user
export const addUser = createAsyncThunk("user/add", async (user, thunkAPI) => {
  try {
    const response = await axios.post(`${API_URL}/api/auth/register`, user);
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//Get one user
export const getUser = createAsyncThunk("user/getone", async (id, thunkAPI) => {
  try {
    const token = localStorage.getItem("token");
    const config = { headers: { authorization: token } };
    const response = await axios.get(`${API_URL}/api/users/${id}`, config);
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//Update one user
export const updateUser = createAsyncThunk(
  "user/update",
  async ({ id, user }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const config = { headers: { authorization: token } };
      const response = await axios.put(
        `${API_URL}/api/users/${id}`,
        user,
        config
      );
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Delete one user
export const removeUser = createAsyncThunk(
  "user/remove",
  async (id, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const config = { headers: { authorization: token } };
      const response = await axios.delete(`${API_URL}/api/users/${id}`, config);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Update photo
export const updatePhoto = createAsyncThunk(
  "user/photo",
  async ({ id, photo }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const config = { headers: { authorization: token } };
      const response = await axios.put(
        `${API_URL}/api/users/photo/${id}`,
        photo,
        config
      );
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Happy birthday greeting
export const happyBirthday = createAsyncThunk(
  "user/birthday",
  async ({ id }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const config = { headers: { authorization: token } };
      const response = await axios.post(
        `${API_URL}/api/users/${id}`,
        {},
        config
      );
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//User contact request message
export const contactRequest = createAsyncThunk(
  "user/contact",
  async (userMessage, thunkAPI) => {
    try {
      const response = await axios.post(
        `${API_URL}/api/users/contact`,
        userMessage
      );
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUsers: (state) => {
      state.user = null;
      state.users = [];
      state.message = "";
      state.isLoading = false;
    },
    clearMessage: (state) => {
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.users = [...action.payload.users];
        state.count = action.payload.count;
        state.pages = action.payload.pages;
        state.limit = action.payload.limit;
        state.page = action.payload.page;
        state.prev = action.payload.prev;
        state.next = action.payload.next;
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.users = [];
        state.message = action.payload;
        state.isLoading = false;
      })
      .addCase(addUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.user = action.payload.auth;
        state.users = [];
        state.search = [];
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(addUser.rejected, (state, action) => {
        state.user = null;
        state.message = action.payload;
        state.isLoading = false;
      })
      .addCase(getUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.exams = action.payload.exams;
        state.examsCount = action.payload.examsCount;
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.user = null;
        state.message = action.payload;
        state.isLoading = false;
      })
      .addCase(updateUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.message = action.payload;
        state.isLoading = false;
      })
      .addCase(removeUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(removeUser.fulfilled, (state, action) => {
        state.users = [...state.users].filter(
          (user) => user._id !== action.payload.id
        );
        state.search = [...state.users];
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(removeUser.rejected, (state, action) => {
        state.message = action.payload;
        state.isLoading = false;
      })
      .addCase(updatePhoto.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePhoto.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(updatePhoto.rejected, (state, action) => {
        state.message = action.payload;
        state.isLoading = false;
      })
      .addCase(happyBirthday.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(happyBirthday.fulfilled, (state, action) => {
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(happyBirthday.rejected, (state, action) => {
        state.message = action.payload;
        state.isLoading = false;
      })
      .addCase(contactRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(contactRequest.fulfilled, (state, action) => {
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(contactRequest.rejected, (state, action) => {
        state.message = action.payload;
        state.isLoading = false;
      });
  },
});

export const { resetUsers, clearMessage } = userSlice.actions;
export default userSlice.reducer;
