import "./examrow.css";
import { FaCertificate } from "react-icons/fa";
import { Link } from "react-router-dom";

const ExamRow = ({ exam, idx }) => {
  return (
    <tr className={idx % 2 === 0 ? "even" : "odd"}>
      <td className="centered">
        <strong>{idx + 1}</strong>
      </td>
      <td className="centered">
        {new Date(exam.createdAt).toLocaleDateString()}
      </td>
      <td className="centered">
        {new Date(exam.createdAt).toLocaleTimeString()}
      </td>
      <td className="centered">
        <strong>{`${Math.floor((100 * exam.score) / 20)}%`}</strong>
      </td>
      <td>
        {exam.isPass && (
          <Link className="link" to={`/certificate/${exam._id}`}>
            <FaCertificate /> View My Certificate
          </Link>
        )}
      </td>
      <td>
        {exam.isPass && (
          <div className="exam-award">
            {Date.parse(exam.expiresAt) >= Date.now() ? (
              <span style={{ color: "green", fontStyle: "italic" }}>
                {`Valid through ${new Date(exam.expiresAt).toLocaleString()}`}
              </span>
            ) : (
              <span style={{ color: "red", fontStyle: "italic" }}>
                Expired one year validity!
              </span>
            )}
          </div>
        )}
      </td>
    </tr>
  );
};

export default ExamRow;
