import { Link } from "react-router-dom";
import Rating from "../../../components/Rating/Rating";
import { deleteReview } from "../../../app/features/review/reviewSlice";
import { useDispatch } from "react-redux";
import { FaTrashAlt } from "react-icons/fa";

const ReviewsTable = ({ reviews }) => {
  const dispatch = useDispatch();

  const handleDelete = (id) => {
    dispatch(deleteReview(id));
  };

  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>N°</th>
            <th>Date</th>
            <th>Time</th>
            <th>User</th>
            <th>Course</th>
            <th>Rating</th>
            <th>Comment</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {reviews.map((review, idx) => (
            <tr className={idx % 2 === 0 ? "even" : "odd"} key={idx}>
              <td className="icon">
                <strong>{idx + 1}</strong>
              </td>
              <td>{new Date(review.updatedAt).toLocaleDateString()}</td>
              <td>{new Date(review.updatedAt).toLocaleTimeString()}</td>
              <td>
                {review.userRemoved ? (
                  review.username
                ) : (
                  <Link to={`/profile/${review.user}`} className="link">
                    {review.username}
                  </Link>
                )}
              </td>
              <td>
                <Link to={`/course/${review.course}`} className="link">
                  {review.coursename}
                </Link>
              </td>
              <td>
                <Rating count={5} edit={false} value={review.rate} />
              </td>
              <td>{review.text}</td>
              <td className="icon">
                <Link
                  to="#"
                  onClick={() => handleDelete(review._id)}
                  className="link"
                >
                  <FaTrashAlt />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ReviewsTable;
