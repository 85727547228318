import "./certificate.css";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getExam, resetExams } from "../../app/features/exam/examSlice";
import { FaCertificate, FaExclamationTriangle, FaPrint } from "react-icons/fa";
import QRCode from "qrcode";

const Verification = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { exam, isLoading } = useSelector((state) => state.exams);
  const [qrCode, setQrCode] = useState("");
  const [printDate, setPrintDate] = useState(new Date().toLocaleString());

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(resetExams());

    // function to generate verification QR code
    const generateQR = () => {
      QRCode.toDataURL(`${process.env.REACT_APP_BASE_URL}/verify/${id}`, {
        margin: 0,
      })
        .then((url) => setQrCode(url))
        .catch((err) => console.log(err));
    };

    dispatch(getExam(id));
    generateQR();
  }, [dispatch, id]);

  const printReport = () => {
    setPrintDate(new Date().toLocaleString());
    window.print();
  };

  return (
    <div className="container">
      {exam ? (
        exam.isPass ? (
          <div>
            <div className="verification-report">
              <div className="verification-head">
                <h1>Online Verification Report</h1>
                <button className="circle-btn" onClick={printReport}>
                  <FaPrint />
                </button>
              </div>
              <div className="verification-details">
                <strong>Student name:</strong>
                <span>{exam.username}</span>
                <strong>Course title:</strong>
                <span>{exam.coursename}</span>
                <strong>Exam score:</strong>
                <span>{`${exam.score.toFixed(2)} /20`}</span>
                <strong>Exam percentage:</strong>
                <span>{`${((100 * exam.score) / 20).toFixed(0)} %`}</span>
                <strong>Certificate issue date:</strong>
                <span>{new Date(exam.createdAt).toDateString()}</span>
                <strong>Certificate expiry date:</strong>
                <span>{new Date(exam.expiresAt).toDateString()}</span>
                <strong>Certificate status:</strong>
                <span>
                  {Date.parse(exam.expiresAt) >= Date.now()
                    ? "Valid"
                    : "Expired"}
                </span>
                <strong>View certificate:</strong>
                <span className="certificate-link">
                  <Link to={`/certificate/${id}`}>
                    <FaCertificate
                      color={
                        Date.parse(exam.expiresAt) >= Date.now()
                          ? "green"
                          : "red"
                      }
                    />
                  </Link>
                </span>
                <div className="logo-img">
                  <img
                    src="/img/kws-logo-white.png"
                    alt="Kws Logo"
                    width="132px"
                  />
                  <p className="verification-date">{printDate}</p>
                </div>
              </div>
              <p>The above mentioned information is verified authentic.</p>
              <div className="verification-code">
                <div className="qr-code">
                  <img src={qrCode} alt="QR Code" />
                </div>
                <div className="verification-txt">
                  <p>
                    Unique Certificate ID: <strong>{exam._id}</strong>
                  </p>

                  <ol>
                    <li>
                      Online verification can be made at{" "}
                      <a href={process.env.REACT_APP_BASE_URL}>
                        www.{process.env.REACT_APP_DOMAIN}
                      </a>
                    </li>
                    <li>or scan the QR code.</li>
                  </ol>
                </div>
              </div>
              <small>
                <strong>Notes:</strong>
                <ol>
                  <li>
                    Verified by &copy;{" "}
                    <strong>{process.env.REACT_APP_NAME}</strong>, the
                    certificate is validated online at{" "}
                    <a href={process.env.REACT_APP_BASE_URL}>
                      www.{process.env.REACT_APP_DOMAIN}
                    </a>{" "}
                    using its unique Certificate ID and the{" "}
                    <strong>Certificate Validator</strong> tool.
                  </li>
                  <li>
                    Online Verification Report of Student Record is the
                    verification result of the student record managed by &copy;{" "}
                    <strong>{process.env.REACT_APP_NAME}</strong>.
                  </li>
                  <li>
                    The report shall not be used for other purposes without the
                    consent of the owner.
                  </li>
                  <li>
                    The report is subject to change. Please use the latest
                    version of the report.
                  </li>
                  <li>
                    The online verification validity of the report is 1 year.
                  </li>
                </ol>
              </small>
            </div>
          </div>
        ) : (
          <div className="certificate-error">
            <FaExclamationTriangle color="orange" size={68} />
            <h3>{`${exam.username} did not yet pass the quiz for the ${exam.coursename} course!`}</h3>
          </div>
        )
      ) : (
        !isLoading && (
          <div className="certificate-error">
            <FaExclamationTriangle color="orange" size={68} />
            <h3>Invalid Certificate ID !</h3>
          </div>
        )
      )}
    </div>
  );
};

export default Verification;
