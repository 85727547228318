import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/auth/authSlice";
import quizReducer from "./features/quiz/quizSlice";
import userReducer from "./features/user/userSlice";
import examReducer from "./features/exam/examSlice";
import statsReducer from "./features/stats/statsSlice";
import courseReducer from "./features/course/courseSlice";
import reviewReducer from "./features/review/reviewSlice";
import questionReducer from "./features/question/questionSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    quiz: quizReducer,
    stats: statsReducer,
    users: userReducer,
    exams: examReducer,
    courses: courseReducer,
    reviews: reviewReducer,
    questions: questionReducer,
  },
});
