//oil and gas jobs
export const jobsList = [
  { code: "CMNTEN", en: "Cementing Engineer" },
  { code: "CMNTOP", en: "Cementing Operator" },
  { code: "CMNTSP", en: "Cementing Technician" },
  { code: "CMNTSU", en: "Cementing Supervisor" },
  { code: "COILEN", en: "Coiled Tubing Engineer" },
  { code: "COILOP", en: "Coiled Tubing Operator" },
  { code: "COILSP", en: "Coiled Tubing Technician" },
  { code: "COILSU", en: "Coiled Tubing Supervisor" },
  { code: "CMPMAN", en: "Company Man" },
  { code: "CNSULT", en: "Consultant" },
  { code: "DRICKM", en: "Derrickman" },
  { code: "DRILLE", en: "Driller" },
  { code: "DRILEN", en: "Drilling Engineer" },
  { code: "DRILOP", en: "Drilling Operator" },
  { code: "DRILSP", en: "Drilling Technician" },
  { code: "DRILSU", en: "Drilling Supervisor" },
  { code: "FRACEN", en: "Frac Engineer" },
  { code: "FRACOP", en: "Frac Operator" },
  { code: "FRACSP", en: "Frac Technician" },
  { code: "FRACSU", en: "Frac Supervisor" },
  { code: "GEOLOG", en: "Geologist" },
  { code: "GEOPHY", en: "Geophysicist" },
  { code: "HELPER", en: "Helper" },
  { code: "HSEENG", en: "HSE Engineer" },
  { code: "HSEMGR", en: "HSE Manager" },
  { code: "HSESPE", en: "HSE Specialist" },
  { code: "INSTRC", en: "Instructor" },
  { code: "MANAGE", en: "Manager" },
  { code: "MPFMEN", en: "MPFM Engineer" },
  { code: "MPFMOP", en: "MPFM Operator" },
  { code: "MPFMSP", en: "MPFM Technician" },
  { code: "MPFMSU", en: "MPFM Supervisor" },
  { code: "OPERAT", en: "Operator" },
  { code: "PETROL", en: "Petroleum Engineer" },
  { code: "PRODEN", en: "Production Engineer" },
  { code: "PROMGR", en: "Production Manager" },
  { code: "RESERV", en: "Reservoir Engineer" },
  { code: "ROUGHN", en: "Roughneck" },
  { code: "ROUSTB", en: "Roustabout" },
  { code: "SAFETY", en: "Safety Officer" },
  { code: "SAMPEN", en: "Sampling Engineer" },
  { code: "SAMPOP", en: "Sampling Operator" },
  { code: "SAMPSP", en: "Sampling Technician" },
  { code: "SAMPSU", en: "Sampling Supervisor" },
  { code: "SRVENG", en: "Service Engineer" },
  { code: "SRVSPE", en: "Service Specialist" },
  { code: "SLIKEN", en: "Slickline Engineer" },
  { code: "SLIKOP", en: "Slickline Operator" },
  { code: "SLIKSP", en: "Slickline Technician" },
  { code: "SLIKSU", en: "Slickline Supervisor" },
  { code: "STUDNT", en: "Student" },
  { code: "SUPRIN", en: "Superintendant" },
  { code: "SUPRVI", en: "Superviser" },
  { code: "THECHN", en: "Technician" },
  { code: "TOOLPS", en: "Toolpusher" },
  { code: "TRNMGR", en: "Training Manager" },
  { code: "WELLEN", en: "Well Testing Engineer" },
  { code: "WELLOP", en: "Well Testing Operator" },
  { code: "WELLSP", en: "Well Testing Technician" },
  { code: "WELLSU", en: "Well Testing Supervisor" },
  { code: "WIREEN", en: "Wireline Engineer" },
  { code: "WIREOP", en: "Wireline Operator" },
  { code: "WIRESP", en: "Wireline Technician" },
  { code: "WIRESU", en: "Wireline Supervisor" },
];
