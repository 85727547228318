import "./editprofile.css";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getUser,
  updateUser,
  resetUsers,
  clearMessage,
} from "../../app/features/user/userSlice";
import { jobsList } from "../../constants/jobsList";
import { genders } from "../../constants/userGenders";
import { fetchme } from "../../app/features/auth/authSlice";
import { countriesList } from "../../constants/countriesList";
import {
  FaBirthdayCake,
  FaBuilding,
  FaEnvelope,
  FaRegEye,
  FaRegEyeSlash,
  FaHardHat,
  FaLocationArrow,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaToggleOff,
  FaToggleOn,
  FaUser,
  FaUserTie,
  FaArrowLeft,
  FaExclamationTriangle,
  FaInfo,
} from "react-icons/fa";
import { capitalizeString } from "../../utils/formatName";

const EditProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { auth } = useSelector((state) => state.auth);
  const { user, message } = useSelector((state) => state.users);
  const [userData, setUserData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    gender: "",
    birthday: "",
    callcode: "",
    phone: "",
    job: "",
    company: "",
    address: "",
    country: "",
    about: "",
    isAdmin: false,
    isPublic: true,
    isEmailPublic: true,
    isPhonePublic: true,
    isBirthDayPublic: true,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(resetUsers());
    dispatch(getUser(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (user) {
      setUserData({
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        gender: user.gender,
        birthday: user.birthday?.split("T")[0] || "",
        callcode: user.callcode || "",
        phone: user.phone || "",
        job: user.job || "",
        company: user.company || "",
        address: user.address || "",
        country: user.country || "",
        about: user.about || "",
        isAdmin: user.isAdmin,
        isPublic: user.isPublic,
        isEmailPublic: user.isEmailPublic,
        isPhonePublic: user.isPhonePublic,
        isBirthDayPublic: user.isBirthDayPublic,
      });
    }
  }, [user]);

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        dispatch(clearMessage());
      }, 2000);
    }
  }, [message, dispatch]);

  useEffect(() => {
    if (auth?._id === user?._id) {
      dispatch(fetchme());
    }
  }, [auth?._id, user?._id, user?.firstname, user?.lastname, dispatch]);

  const handleUserData = (evt) => {
    const { type, name, value, checked } = evt.target;
    setUserData((prev) => {
      return { ...prev, [name]: type === "checkbox" ? checked : value };
    });
  };

  const handleUpdateUser = async (evt) => {
    evt.preventDefault();
    userData.email = userData.email.toLowerCase();
    userData.firstname = capitalizeString(userData.firstname);
    userData.lastname = capitalizeString(userData.lastname);
    dispatch(updateUser({ id, user: userData }));
  };

  return (
    <div className="container">
      <header>
        <button
          className="circle-btn"
          onClick={() => navigate(`/profile/${id}`)}
        >
          <FaArrowLeft />
        </button>
      </header>

      <div>
        <form className="auth-form" onSubmit={handleUpdateUser}>
          <h2>
            {`${capitalizeString(userData.firstname)} ${capitalizeString(
              userData.lastname
            )}`}
          </h2>

          <div className="input-group">
            <label htmlFor="firstname">
              <FaUser />
            </label>
            <input
              type="text"
              id="firstname"
              name="firstname"
              required
              placeholder="Enter your first name..."
              value={userData.firstname}
              onChange={handleUserData}
            />
          </div>
          <div className="input-group">
            <label htmlFor="lastname">
              <FaUser />
            </label>
            <input
              type="text"
              id="lastname"
              name="lastname"
              required
              placeholder="Enter your last name..."
              value={userData.lastname}
              onChange={handleUserData}
            />
          </div>
          <div className="input-group">
            <label htmlFor="email">
              <FaEnvelope />
            </label>
            <input
              type="email"
              id="email"
              name="email"
              required
              placeholder="Enter your email address..."
              value={userData.email}
              onChange={handleUserData}
            />
          </div>
          <div className="input-group">
            <label htmlFor="gender">
              {genders.find((gender) => gender.sex === userData.gender)?.icon}
            </label>
            <select
              id="gender"
              name="gender"
              required
              value={userData.gender}
              onChange={handleUserData}
            >
              {genders.map((gender, idx) => (
                <option value={gender.sex} key={idx}>
                  {gender.name}
                </option>
              ))}
            </select>
          </div>
          <div className="input-group">
            <label htmlFor="birthday">
              <FaBirthdayCake />
            </label>
            <input
              type="date"
              id="birthday"
              name="birthday"
              value={userData.birthday}
              onChange={handleUserData}
            />
          </div>
          <div className="input-group">
            <label htmlFor="callcode">
              <FaPhoneAlt />
            </label>
            <select
              id="callcode"
              name="callcode"
              value={userData.callcode}
              onChange={handleUserData}
              required={userData.phone ? true : false}
            >
              <option value="">Choose country call code...</option>
              {countriesList.map((country, idx) => (
                <option value={country.dial_code} key={idx}>
                  {`${country.name} (${country.dial_code})`}
                </option>
              ))}
            </select>
          </div>
          <div className="input-group">
            <label htmlFor="phone">
              <FaPhoneAlt />
            </label>
            <input
              type="number"
              id="phone"
              name="phone"
              value={userData.phone}
              placeholder="Add your phone number..."
              onChange={handleUserData}
            />
          </div>
          <div className="input-group">
            <label htmlFor="job">
              <FaHardHat />
            </label>
            <select
              id="job"
              name="job"
              value={userData.job}
              onChange={handleUserData}
            >
              <option value="">Choose job function...</option>
              {jobsList.map((job, idx) => (
                <option value={job.en} key={idx}>
                  {job.en}
                </option>
              ))}
            </select>
          </div>
          <div className="input-group">
            <label htmlFor="company">
              <FaBuilding />
            </label>
            <input
              type="text"
              id="company"
              name="company"
              value={userData.company}
              placeholder="Add your company or school name..."
              onChange={handleUserData}
            />
          </div>
          <div className="input-group">
            <label htmlFor="address">
              <FaLocationArrow />
            </label>
            <input
              type="text"
              id="address"
              name="address"
              value={userData.address}
              placeholder="Add your street address..."
              onChange={handleUserData}
            />
          </div>

          <div className="input-group">
            <label htmlFor="country">
              <FaMapMarkerAlt />
            </label>
            <select
              id="country"
              name="country"
              value={userData.country}
              onChange={handleUserData}
            >
              <option value="">Choose country...</option>
              {countriesList.map((country, idx) => (
                <option value={country.name} key={idx}>
                  {country.name}
                </option>
              ))}
            </select>
          </div>

          <div className="input-group">
            <label htmlFor="about">
              <FaInfo />
            </label>
            <textarea
              id="about"
              name="about"
              placeholder="Add more details about yourself including working experiences, current projects, etc..."
              rows="5"
              maxLength={1200}
              value={userData.about}
              onChange={handleUserData}
            ></textarea>
          </div>

          <section className="privacy">
            <h2>Privacy settings</h2>
            {auth && user && (auth.isAdmin || auth._id === user._id) && (
              <>
                <p>
                  {userData.isPublic ? (
                    <span>
                      <FaUser color="green" /> Anyone can see my profile page{" "}
                      <FaRegEye color="green" />
                    </span>
                  ) : (
                    <span>
                      <FaUser color="red" /> Only me can see my profile page{" "}
                      <FaRegEyeSlash color="red" />
                    </span>
                  )}
                  <label htmlFor="isPublic">
                    {userData.isPublic ? (
                      <FaToggleOn color="blue" />
                    ) : (
                      <FaToggleOff />
                    )}
                  </label>
                  <input
                    hidden
                    type="checkbox"
                    id="isPublic"
                    name="isPublic"
                    checked={userData.isPublic}
                    onChange={handleUserData}
                  />
                </p>

                {userData.isPublic && (
                  <>
                    <p>
                      {userData.isEmailPublic ? (
                        <span>
                          <FaEnvelope color="green" /> Anyone can see my e-mail
                          address <FaRegEye color="green" />
                        </span>
                      ) : (
                        <span>
                          <FaEnvelope color="red" /> Only me can see my e-mail
                          address <FaRegEyeSlash color="red" />
                        </span>
                      )}
                      <label htmlFor="isEmailPublic">
                        {userData.isEmailPublic ? (
                          <FaToggleOn color="blue" />
                        ) : (
                          <FaToggleOff />
                        )}
                      </label>
                      <input
                        hidden
                        type="checkbox"
                        id="isEmailPublic"
                        name="isEmailPublic"
                        checked={userData.isEmailPublic}
                        onChange={handleUserData}
                      />
                    </p>

                    <p>
                      {userData.isPhonePublic ? (
                        <span>
                          <FaPhoneAlt color="green" /> Anyone can see my phone
                          number <FaRegEye color="green" />
                        </span>
                      ) : (
                        <span>
                          <FaPhoneAlt color="red" />
                          Only me can see my phone number{" "}
                          <FaRegEyeSlash color="red" />
                        </span>
                      )}
                      <label htmlFor="isPhonePublic">
                        {userData.isPhonePublic ? (
                          <FaToggleOn color="blue" />
                        ) : (
                          <FaToggleOff />
                        )}
                      </label>
                      <input
                        hidden
                        type="checkbox"
                        id="isPhonePublic"
                        name="isPhonePublic"
                        checked={userData.isPhonePublic}
                        onChange={handleUserData}
                      />
                    </p>

                    <p>
                      {userData.isBirthDayPublic ? (
                        <span>
                          <FaBirthdayCake color="green" /> Anyone can see my
                          birth date <FaRegEye color="green" />
                        </span>
                      ) : (
                        <span>
                          <FaBirthdayCake color="red" /> Only me can see my
                          birth date <FaRegEyeSlash color="red" />
                        </span>
                      )}
                      <label htmlFor="isBirthDayPublic">
                        {userData.isBirthDayPublic ? (
                          <FaToggleOn color="blue" />
                        ) : (
                          <FaToggleOff />
                        )}
                      </label>
                      <input
                        hidden
                        type="checkbox"
                        id="isBirthDayPublic"
                        name="isBirthDayPublic"
                        checked={userData.isBirthDayPublic}
                        onChange={handleUserData}
                      />
                    </p>
                  </>
                )}
              </>
            )}
            {auth && auth.isAdmin && (
              <>
                <h2>Access level</h2>
                <p>
                  An administrator, will have the rights to add courses, modify
                  courses, delete courses, add users, modify users details, and
                  delete users.
                </p>
                <p>
                  {userData.isAdmin ? (
                    <span>
                      <FaExclamationTriangle color="orange" /> User has
                      administrator rights <FaUserTie color="orange" />
                    </span>
                  ) : (
                    <span>
                      <FaExclamationTriangle color="lightgrey" /> User has
                      student rights only <FaUser />
                    </span>
                  )}
                  <label htmlFor="isAdmin">
                    {userData.isAdmin ? (
                      <FaToggleOn color="blue" />
                    ) : (
                      <FaToggleOff />
                    )}
                  </label>
                  <input
                    hidden
                    type="checkbox"
                    id="isAdmin"
                    name="isAdmin"
                    checked={userData.isAdmin}
                    onChange={handleUserData}
                  />
                </p>
              </>
            )}
          </section>

          {message && <div className="message">{message}</div>}

          <button type="submit" className="btn">
            Save changes
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditProfile;
