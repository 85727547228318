import React from "react";
import { FaPlus, FaTimes } from "react-icons/fa";

const OptionAnswerInput = ({
  idx,
  type,
  answer,
  option,
  onOptionAnswerChange,
  onOptionSelectChange,
}) => {
  // Handling option select array
  const handleAddOptionSelect = () => {
    const select = [...option.select];
    select.push("");
    onOptionSelectChange(select, idx);
  };
  const handleRemoveOptionSelect = (i) => {
    const select = [...option.select];
    select.splice(i, 1);
    onOptionSelectChange(select, idx);
  };
  const handleOptionSelectChange = (evt, i) => {
    const { value } = evt.target;
    const select = [...option.select];
    select[i] = value;
    onOptionSelectChange(select, idx);
  };

  //Handling answer array
  const handleOptionAnswer = (evt) => {
    const { type, value, checked } = evt.target;
    const data = type === "checkbox" ? checked : value;
    onOptionAnswerChange(data, idx);
  };

  switch (type) {
    case "select":
    case "phrase":
      return (
        <div className="options__option__select">
          <label htmlFor="select">
            Select
            <button
              type="button"
              className="circle-btn"
              onClick={() => handleAddOptionSelect()}
            >
              <FaPlus />
            </button>
          </label>
          {option.select.map((opt, i) => (
            <div key={i} className="select__option">
              <input
                type="radio"
                name={`answer-${idx}`}
                value={opt}
                checked={answer[idx] ? answer[idx] === opt : false}
                onChange={handleOptionAnswer}
              />
              <div className="inputs__option">
                <input
                  type="text"
                  value={opt}
                  onChange={(evt) => handleOptionSelectChange(evt, i)}
                  placeholder="Add select option..."
                />
                {option.select.length > 2 && (
                  <button
                    type="button"
                    onClick={() => handleRemoveOptionSelect(i)}
                  >
                    <FaTimes />
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      );
    case "radio":
      return (
        <input
          name="answer"
          type={type}
          value={option.value}
          checked={answer[0] && answer[0] === option.value}
          onChange={(evt) => handleOptionAnswer(evt, idx)}
        />
      );
    case "checkbox":
      return (
        <input
          type={type}
          checked={answer[idx] || false}
          onChange={(evt) => handleOptionAnswer(evt, idx)}
        />
      );
    default:
      return (
        <input
          type={type}
          value={answer[idx] || ""}
          placeholder="Add answer..."
          onChange={(evt) => handleOptionAnswer(evt, idx)}
        />
      );
  }
};

export default OptionAnswerInput;
