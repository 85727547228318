import "./questionstable.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { removeQuestion } from "../../../app/features/question/questionSlice";

const QuestionsTable = ({ questions, page, limit }) => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state.auth);

  const handleDelete = (id) => {
    dispatch(removeQuestion(id));
  };

  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>N°</th>
            <th>Type</th>
            <th>Points</th>
            <th>Courses</th>
            <th>Statement</th>
            {auth && auth.isAdmin && (
              <>
                <th>Edit</th>
                <th>Delete</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {questions.map((question, idx) => (
            <tr className={idx % 2 === 0 ? "even" : "odd"} key={idx}>
              <td className="icon">
                <strong>{idx + 1 + (page - 1) * limit}</strong>
              </td>
              <td>{question.type}</td>
              <td>{question.points}</td>
              <td>{question.courses.length}</td>
              <td>
                {question.statement.length > 60
                  ? `${question.statement.slice(0, 60).trim()}...`
                  : question.statement}
              </td>
              {auth && auth.isAdmin && (
                <>
                  <td className="icon">
                    <Link
                      to={`/question/${question._id}`}
                      state={{ count: idx + (page - 1) * limit }}
                      className="link"
                    >
                      <FaEdit />
                    </Link>
                  </td>
                  <td className="icon">
                    <Link
                      to="#"
                      onClick={() => handleDelete(question._id)}
                      className="link"
                    >
                      <FaTrashAlt />
                    </Link>
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default QuestionsTable;
