import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { verifyUser } from "../../app/features/auth/authSlice";
import { useEffect } from "react";

const VerifyAccount = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userId, verifyToken } = params;
  const { auth, message } = useSelector((state) => state.auth);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (auth && auth.isVerified) {
      setTimeout(() => {
        navigate(`/profile/${auth._id}`);
      }, 3000);
    }
  }, [auth, navigate]);

  const verifyAccount = (id, token) => {
    dispatch(verifyUser({ id, token }));
  };

  return (
    <div className="auth-container">
      <div>
        <h1>Account verification</h1>
        {message && <div className="message">{message}</div>}

        <br />

        {(!auth || !auth.isVerified) && (
          <button
            className="btn"
            onClick={() => verifyAccount(userId, verifyToken)}
          >
            Verify my Email
          </button>
        )}
      </div>
    </div>
  );
};

export default VerifyAccount;
