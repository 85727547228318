import "./auth.css";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { clearMessage, resetPassword } from "../../app/features/auth/authSlice";
import { FaLock } from "react-icons/fa";

const Resetpass = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { resetToken } = params;
  const { reset, message } = useSelector((state) => state.auth);
  const [password, setPassword] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    if (message) {
      setTimeout(() => {
        dispatch(clearMessage());
      }, 2000);
    }
  }, [message, dispatch]);

  useEffect(() => {
    if (reset) {
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    }
  }, [reset, navigate]);

  const handleResetPassword = (e) => {
    e.preventDefault();
    dispatch(resetPassword({ resetToken, password: { password } }));
  };

  return (
    <div className="auth-container">
      <form className="auth-form" onSubmit={handleResetPassword}>
        <h2>Reset your password</h2>
        {message && <div className="message">{message}</div>}
        <div className="input-group">
          <label htmlFor="password">
            <FaLock />
          </label>
          <input
            type="password"
            id="password"
            name="password"
            required
            placeholder="Enter a new password..."
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit" className="btn">
          Reset password
        </button>
        <p>
          <Link className="link" to="/login">
            Back to Log in...
          </Link>
        </p>
        <p>
          <Link className="link" to="/register">
            Dont have an account? create account...
          </Link>
        </p>
      </form>
    </div>
  );
};

export default Resetpass;
