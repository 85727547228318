import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

const initialState = {
  averageRate: null,
  totalRates: null,
  newPast24h: null,
  totalUsers: null,
  totalMen: null,
  totalWomen: null,
  totalTests: null,
  totalAwards: null,
  totalValid: null,
  testimonies: [],
  examsPerMonth: [],
  usersPerMonth: [],
  usersPerCountry: [],
  usersPerJob: [],
  popularCourses: [],
  celebratingBirthday: [],
  message: "",
  isLoading: false,
};

// Fetch statistics
export const fetchstats = createAsyncThunk("stats/fetch", async (thunkAPI) => {
  try {
    const response = await axios.get(`${API_URL}/api/stats`);
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Fetch users analytics data
export const fetchUsersAnalytics = createAsyncThunk(
  "analytics/fetchusers",
  async (year, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const config = { headers: { authorization: token } };
      const response = await axios.get(
        `${API_URL}/api/stats/usersanalytics?year=${year}`,
        config
      );
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Fetch exams analytics data
export const fetchExamsAnalytics = createAsyncThunk(
  "analytics/fetchexams",
  async (year, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const config = { headers: { authorization: token } };
      const response = await axios.get(
        `${API_URL}/api/stats/examsanalytics?year=${year}`,
        config
      );
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Fetch birthdays analytics data
export const fetchBirthdays = createAsyncThunk(
  "analytics/birthdays",
  async (thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const config = { headers: { authorization: token } };
      const response = await axios.get(`${API_URL}/api/stats/birthday`, config);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const statsSlice = createSlice({
  name: "stats",
  initialState,
  reducers: {
    resetStats: (state) => {
      state.averageRate = null;
      state.totalRates = null;
      state.newPast24h = null;
      state.totalUsers = null;
      state.totalMen = null;
      state.totalWomen = null;
      state.totalLogin = null;
      state.totalVerified = null;
      state.totalTests = null;
      state.totalAwards = null;
      state.totalValid = null;
      state.testimonies = [];
      state.examsPerMonth = [];
      state.usersPerMonth = [];
      state.usersPerCountry = [];
      state.usersPerJob = [];
      state.popularCourses = [];
      state.celebratingBirthday = [];
      state.isLoading = false;
    },
    clearMessage: (state) => {
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchstats.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchstats.fulfilled, (state, action) => {
        state.averageRate = action.payload.averageRate;
        state.totalRates = action.payload.totalRates;
        state.newPast24h = action.payload.newPast24h;
        state.totalUsers = action.payload.totalUsers;
        state.totalMen = action.payload.totalMen;
        state.totalWomen = action.payload.totalWomen;
        state.totalLogin = action.payload.totalLogin;
        state.totalVerified = action.payload.totalVerified;
        state.totalTests = action.payload.totalTests;
        state.totalAwards = action.payload.totalAwards;
        state.totalValid = action.payload.totalValid;
        state.testimonies = [...action.payload.testimonies];
        state.popularCourses = [...action.payload.popularCourses];
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(fetchstats.rejected, (state, action) => {
        state.message = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchUsersAnalytics.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUsersAnalytics.fulfilled, (state, action) => {
        state.usersPerMonth = action.payload.usersPerMonth;
        state.usersPerCountry = action.payload.usersPerCountry;
        state.usersPerJob = action.payload.usersPerJob;
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(fetchUsersAnalytics.rejected, (state, action) => {
        state.message = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchExamsAnalytics.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchExamsAnalytics.fulfilled, (state, action) => {
        state.examsPerMonth = action.payload.examsPerMonth;
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(fetchExamsAnalytics.rejected, (state, action) => {
        state.message = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchBirthdays.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchBirthdays.fulfilled, (state, action) => {
        state.celebratingBirthday = action.payload.celebratingBirthday;
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(fetchBirthdays.rejected, (state, action) => {
        state.message = action.payload;
        state.isLoading = false;
      });
  },
});

export const { resetStats, clearMessage } = statsSlice.actions;
export default statsSlice.reducer;
