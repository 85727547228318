import "./examscard.css";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import ExamProgressBar from "../../../components/ExamProgressBar/ExamProgressBar";
import { useSelector } from "react-redux";

const ExamsCard = ({ exams, idx }) => {
  const { auth } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.users);

  return (
    <div className="course-scores">
      <div>
        <div className="course-title">
          <strong>{`${idx + 1}.`}</strong>
          <strong className={exams.courseRemoved ? "removed" : ""}>
            {exams.courseRemoved ? (
              `$ ${exams.coursename} (This course has been removed from the list of courses)"`
            ) : (
              <Link className="link" to={`/course/${exams.course}`}>
                {exams.coursename}
              </Link>
            )}
          </strong>
        </div>
        <ExamProgressBar value={exams.average} />
      </div>

      {auth && user && (auth.isAdmin || auth._id === user._id) && (
        <Link
          to={`/exams/${user._id}/${exams.course}`}
          className="link details-link"
        >
          See exams details <FaArrowRight />
        </Link>
      )}
    </div>
  );
};

export default ExamsCard;
