import "./logo.css";

const Logo = () => {
  return (
    <div className="logo_outer">
      <div className="logo_inner">
        <span>K</span>
      </div>
    </div>
  );
};

export default Logo;
