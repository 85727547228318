import { useEffect } from "react";
import "./about.css";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container about-us">
      <h1>About us</h1>
      <p>
        At {process.env.REACT_APP_NAME} eLearning, we are passionate about
        transforming education and empowering individuals through innovative
        online learning experiences. Our commitment is driven by a clear
        mission, a compelling vision, and unwavering values that guide every
        aspect of our work.
      </p>

      <h2>Mission</h2>
      <p>
        <strong>Empowering Minds, Transforming Lives:</strong> Our mission is to
        provide accessible, high-quality eLearning solutions that inspire and
        equip learners with the knowledge and skills needed to excel in an
        ever-evolving world. We strive to break down barriers to education,
        fostering a culture of continuous learning and personal growth.
      </p>

      <h2>Vision</h2>
      <p>
        <strong>Shaping the Future of Learning:</strong> We envision a future
        where education knows no boundaries, where anyone, anywhere, can access
        a world-class learning experience. {process.env.REACT_APP_NAME}{" "}
        eLearning aims to be a global leader in shaping the future of learning
        by leveraging technology to create dynamic, engaging, and personalized
        educational journeys.
      </p>

      <h2>Values</h2>

      <p>
        <strong>Excellence:</strong> We are committed to delivering excellence
        in everything we do, from the content we create to the support we
        provide. We constantly seek improvement and innovation to exceed
        expectations.
      </p>

      <p>
        <strong>Accessibility:</strong> Education should be accessible to all.
        We strive to make our courses and resources available to learners
        worldwide, fostering inclusivity and equal opportunities.
      </p>

      <p>
        <strong>Collaboration:</strong> We believe in the power of
        collaboration. By working together with educators, experts, and
        learners, we create a community that enhances the learning experience
        for everyone.
      </p>

      <p>
        <strong>Integrity:</strong> Integrity is the foundation of our
        interactions. We are transparent, honest, and ethical in all aspects of
        our business, building trust with our learners and partners.
      </p>

      <p>
        <strong>Passion for Learning:</strong> We are driven by a genuine
        passion for learning. Our team is dedicated to instilling that passion
        in our learners, creating a lifelong love for acquiring knowledge.
      </p>

      <p>
        Join us on our journey to redefine education in the digital age.
        Together, let's inspire minds and shape the future.
      </p>
    </div>
  );
};

export default About;
