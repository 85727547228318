import "./coursestable.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  FaEdit,
  FaLayerGroup,
  FaRegEye,
  FaRegEyeSlash,
  FaTrashAlt,
  FaUsers,
} from "react-icons/fa";
import { removeCourse } from "../../../app/features/course/courseSlice";
import Rating from "../../../components/Rating/Rating";
import { formatNumber } from "../../../utils/formatNumber";
import { nintyDays } from "../../../constants/courseOptions";

const CoursesTable = ({ courses, page, limit }) => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state.auth);

  const handleRemove = (id) => {
    dispatch(removeCourse(id));
  };

  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>N°</th>
            <th>Course Title</th>
            <th>Duration</th>
            <th>Language</th>
            <th>Category</th>
            <th>Rating</th>
            <th>Reviews</th>
            <th>Quiz</th>
            <th>Price</th>
            {auth && auth.isAdmin && (
              <>
                <th>
                  <FaUsers />
                </th>
                <th></th>
                <th>Edit</th>
                <th>Delete</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {courses.map((course, idx) => (
            <tr className={idx % 2 === 0 ? "even" : "odd"} key={idx}>
              <td className="icon">
                <strong>{idx + 1 + (page - 1) * limit}</strong>
              </td>
              <td className="course-title">
                <Link to={`/course/${course._id}`} className="link">
                  {course.title}
                </Link>
                {Date.parse(course.createdAt) + nintyDays >=
                  Date.now() && <span className="new-course">NEW</span>}
              </td>
              <td className="centered">{course.duration}</td>
              <td className="centered">{course.language}</td>
              <td className="centered">{course.category.toUpperCase()}</td>
              <td>
                <Rating
                  count={5}
                  edit={false}
                  value={course.totalRates / course.totalReviews}
                />
              </td>
              <td className="centered">{formatNumber(course.totalReviews)}</td>
              <td className="icon">
                {course.isQuiz && (
                  <Link to={`/quiz/${course._id}`} className="link">
                    <FaLayerGroup />
                  </Link>
                )}
              </td>
              <td className="centered">
                {course.price.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </td>
              {auth && auth.isAdmin && (
                <>
                  <td className="centered">
                    {course.users.length.toLocaleString("en", {
                      useGrouping: true,
                    })}
                  </td>
                  <td className="icon">
                    {course.isPublic ? (
                      <FaRegEye color="green" />
                    ) : (
                      <FaRegEyeSlash color="red" />
                    )}
                  </td>
                  <td className="icon">
                    <Link to={`/courses/edit/${course._id}`} className="link">
                      <FaEdit />
                    </Link>
                  </td>
                  <td className="icon">
                    <Link
                      to="#"
                      onClick={() => handleRemove(course._id)}
                      className="link"
                    >
                      <FaTrashAlt />
                    </Link>
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CoursesTable;
