import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

const initialState = {
  score: 0,
  message: "",
  questions: [],
  totalPoints: null,
  coursename: "",
  isLoading: false,
  isComplete: false,
};

export const newQuiz = createAsyncThunk("quiz/new", async (id, thunkAPI) => {
  try {
    const token = localStorage.getItem("token");
    const config = { headers: { authorization: token } };
    const response = await axios.get(
      `${API_URL}/api/quiz?course=${id}`,
      config
    );
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const quizSlice = createSlice({
  name: "quiz",
  initialState,
  reducers: {
    resetQuiz: (state) => {
      state.score = 0;
      state.isComplete = false;
    },
    answer: (state, action) => {
      state.score += action.payload.score;
    },
    complete: (state) => {
      state.isComplete = true;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(newQuiz.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(newQuiz.fulfilled, (state, action) => {
        state.score = 0;
        state.message = action.payload.message;
        state.totalPoints = action.payload.totalPoints;
        state.questions = [...action.payload.questions];
        state.coursename = action.payload.coursename;
        state.isLoading = false;
      })
      .addCase(newQuiz.rejected, (state, action) => {
        state.score = 0;
        state.message = action.payload;
        state.questions = [];
        state.coursename = "";
        state.isLoading = false;
      });
  },
});

export const { answer, complete, resetQuiz } = quizSlice.actions;
export default quizSlice.reducer;
