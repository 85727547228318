import "./coursecard.css";
import { Link } from "react-router-dom";
import Rating from "../../../components/Rating/Rating";
import {
  formatNumber,
  truncateToOneDecimal,
} from "../../../utils/formatNumber";
import { FaGraduationCap } from "react-icons/fa";
import { nintyDays } from "../../../constants/courseOptions";

const CourseCard = ({ course }) => {
  return (
    <Link to={`/course/${course._id}`} className="link course-link">
      <div className="course-card">
        <div className="course-top">
          <img src={course.coverURL} alt={course.title} loading="lazy" />
          <h4>{course.title}</h4>
          <div className="course-rating">
            <strong>
              {truncateToOneDecimal(course.totalRates / course.totalReviews)}
            </strong>
            <Rating
              count={5}
              edit={false}
              value={course.totalRates / course.totalReviews}
            />
            <small>{`${formatNumber(course.totalReviews)} review${
              course.totalReviews > 1 ? "s" : ""
            }`}</small>
          </div>
        </div>

        <div className="course-bottom">
          <div className="course-advertise">
            {course.isQuiz && (
              <p className="earn-certificate">
                <FaGraduationCap />
                <span>Earn certificate</span>
              </p>
            )}
            <p className="course-price">
              {course.price > 0
                ? course.price.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })
                : "Free Course"}
            </p>
          </div>
          {course.users.length > 0 && (
            <p className="muted">
              {`${formatNumber(course.users.length)} student${
                course.totalReviews > 1 ? "s" : ""
              } have already taken this Quiz`}
            </p>
          )}
        </div>

        {Date.parse(course.createdAt) + nintyDays >= Date.now() && (
          <div className="course-badge-new">New</div>
        )}
      </div>
    </Link>
  );
};

export default CourseCard;
