import "./exampaper.css";
import { useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchme } from "../../app/features/auth/authSlice";
import { addExam } from "../../app/features/exam/examSlice";
import { complete, newQuiz } from "../../app/features/quiz/quizSlice";
import QuestionCard from "../../components/QuestionCard/QuestionCard";
import ExamHerader from "./ExamHerader";

const ExamPaper = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { auth } = useSelector((state) => state.auth);
  const { exam, message } = useSelector((state) => state.exams);
  const { score, totalPoints, questions, coursename, isComplete, isLoading } =
    useSelector((state) => state.quiz);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(fetchme());
    dispatch(newQuiz(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (isComplete && message && message === "Score add success") {
      navigate(`/exams/${auth._id}/${id}`);
    }
  }, [isComplete, id, auth, message, exam, navigate]);

  const handleSubmit = () => {
    dispatch(complete());
    dispatch(
      addExam({
        course: id,
        coursename,
        score: ((20 * score) / totalPoints).toFixed(2),
      })
    );
  };

  if (auth && !auth.isVerified) return <Navigate to="/verification-error" />;

  return (
    <div className="container">
      {auth && questions.length > 0 && (
        <div className="exam-paper">
          <ExamHerader auth={auth} score={score} totalPoints={totalPoints} />
          <br />
          <h2>{coursename}</h2>
          <p className="muted">NOTE. Passing mark is 80%.</p>

          {questions.map((question, idx) => (
            <QuestionCard question={question} i={idx} key={idx} />
          ))}

          <button
            className="btn"
            disabled={isComplete || isLoading}
            onClick={handleSubmit}
          >
            Submit my Exam
          </button>
        </div>
      )}
    </div>
  );
};

export default ExamPaper;
