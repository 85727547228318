import {
  FaCrown,
  FaTrophy,
  FaSeedling,
  FaJetFighterUp,
  FaExclamation,
  FaTree,
} from "react-icons/fa6";

export const performance = [
  {
    icon: <FaSeedling color="yellowgreen" />,
    tag: "Novice",
    msg: "You've just unlocked the Novice level. Enroll in more courses and take more quizzes to reach the Average Performer level.",
    info: "Student who is new to the platform or has limited experience with courses and exams.",
    style: "novice",
  },
  {
    icon: <FaTree color="darkgreen" />,
    tag: "Average Performer",
    msg: "Congratulations! You have unlocked the Average Performer level. Enroll in more courses and take more quizzes to reach the Regular Participant level.",
    info: "Student who demonstrate a moderate level of performance in courses and exams.",
    style: "average",
  },
  {
    icon: <FaJetFighterUp color="darkblue" />,
    tag: "Regular Participant",
    msg: "Congratulations! You have unlocked the Regular Participant level. Enroll in more courses and take more quizzes to reach the Top Performer level.",
    info: "Student who actively engage with a variety of courses and consistently complete exams.",
    style: "regular",
  },
  {
    icon: <FaTrophy color="gold" />,
    tag: "Top Performer",
    msg: "Congratulations! You have unlocked the Top Performer level. Enroll in more courses and take more quizzes to reach the Expert level.",
    info: "Student who consistently excel in courses and exams, demonstrating advanced knowledge.",
    style: "top",
  },
  {
    icon: <FaCrown color="orange" />,
    tag: "Expert",
    msg: "Congratulations! You have unlocked the Expert level. Continue enrolling in courses and taking quizzes to maintain your level!",
    info: "Student who demonstrate expertise in courses and exams, excelling in advanced topics.",
    style: "expert",
  },
];

export const userPerformance = (user, exams, reviews) => {
  if (!user || !exams || !reviews) {
    return {
      icon: <FaExclamation color="red" />,
      tag: "Undefined",
      msg: "Missing user information, exams information and/or reviews information!",
      info: "Undefined performance level.",
      style: "",
    };
  }
  const reviewsNumber = reviews.length;
  const coursesNumber = user.courses.length;
  const examsNumber = user.totalExams;
  const averageScore =
    exams.length > 0
      ? exams
          ?.map((course) => course.average)
          .reduce((avg, tot) => tot + avg, 0) / exams?.length
      : 0;
  const isCertified = user.dateCertified ? 1 : 0;
  const isTestimony = user.testimonial ? 1 : 0;
  const finalScore =
    reviewsNumber +
    coursesNumber +
    examsNumber * averageScore +
    isCertified +
    isTestimony;

  if (finalScore <= 120) {
    return performance[0];
  } else if (finalScore <= 230) {
    return performance[1];
  } else if (finalScore <= 450) {
    return performance[2];
  } else if (finalScore <= 670) {
    return performance[3];
  } else {
    return performance[4];
  }
};
