import { useEffect } from "react";

const VerificationSent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container">
      <div>
        <h2>Account Verification Instruction Sent!</h2>
        <br />
        <p>
          A verification email has been sent to your registered email address.
        </p>
        <br />
        <p>
          Please check your inbox and follow the instructions to verify your
          account.
        </p>
        <br />
        <p>
          If you do not receive the email, it could mean that the email address
          you provided is either invalid or inactive. In this case, please
          update your account with a valid and active email address to proceed.
        </p>
        <br />
        <p>
          Additionally, please check your junk or spam box, as the email might
          have been filtered there by mistake.
        </p>
        <br />
        <p>Thank you!</p>
        <br />
      </div>
    </div>
  );
};

export default VerificationSent;
