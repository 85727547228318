import "./questionslist.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getQuestions } from "../../app/features/question/questionSlice";
import { FaPlus, FaQuestion } from "react-icons/fa";
import DashCard from "../../components/DashCard/DashCard";
import QuestionsTable from "./QuestionsTable/QuestionsTable";
import Pagination from "../../components/Pagination/Pagination";

const QuestionsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { auth } = useSelector((state) => state.auth);
  const { message, questions, count } = useSelector((state) => state.questions);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const dashCards = [
    { icon: <FaQuestion />, value: count, text: "Total Questions" },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getQuestions({ page, limit, search }));
  }, [dispatch, page, limit, search]);

  return (
    <div className="container">
      <h1>Questions List</h1>

      {auth && auth.isAdmin && (
        <header>
          <button
            className="primary-btn"
            onClick={() => navigate("/question/new", { state: { count } })}
          >
            <FaPlus /> Add new question
          </button>
        </header>
      )}

      {message && <div className="message">{message}</div>}

      <section className="dash-cards">
        {dashCards.map((dash, idx) => (
          <DashCard dash={dash} key={idx} />
        ))}
      </section>

      <section>
        <select
          id="type"
          name="type"
          value={search}
          onChange={(e) => {
            setPage(1);
            setSearch(e.target.value);
          }}
        >
          <option value="">all types...</option>
          <option value="text">text</option>
          <option value="radio">radio</option>
          <option value="number">number</option>
          <option value="select">select</option>
          <option value="phrase">phrase</option>
          <option value="checkbox">checkbox</option>
        </select>
        {questions && questions.length > 0 && (
          <QuestionsTable questions={questions} page={page} limit={limit} />
        )}

        <select
          id="limit"
          name="limit"
          value={limit}
          onChange={(e) => {
            setPage(1);
            setLimit(e.target.value);
          }}
        >
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
        {questions && count && count > limit && (
          <Pagination
            page={page}
            limit={limit}
            count={count}
            paginate={setPage}
          />
        )}
      </section>
    </div>
  );
};

export default QuestionsList;
