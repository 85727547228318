import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  FaAward,
  FaCircle,
  FaEdit,
  FaRegCheckCircle,
  FaRegEye,
  FaRegEyeSlash,
  FaRegTimesCircle,
  FaTrashAlt,
  FaUser,
  FaUserTie,
} from "react-icons/fa";
import { removeUser } from "../../../app/features/user/userSlice";
import { logoutUser } from "../../../app/features/auth/authSlice";
import { genders } from "../../../constants/userGenders";
import { formatEmail, formatFullName } from "../../../utils/formatName";
import Avatar from "../../../components/Avatar/Avatar";
import Rating from "../../../components/Rating/Rating";

const UsersTable = ({ users, page, limit }) => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state.auth);

  const handleRemove = (id) => {
    dispatch(removeUser(id));
    if (id === auth._id) {
      dispatch(logoutUser());
    }
  };

  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Gender</th>
            <th>Job Title</th>
            <th>Age</th>
            <th>Courses</th>
            <th>Exams</th>
            <th>Award</th>
            <th>Rating</th>
            <th>Admin</th>
            <th>Public</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, idx) => (
            <tr className={idx % 2 === 0 ? "even" : "odd"} key={idx}>
              <td className="icon">
                <strong>{idx + 1 + (page - 1) * limit}</strong>
              </td>
              <td className="name">
                <Link to={`/profile/${user._id}`} className="link nav-link">
                  <Avatar
                    source={user.photo}
                    first={user.firstname}
                    last={user.lastname}
                    size={"sm"}
                    border={true}
                  />
                </Link>
                <div className="contact">
                  <span>
                    {formatFullName(user.firstname, user.lastname, 24)}
                    <small>
                      {user.isLogin ? (
                        <FaCircle color="green" />
                      ) : (
                        <FaCircle color="lightgrey" />
                      )}
                    </small>
                  </span>
                  <Link to={`mailto:${user.email}`} className="link">
                    {formatEmail(user.email, 20)}
                    <small>
                      {user.isVerified ? (
                        <FaRegCheckCircle color="blue" />
                      ) : (
                        <FaRegTimesCircle color="red" />
                      )}
                    </small>
                  </Link>
                </div>
              </td>
              <td className="icon">
                {genders.find((gender) => gender.sex === user.gender)?.icon}
              </td>
              <td>
                <div className="job">
                  <span className="job-function">{user.job}</span>
                  <span className="job-company">{user.company}</span>
                </div>
              </td>
              <td className="icon">
                {user.birthday &&
                  Math.floor(
                    (Date.now() - Date.parse(user.birthday)) /
                      (1000 * 60 * 60 * 24 * 365)
                  )}
              </td>
              <td className="icon">{user.courses.length}</td>
              <td className="icon">{user.totalExams}</td>
              <td className="icon">
                {user.dateCertified &&
                  Date.parse(user.dateCertified) + 365 * 24 * 60 * 60 * 1000 >=
                    Date.now() && <FaAward color="blue" />}
              </td>
              <td>
                {user.testimonial && user.testimonial.rate && (
                  <Rating
                    count={5}
                    edit={false}
                    value={user.testimonial.rate}
                  />
                )}
              </td>
              <td className="icon">
                {user.isAdmin ? <FaUserTie color="orange" /> : <FaUser />}
              </td>
              <td className="icon">
                {user.isPublic ? (
                  <FaRegEye color="green" />
                ) : (
                  <FaRegEyeSlash color="red" />
                )}
              </td>
              <td className="icon">
                <Link to={`/editprofile/${user._id}`} className="link">
                  <FaEdit />
                </Link>
              </td>
              <td className="icon">
                <Link
                  to="#"
                  onClick={() => handleRemove(user._id)}
                  className="link"
                >
                  <FaTrashAlt />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UsersTable;
