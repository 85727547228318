import "./removeusermodal.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaExclamationTriangle, FaTimes, FaTrashAlt } from "react-icons/fa";
import { removeUser } from "../../../app/features/user/userSlice";
import { logoutUser } from "../../../app/features/auth/authSlice";
import { useNavigate } from "react-router-dom";

const RemoveUserModal = ({ id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { auth } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.users);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (message && message === "User remove success") {
      if (id === auth._id) {
        dispatch(logoutUser());
      } else {
        navigate("/dashboard");
      }
    }
  }, [message, id, auth._id, dispatch, navigate]);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  const handleRemove = () => {
    dispatch(removeUser(id));
  };

  return (
    <>
      <button
        className="primary-btn btn-danger"
        onClick={handleShow}
        title="Remove account"
      >
        <FaTrashAlt /> Remove account
      </button>

      <div className={show ? "modal show" : "modal"}>
        <div className="modal-container">
          <div className="modal-content">
            <div className="modal-header">
              <h2>Remove account?</h2>
              <button className="close-btn" onClick={handleClose}>
                <FaTimes />
              </button>
            </div>

            {message && <div className="message">{message}</div>}

            <div className="modal-body">
              <div className="msg-warning">
                <FaExclamationTriangle className="warning-logo" />
                <p>Are you sure you want to permanently remove this account?</p>

                <small>This operation is irreversible!</small>
              </div>

              <button className="btn" onClick={handleRemove}>
                <FaTrashAlt /> Yes, remove account{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RemoveUserModal;
