import { countriesList } from "../../constants/countriesList";

const Flag = ({ location }) => {
  const country = countriesList.find((loc) => loc.name === location);
  if (country)
    return <span className={`fi fi-${country.code.toLowerCase()}`}></span>;
  else return <span className="fi fi-xx"></span>;
};

export default Flag;
