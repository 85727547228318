//UNITS CONVERSIONS
//Converting temperature to DegC
export const toDegC = (temp, unit) => {
	if (unit === "C") return temp;
	if (unit === "F") return ((temp - 32) * 5) / 9;
	if (unit === "K") return temp - 273.15;
};
//Converting temperature to DegF
export const toDegF = (temp, unit) => {
	if (unit === "C") return (temp * 9) / 5 + 32;
	if (unit === "F") return temp;
	if (unit === "K") return ((temp - 273.15) * 9) / 5 + 32;
};
//Converting pressure to psi
export const toPSI = (p, unit) => {
	if (unit === "psi") return p;
	if (unit === "bar") return p * 14.504;
	if (unit === "atm") return p * 14.696;
	if (unit === "kg/cm2") return p * 14.22;
	if (unit === "Pa") return p / 6895;
	if (unit === "mmHg") return p / 51.715;
};
//Converting pressure to kg/cm2
export const toKgCm2 = (p, unit) => {
	if (unit === "psi") return p / 14.22;
	if (unit === "bar") return (p * 14.504) / 14.22;
	if (unit === "atm") return (p * 14.696) / 14.22;
	if (unit === "kg/cm2") return p;
	if (unit === "Pa") return p / 6895 / 14.22;
	if (unit === "mmHg") return p / 51.715 / 14.22;
};
//Converting H2S to ppm
export const toPPM = (h2s, unit) => {
	if (unit === "ppm") return h2s;
	if (unit === "%") return h2s * 10000;
};
//Converting gravity to SG [air=1]
export const toSG = (gravity, unit) => {
	if (unit === "SG") return gravity;
	if (unit === "API") return 141.5 / (gravity + 131.5);
};
//Converting gravity to API
export const toAPI = (gravity, unit) => {
	if (unit === "SG") return 141.5 / gravity - 131.5;
	if (unit === "API") return gravity;
};
//Converting volume to bbls
export const toBBL = (vol, unit) => {
	if (unit === "bbl") return vol;
	if (unit === "m3") return vol * 6.29;
	if (unit === "inH2O") return vol / 25;
	if (unit === "USgal") return vol / 42;
};
//Converting flowrates to MMscf/d
export const toMMscfd = (rate, unit) => {
	if (unit === "MMscfd") return rate;
	if (unit === "Mm3d") return rate / 28.314;
};
//Converting distance to inch
export const toInch = (distance, unit) => {
	if (unit === "inch") return distance;
	if (unit === "foot") return distance * 12;
	if (unit === "mm") return distance / 25.4;
	if (unit === "cm") return distance / 2.54;
};
