import "./auth.css";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  loginUser,
  clearMessage,
  resetAuth,
} from "../../app/features/auth/authSlice";
import { FaEnvelope, FaLock } from "react-icons/fa";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { auth, message } = useSelector((state) => state.auth);
  const [newUser, setNewUser] = useState({ email: "", password: "" });

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(resetAuth());
  }, [dispatch]);

  useEffect(() => {
    if (auth) {
      navigate(location.state?.from?.pathname || `/profile/${auth._id}`);
    }
  }, [auth, navigate, location.state?.from?.pathname]);

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        dispatch(clearMessage());
      }, 3000);
    }
  }, [message, dispatch]);

  const handleNewUser = (e) => {
    const { name, value } = e.target;
    setNewUser((prev) => ({ ...prev, [name]: value }));
  };

  const handleLoginUser = async (e) => {
    e.preventDefault();
    newUser.email = newUser.email.toLowerCase();
    dispatch(loginUser(newUser));
  };

  return (
    <div className="auth-container">
      <form className="auth-form" onSubmit={(e) => handleLoginUser(e)}>
        <h2>Log in to your account</h2>
        {message && <div className="message">{message}</div>}
        <div className="input-group">
          <label htmlFor="email">
            <FaEnvelope />
          </label>
          <input
            type="email"
            id="email"
            name="email"
            required
            placeholder="Email..."
            value={newUser.email}
            onChange={(e) => handleNewUser(e)}
          />
        </div>
        <div className="input-group">
          <label htmlFor="password">
            <FaLock />
          </label>
          <input
            type="password"
            id="password"
            name="password"
            required
            placeholder="Password..."
            value={newUser.password}
            onChange={(e) => handleNewUser(e)}
          />
        </div>
        <p>
          <small>
            <Link className="link" to="/forgotpass">
              Forgot your password?
            </Link>
          </small>
        </p>

        <button type="submit" className="btn">
          Log in
        </button>

        <p>
          <Link className="link" to="/register">
            Dont have an account? create account...
          </Link>
        </p>
      </form>
    </div>
  );
};

export default Login;
