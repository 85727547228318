import "./analytics.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FaAngleLeft,
  FaAngleRight,
  FaLayerGroup,
  FaRegThumbsDown,
  FaRegThumbsUp,
} from "react-icons/fa";
import { fetchExamsAnalytics } from "../../app/features/stats/statsSlice";
import { clearMessage } from "../../app/features/user/userSlice";
import { menuAnlytics } from "../../constants/menuData";
import ChartBarVerticalDual from "../../components/ChartBarVerticalDual/ChartBarVertivalDual";
import AnalyticLink from "./AnalyticLink";

const ExamsAnalytics = () => {
  const dispatch = useDispatch();
  const initialYear = 2021;
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState(currentYear);
  const userMessage = useSelector((state) => state.users.message);
  const { examsPerMonth, message } = useSelector((state) => state.stats);

  useEffect(() => {
    if (userMessage) {
      setTimeout(() => {
        dispatch(clearMessage());
      }, 2000);
    }
  }, [userMessage, dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchExamsAnalytics(year));
  }, [dispatch, year]);

  return (
    <div className="container">
      <h1>Analytics</h1>
      <div className="analytics-links">
        {menuAnlytics.map((item, idx) => (
          <AnalyticLink item={item} key={idx} />
        ))}
      </div>
      {message && <div className="message">{message}</div>}
      <section className="analytics">
        <div className="analytics-box">
          <div className="analytics-group">
            <div className="main-chart">
              <h2>
                <FaLayerGroup /> Exams count:{" "}
                <FaRegThumbsUp color="lightseagreen" />
                <FaRegThumbsDown color="tomato" />
              </h2>
              <div className="chart-area">
                {examsPerMonth.map((element, idx) => (
                  <ChartBarVerticalDual
                    key={"pppp" + idx}
                    variant="primary"
                    title={element.month}
                    value1={element.total - element.pass}
                    value2={element.pass}
                    max={Math.max(...examsPerMonth.map((el) => el.total))}
                  />
                ))}
              </div>
              <div className="chart-title">
                <span>{`Total exams: ${examsPerMonth.reduce(
                  (total, month) => total + month.total,
                  0
                )}`}</span>
              </div>
            </div>
          </div>

          <div className="pages-nbrs">
            <button
              className="page-btn"
              disabled={year === initialYear}
              onClick={() =>
                setYear((prev) => (prev > initialYear ? prev - 1 : prev))
              }
            >
              <FaAngleLeft />
            </button>
            <strong>{year}</strong>
            <button
              className="page-btn"
              disabled={year === currentYear}
              onClick={() =>
                setYear((prev) => (prev < currentYear ? prev + 1 : prev))
              }
            >
              <FaAngleRight />
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ExamsAnalytics;
