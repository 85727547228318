import {
  FaRadiationAlt,
  FaSkullCrossbones,
  FaTemperatureHigh,
  FaWind,
} from "react-icons/fa";

export const supportList = [
  {
    id: "velocity",
    icon: <FaWind />,
    photo: "img/velocity.jpg",
    title: "Dry Gas Velocity Calculator",
    link: "/velocity",
    shortDesc:
      "Calculate the dry gas velocity inside a given pipe using this web application and simulate your piping studies for your next clean up job.",
    description:
      "This application calculates the velocity of the dry gas flowing inside a given pipework, knowing that the dry gas velocity limit is 160 ft/sec.",
  },
  {
    id: "texas-estimation",
    icon: <FaWind />,
    photo: "img/estimate.jpg",
    title: "Texas A&I Gas Estimate Calculator",
    link: "/texas",
    shortDesc:
      "Estimate gas flowrate across a choke manifold using the Texas A&I gas estimate formula for all your clean up jobs without a separator nor a gas flow meter.",
    description:
      "This application calculates the gas rate estimate as per the Texas A&I formula for a gas flow through the choke manifold when critical flow condtion is achieved across choke.",
  },
  {
    id: "h2spp",
    photo: "img/h2spp.jpg",
    icon: <FaSkullCrossbones />,
    title: "H2S Partial Pressure Calculator",
    link: "/h2spp",
    shortDesc:
      "Make your piping material design in a sour well flowing conditions, calcualate the H2S partial pressure.",
    description:
      "This application calculates the H2S partial pressure for a given well flowing conditions, and recommends the safety operations with regards to the used piping material.",
  },
  {
    id: "gamma",
    icon: <FaTemperatureHigh />,
    photo: "img/gamma.jpg",
    title: "Gamma Detector Stabilization Temperature Selector",
    link: "/gamma",
    shortDesc:
      "Get help in selecting the most appropriate gamma detectot stabilization temperature for your MPFM meter.",
    description:
      "This application calculates and recommends the gamma detector stabilization temperature for a given gamma detector based on its ambiant temperature and the well flowing temperature.",
  },
  {
    id: "ba133",
    icon: <FaRadiationAlt />,
    photo: "img/barium.jpg",
    title: "Barium-133 Source Decay Calculator",
    link: "/barium",
    shortDesc:
      "Calculate the Ba-133 source energy decay over the time, get recommendation on the status of the source.",
    description:
      "This application calculates the radioactive decay of a given barium source Ba-133 based on its empty pipe measurements taken on a Vx multiphase flow meter at a given date & time.",
  },
];
