import "./support.css";
import { useState } from "react";
import { toPSI, toPPM } from "../../utils/unitConversion";
import { FaSkullCrossbones } from "react-icons/fa";

const H2S = () => {
  const [pressure, setPressure] = useState(0);
  const [prUnit, setPrUnit] = useState("psi");
  const [h2s, setH2s] = useState(0);
  const [h2sUnit, setH2sUnit] = useState("ppm");
  const [message, setMessage] = useState([]);
  const [variant, setVariant] = useState("");

  const h2sPP = (e) => {
    e.preventDefault();
    let messages = [];
    if (!pressure) messages.push("Well flowing pressure is required.");
    if (Number(pressure) <= 0)
      messages.push("Well flowing Pressure must be greater than 0.");
    if (!h2s) messages.push("H2S concentration is required.");
    if (Number(h2s) < 0) messages.push("H2S concentration must be positive.");
    if (messages.length > 0) {
      setVariant("msg-error w300");
      setMessage(messages);
    } else {
      const pr = toPSI(Number.parseFloat(pressure), prUnit);
      const h2 = toPPM(Number.parseFloat(h2s), h2sUnit);
      const h2sPP = (pr * h2) / 1000000;
      messages.push(`The calculated H2S partial pressure = ${h2sPP} psi.`);
      if (h2sPP <= 1.5) {
        messages.push(
          "It is safe to flow the well through piping and MPFM flow meter made of Duplex material in these conditions."
        );
        setVariant("msg-success w300");
      } else {
        messages.push(
          "It is not safe to flow the well through piping and MPFM flow meter made of Duplex material in these conditions !"
        );
        messages.push("You should consider using Inconel material !");
        setVariant("msg-warning w300");
      }
      setMessage(messages);
    }
  };

  return (
    <section id="h2spp">
      <div className="container">
        <div className="section-title">
          <h1>
            <FaSkullCrossbones /> H<sub>2</sub>S Partial Pressure Calculator
          </h1>
        </div>
        <div className="service-card">
          <div className="service-image">
            <img src="../img/mpfm4.jpg" alt="multiphase flow meter" />
          </div>
          <div className="service-form">
            <form>
              <div className={variant}>
                {message.map((el, i) => (
                  <div className="message-div" key={i}>{`${i + 1}. ${el}`}</div>
                ))}
              </div>
              <div className="input-container">
                <div className="group-input">
                  <div className="div-input">
                    <input
                      type="number"
                      placeholder="Flowing Pressure"
                      onChange={(e) => setPressure(e.target.value)}
                    />
                  </div>
                  <div className="div-input">
                    <input
                      type="number"
                      placeholder="H2S Concentration"
                      onChange={(e) => setH2s(e.target.value)}
                    />
                  </div>
                </div>
                <div className="group-input">
                  <div className="div-input">
                    <select onChange={(e) => setPrUnit(e.target.value)}>
                      <option value="psi">psi</option>
                      <option value="bar">bar</option>
                      <option value="atm">atm</option>
                      <option value="Pa">Pa</option>
                      <option value="kg/cm2">kg/cm2</option>
                      <option value="mmHg">torr (mmHg)</option>
                    </select>
                  </div>
                  <div className="div-input">
                    <select onChange={(e) => setH2sUnit(e.target.value)}>
                      <option value="ppm">ppm</option>
                      <option value="%">%</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="button-container">
                <button className="btn" onClick={(e) => h2sPP(e)}>
                  Simulate H<sub>2</sub>S <FaSkullCrossbones />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default H2S;
