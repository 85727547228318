export const formatNumber = (number) => {
  const suffixes = ["", "k", "M", "B", "T"];
  const numLength = number.toString().length;
  const suffixIndex = Math.floor((numLength - 1) / 3);
  const shortNum = parseFloat(
    (number / Math.pow(1000, suffixIndex)).toFixed(1)
  );
  return shortNum + suffixes[suffixIndex];
};

export const truncateToOneDecimal = (value) => {
  // Ensure the value is between 0 and 5
  if (value < 0) value = 0;
  if (value > 5) value = 5;

  // Truncate the value to one decimal place
  return (Math.floor(value * 10) / 10).toFixed(1);
};
