import {
  FaHome,
  FaThLarge,
  FaTh,
  FaChartBar,
  FaQuestion,
  FaLayerGroup,
  FaComments,
  FaChalkboard,
  FaInfo,
  FaRegEnvelopeOpen,
  FaUsers,
  FaBirthdayCake,
} from "react-icons/fa";

export const menuData = [
  {
    path: "/",
    icon: <FaHome />,
    title: "Home",
    access: "public",
  },
  {
    path: "/support",
    icon: <FaTh />,
    title: "Utilities",
    access: "public",
  },
  {
    path: "/courses",
    icon: <FaChalkboard />,
    title: "Courses",
    access: "private",
  },

  {
    path: "/questions",
    icon: <FaQuestion />,
    title: "Questions",
    access: "admin",
  },
  {
    path: "/exams",
    icon: <FaLayerGroup />,
    title: "Exams",
    access: "admin",
  },
  {
    path: "/reviews",
    icon: <FaComments />,
    title: "Reviews",
    access: "admin",
  },
  {
    path: "/analytics/users",
    icon: <FaChartBar />,
    title: "Analytics",
    access: "admin",
  },
  {
    path: "/dashboard",
    icon: <FaThLarge />,
    title: "Dashboard",
    access: "admin",
  },

  {
    path: "/contact",
    icon: <FaRegEnvelopeOpen />,
    title: "Contact",
    access: "public",
  },
  {
    path: "/about",
    icon: <FaInfo />,
    title: "About us",
    access: "public",
  },
];

export const menuAnlytics = [
  { path: "/users", icon: <FaUsers />, title: "Users" },
  { path: "/exams", icon: <FaLayerGroup />, title: "Exams" },
  { path: "/birthday", icon: <FaBirthdayCake />, title: "Birthdays" },
];
