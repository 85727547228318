import "./auth.css";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { clearMessage, registerUser } from "../../app/features/auth/authSlice";
import { addUser, resetUsers } from "../../app/features/user/userSlice";
import { genders } from "../../constants/userGenders";
import {
  FaArrowLeft,
  FaEnvelope,
  FaLock,
  FaUser,
  FaUserTie,
} from "react-icons/fa";
import { capitalizeString } from "../../utils/formatName";

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { auth, message } = useSelector((state) => state.auth);
  const userMessage = useSelector((state) => state.users.message);

  const [userData, setUserData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    gender: "",
    password: "",
    password2: "",
    isAdmin: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(resetUsers());
  }, [dispatch]);

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        dispatch(clearMessage());

        if (
          message ===
          "Registration successful. Please check your email to verify your account."
        ) {
          navigate("/verification-sent");
        }
      }, 3000);
    }
  }, [message, dispatch, navigate]);

  //Not used now with email verification logic
  // useEffect(() => {
  //   if (auth && !auth.isAdmin) {
  //     navigate(`/profile/${auth._id}`);
  //   }
  // }, [auth, navigate]);

  const handleNewUser = (e) => {
    const { name, type, checked, value } = e.target;
    setUserData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleRegisterUser = async (e) => {
    e.preventDefault();
    userData.email = userData.email.toLowerCase();
    userData.firstname = capitalizeString(userData.firstname);
    userData.lastname = capitalizeString(userData.lastname);
    if (auth && auth.isAdmin) {
      dispatch(addUser(userData));
    } else {
      dispatch(registerUser(userData));
    }
  };

  return (
    <div className="auth-container">
      <form className="auth-form" onSubmit={handleRegisterUser}>
        <h2>{auth ? "Create a new account" : "Create your account"}</h2>
        {auth && (
          <p>
            <Link to="/dashboard" className="link">
              <FaArrowLeft /> Back to users list...
            </Link>
          </p>
        )}

        {message && <div className="message">{message}</div>}
        {userMessage && <div className="message">{userMessage}</div>}
        <div className="input-group">
          <label htmlFor="firstname">
            <FaUser />
          </label>
          <input
            type="text"
            id="firstname"
            name="firstname"
            placeholder="First name..."
            required
            value={userData.firstname}
            onChange={handleNewUser}
          />
        </div>
        <div className="input-group">
          <label htmlFor="lastname">
            <FaUser />
          </label>
          <input
            type="text"
            id="lastname"
            name="lastname"
            required
            placeholder="Last name..."
            value={userData.lastname}
            onChange={handleNewUser}
          />
        </div>
        <div className="input-group">
          <label htmlFor="email">
            <FaEnvelope />
          </label>
          <input
            type="email"
            id="email"
            name="email"
            required
            placeholder="Email address..."
            value={userData.email}
            onChange={handleNewUser}
          />
        </div>
        <div className="input-group">
          <label htmlFor="gender">
            {genders.find((gender) => gender.sex === userData.gender).icon}
          </label>
          <select
            id="gender"
            name="gender"
            required
            value={userData.gender}
            onChange={handleNewUser}
          >
            {genders.map((gender, idx) => (
              <option value={gender.sex} key={idx}>
                {gender.name}
              </option>
            ))}
          </select>
        </div>
        <div className="input-group">
          <label htmlFor="password">
            <FaLock />
          </label>
          <input
            type="password"
            id="password"
            name="password"
            required
            placeholder="Create password..."
            value={userData.password}
            onChange={handleNewUser}
          />
        </div>
        <div className="input-group">
          <label htmlFor="password2">
            <FaLock />
          </label>
          <input
            type="password"
            id="password2"
            name="password2"
            required
            placeholder="Confirm password..."
            value={userData.password2}
            onChange={handleNewUser}
          />
        </div>

        {auth && auth.isAdmin && (
          <>
            <p>
              An administrator, will have the rights to add courses, modify
              courses, delete courses, add users, modify users details, and
              delete users.
            </p>

            <p>
              <span>
                Check the box to grant this user the administrator role{" "}
                {userData.isAdmin ? <FaUserTie color="orange" /> : <FaUser />}
              </span>
              <input
                type="checkbox"
                id="isAdmin"
                name="isAdmin"
                checked={userData.isAdmin}
                onChange={handleNewUser}
              />
            </p>
          </>
        )}

        <button type="submit" className="btn">
          Create account
        </button>

        {!auth && (
          <p>
            <Link className="link" to="/login">
              Already have an account? Log in...
            </Link>
          </p>
        )}
      </form>
    </div>
  );
};

export default Register;
