import jsPDF from "jspdf";
import html2canvas from "html2canvas";

// new funcrion to convert html 2 pdf
export const convertPDF = (content, filename) => {
  html2canvas(content, { scale: 2 })
    .then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const pdf = new jsPDF("l", "mm", "a4", true);
      const width = pdf.internal.pageSize.getWidth();
      const height = (canvas.height * width) / canvas.width;
      pdf.addImage(imgData, "jpeg", 0, 0, width, height);
      pdf.save(filename);
    })
    .catch((error) => console.error("Unable to generate PDF", error));
};
