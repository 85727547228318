import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

const initialState = {
  exam: null,
  count: null,
  passed: null,
  exams: [],
  message: "",
  isLoading: false,
};

// Fetch exams by query
export const getExams = createAsyncThunk(
  "exams/fetch",
  async ({ user, course }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const config = { headers: { authorization: token } };
      const response = await axios.get(
        `${API_URL}/api/exams?user=${user}&course=${course}`,
        config
      );
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Add new exam by examData = {score, course, coursename}
export const addExam = createAsyncThunk(
  "exam/add",
  async (examData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const config = { headers: { authorization: token } };
      const response = await axios.post(
        `${API_URL}/api/exams`,
        examData,
        config
      );
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get exam by id for certificate
export const getExam = createAsyncThunk("exam/fetch", async (id, thunkAPI) => {
  try {
    const response = await axios.get(`${API_URL}/api/exams/${id}`);
    return response.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const examSlice = createSlice({
  name: "exam",
  initialState,
  reducers: {
    resetExams: (state) => {
      state.exams = [];
      state.message = "";
      state.isLoading = false;
    },
    clearMessage: (state) => {
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getExams.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getExams.fulfilled, (state, action) => {
        state.count = action.payload.count;
        state.passed = action.payload.passed;
        state.maximum = action.payload.maximum;
        state.average = action.payload.average;
        state.initialYear = action.payload.initialYear;
        state.currentYear = action.payload.currentYear;
        state.exams = [...action.payload.exams];
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(getExams.rejected, (state, action) => {
        state.exams = [];
        state.message = action.payload;
        state.isLoading = false;
      })
      .addCase(addExam.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addExam.fulfilled, (state, action) => {
        state.exam = action.payload.exam;
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(addExam.rejected, (state, action) => {
        state.exams = [];
        state.message = action.payload;
        state.isLoading = false;
      })
      .addCase(getExam.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getExam.fulfilled, (state, action) => {
        state.exam = action.payload.exam;
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(getExam.rejected, (state, action) => {
        state.exams = [];
        state.message = action.payload;
        state.isLoading = false;
      });
  },
});

export const { resetExams, clearMessage } = examSlice.actions;
export default examSlice.reducer;
