import "./courseslist.css";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetUsers } from "../../app/features/user/userSlice";
import {
  getCourses,
  clearMessage,
} from "../../app/features/course/courseSlice";
import { languages, categories } from "../../constants/courseOptions";
import {
  FaChalkboard,
  FaComments,
  FaPlus,
  FaStar,
  FaLayerGroup,
  FaSearch,
} from "react-icons/fa";
import CoursesTable from "./CoursesTable/CoursesTable";
import DashCard from "../../components/DashCard/DashCard";
import Pagination from "../../components/Pagination/Pagination";

const CoursesList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { auth } = useSelector((state) => state.auth);
  const { count, courses, message, averageRate, coursesQuiz, totalReviews } =
    useSelector((state) => state.courses);

  const [query, setQuery] = useState({
    cat: location?.state?.category || "",
    lang: "",
    quiz: "",
    page: 1,
    limit: 25,
    search: "",
  });
  const dashCards = [
    { icon: <FaChalkboard />, value: count, text: "Total Courses" },
    { icon: <FaLayerGroup />, value: coursesQuiz, text: "Courses have a Quiz" },
    { icon: <FaComments />, value: totalReviews, text: "Total Reviews" },
    {
      icon: <FaStar />,
      value: averageRate?.toFixed(1),
      text: "Overall Average Rate",
    },
  ];
  const handleChange = (e) => {
    setQuery((prev) => {
      return {
        ...prev,
        page: 1,
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
      };
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getCourses(query));
    dispatch(resetUsers());
  }, [dispatch, query]);

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        dispatch(clearMessage());
      }, 2000);
    }
  }, [message, dispatch]);

  return (
    <div className="container">
      <h1>Courses</h1>
      {auth && auth.isAdmin && (
        <header>
          <button
            className="primary-btn"
            onClick={() => navigate("/courses/add")}
          >
            <FaPlus /> Add new course
          </button>
        </header>
      )}

      <section className="dash-cards">
        {dashCards.map((dash, idx) => (
          <DashCard dash={dash} key={idx} />
        ))}
      </section>

      <section className="dash-search">
        <form className="auth-form">
          <div className="input-group">
            <label>
              <FaSearch />
            </label>
            <input
              id="search"
              name="search"
              type="search"
              placeholder="Search courses..."
              onChange={handleChange}
            />
          </div>
        </form>
        <div className="filter">
          <div>
            <select
              id="lang"
              name="lang"
              value={query.lang}
              onChange={handleChange}
            >
              <option value="">All languages</option>
              {languages.map((lang, idx) => (
                <option key={idx} value={lang}>
                  {lang}
                </option>
              ))}
            </select>
          </div>
          <div>
            <select
              id="cat"
              name="cat"
              value={query.cat}
              onChange={handleChange}
            >
              <option value="">All categories</option>
              {categories.map((cat, idx) => (
                <option key={idx} value={cat}>
                  {cat}
                </option>
              ))}
            </select>
          </div>
          <div>
            <input
              id="quiz"
              name="quiz"
              type="checkbox"
              value={query.quiz}
              onChange={handleChange}
            />
            <label htmlFor="quiz"> Course has a Quiz</label>
          </div>
        </div>
        {query.search && <p>Total courses found: {count}</p>}
      </section>

      <section>
        {courses && courses.length > 0 && (
          <>
            <h2>Choose a course</h2>
            <CoursesTable
              courses={courses}
              page={query.page}
              limit={query.limit}
            />
            <select
              id="limit"
              name="limit"
              value={query.limit}
              onChange={handleChange}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            {courses && count && count > query.limit && (
              <Pagination
                page={query.page}
                limit={query.limit}
                count={count}
                paginate={(p) => setQuery({ ...query, page: p })}
              />
            )}
          </>
        )}
      </section>
    </div>
  );
};

export default CoursesList;
