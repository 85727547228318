import "./addeditcourse.css";
import { useEffect, useState } from "react";
import {
  FaArrowLeft,
  FaClock,
  FaGlobe,
  FaThList,
  FaYoutube,
  FaParagraph,
  FaHeading,
  FaPhotoVideo,
  FaDollarSign,
  FaCogs,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  addCourse,
  getCourse,
  updateCourse,
  resetCourses,
  clearMessage,
} from "../../app/features/course/courseSlice";
import { languages, categories } from "../../constants/courseOptions";

const AddEditCourse = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { auth } = useSelector((state) => state.auth);
  const { course, message } = useSelector((state) => state.courses);
  const [courseData, setCourseData] = useState({
    title: "",
    descr: "",
    price: "",
    skills: "",
    youtube: "",
    duration: "",
    language: "",
    category: "",
    coverURL: "",
    isPublic: false,
    isQuiz: false,
  });

  useEffect(() => {
    if (!auth?.isAdmin) {
      navigate("/courses");
    }
  }, [auth, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(resetCourses());
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(getCourse(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        dispatch(clearMessage());
      }, 2000);
    }
  }, [message, dispatch]);

  useEffect(() => {
    if (id && course) {
      setCourseData({
        title: course.title,
        descr: course.descr,
        price: course.price,
        skills: course.skills.join(", "),
        youtube: course.youtube,
        duration: course.duration,
        language: course.language,
        category: course.category,
        coverURL: course.coverURL,
        isPublic: course.isPublic,
        isQuiz: course.isQuiz,
      });
    }
  }, [id, course]);

  const handleCourseData = (e) => {
    const { type, name, value, checked } = e.target;
    setCourseData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSaveCourse = async (e) => {
    e.preventDefault();
    if (id) {
      dispatch(updateCourse({ id: id, course: courseData }));
    } else {
      dispatch(addCourse(courseData));
      setCourseData({
        title: "",
        descr: "",
        price: 0,
        skills: "",
        youtube: "",
        duration: "",
        language: "",
        category: "",
        isPublic: false,
        isQuiz: false,
      });
    }
  };

  return (
    <div className="container">
      <h1>{id ? "Edit course" : "Add new course"}</h1>
      <header>
        <button className="circle-btn" onClick={() => navigate("/courses")}>
          <FaArrowLeft />
        </button>
      </header>

      <form className="auth-form" onSubmit={handleSaveCourse}>
        <div className="input-group">
          <label htmlFor="title">
            <FaHeading />
          </label>
          <input
            id="title"
            name="title"
            type="text"
            required
            placeholder="Add course title..."
            value={courseData.title}
            onChange={handleCourseData}
          />
        </div>
        <div className="input-group">
          <label htmlFor="descr">
            <FaParagraph />
          </label>
          <textarea
            id="descr"
            name="descr"
            placeholder="Add course description..."
            rows="3"
            required
            value={courseData.descr}
            onChange={handleCourseData}
          ></textarea>
        </div>
        <div className="input-group">
          <label htmlFor="youtube">
            <FaYoutube />
          </label>
          <input
            id="youtube"
            name="youtube"
            type="text"
            required
            placeholder="Add course YouTube ID..."
            value={courseData.youtube}
            onChange={handleCourseData}
          />
        </div>
        <div className="input-group">
          <label htmlFor="duration">
            <FaClock />
          </label>
          <input
            id="duration"
            name="duration"
            type="text"
            required
            placeholder="Add course duration (mm:ss)..."
            value={courseData.duration}
            onChange={handleCourseData}
          />
        </div>
        <div className="input-group">
          <label htmlFor="language">
            <FaGlobe />
          </label>
          <select
            id="language"
            name="language"
            required
            value={courseData.language}
            onChange={handleCourseData}
          >
            <option value="">Choose Language</option>
            {languages.map((language, idx) => (
              <option key={idx} value={language}>
                {language}
              </option>
            ))}
          </select>
        </div>
        <div className="input-group">
          <label htmlFor="category">
            <FaThList />
          </label>
          <select
            id="category"
            name="category"
            required
            value={courseData.category}
            onChange={handleCourseData}
          >
            <option value="">Choose Category</option>
            {categories.map((cat, idx) => (
              <option key={idx} value={cat}>
                {cat}
              </option>
            ))}
          </select>
        </div>
        <div className="input-group">
          <label htmlFor="skills">
            <FaCogs />
          </label>
          <input
            id="skills"
            name="skills"
            type="text"
            required
            placeholder="Add course skills separated by comma (,)..."
            value={courseData.skills}
            onChange={handleCourseData}
          />
        </div>
        <div className="input-group">
          <label htmlFor="coverURL">
            <FaDollarSign />
          </label>
          <input
            id="price"
            name="price"
            type="number"
            min="0"
            step=".01"
            required
            placeholder="Add course price $..."
            value={courseData.price}
            onChange={handleCourseData}
          />
        </div>
        <div className="input-group">
          <label htmlFor="coverURL">
            <FaPhotoVideo />
          </label>
          <input
            id="coverURL"
            name="coverURL"
            type="file"
            onChange={(e) =>
              setCourseData((prev) => ({
                ...prev,
                coverURL: `/img/${e.target.files[0].name}`,
              }))
            }
          />
        </div>

        <div className="input_options">
          <p>
            <label htmlFor="isQuiz">Course has a Quiz?</label>
            <input
              type="checkbox"
              id="isQuiz"
              name="isQuiz"
              checked={courseData.isQuiz}
              onChange={handleCourseData}
            />
          </p>
          <p>
            <label htmlFor="isPublic">Publish course for students?</label>
            <input
              type="checkbox"
              id="isPublic"
              name="isPublic"
              checked={courseData.isPublic}
              onChange={handleCourseData}
            />
          </p>
        </div>

        {message && <div className="message">{message}</div>}

        <button type="submit" className="btn">
          {id ? "Save changes" : "Add course"}
        </button>
      </form>
      <br />
      <br />
      {course && course.coverURL && <img src={course.coverURL} alt="cover" />}
    </div>
  );
};

export default AddEditCourse;
