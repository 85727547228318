import "./certificate.css";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getExam, resetExams } from "../../app/features/exam/examSlice";
import { formatName } from "../../utils/formatName";
import { convertPDF } from "../../utils/convertPDF";
import {
  FaAward,
  FaChalkboardTeacher,
  FaDownload,
  FaExclamationTriangle,
} from "react-icons/fa";
import QRCode from "qrcode";

const Certificate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { auth } = useSelector((state) => state.auth);
  const { exam, isLoading } = useSelector((state) => state.exams);
  const [qrCode, setQrCode] = useState("");

  const certificateRef = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(resetExams());
    // function to generate verification QR code
    const generateQR = () => {
      QRCode.toDataURL(`${process.env.REACT_APP_BASE_URL}/verify/${id}`, {
        margin: 0,
      })
        .then((url) => setQrCode(url))
        .catch((err) => console.log(err));
    };

    dispatch(getExam(id));
    generateQR();
  }, [dispatch, id]);

  const handlePDF = () => {
    const certificate = certificateRef.current;
    const filename = `${exam.username.split(" ").join("_")}_Certificate_ID_${
      exam._id
    }.pdf`;
    convertPDF(certificate, filename);
  };

  return (
    <div className="container">
      {exam ? (
        exam.isPass ? (
          <div>
            <div className="certificate-container">
              <div id="certificate" ref={certificateRef}>
                <div className="logo-img">
                  <img src="/img/kws-logo.png" alt="KWS Logo" width="140px" />
                  <img src={qrCode} alt="QR Code" />
                </div>

                <div>
                  <h2>CERTIFICATE AWARDED TO</h2>
                  <h1>{formatName(exam.username, 43).toUpperCase()}</h1>
                  <p>
                    IN RECOGNITION OF THE SUCCESSFULL COMPLETION OF THE ONLINE
                    COURSE OF
                  </p>
                  <h1>{exam.coursename.toUpperCase()}</h1>
                </div>

                <div className="certificate-badge">
                  <FaAward />
                </div>

                <div className="certificate-dates">
                  <span>
                    <strong>Issue date:</strong>{" "}
                    {new Date(exam.createdAt).toDateString()}
                  </span>
                  <span>
                    <strong>Expiry date:</strong>{" "}
                    {new Date(exam.expiresAt).toDateString()}
                  </span>
                </div>

                <div className="certificate-id">Certificate ID: {exam._id}</div>

                <div className="certificate-footer">
                  Verified by &copy;{" "}
                  <strong>{process.env.REACT_APP_NAME}</strong>, this
                  certificate can be verified online at www.
                  {process.env.REACT_APP_DOMAIN} using its unique Certificate ID
                  or scan QR code.
                </div>
              </div>
            </div>

            <div className="course-info">
              {auth && (auth._id === exam.user || auth.isAdmin) ? (
                <button className="btn enroll-btn" onClick={handlePDF}>
                  <FaDownload /> Download Certificate
                </button>
              ) : (
                <strong>
                  <Link to={`/course/${exam.course}`} className="link">
                    <FaChalkboardTeacher /> {exam.coursename}
                  </Link>
                </strong>
              )}

              {auth && auth._id === exam.user ? (
                <div className="linkedin-btn">
                  <a
                    href={`https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${
                      exam.coursename
                    }&organizationId=88030191&issueYear=${new Date(
                      exam.createdAt
                    ).getFullYear()}&issueMonth=${
                      new Date(exam.createdAt).getMonth() + 1
                    }&expirationYear=${new Date(
                      exam.expiresAt
                    ).getFullYear()}&expirationMonth=${
                      new Date(exam.expiresAt).getMonth() + 1
                    }&certUrl=${
                      process.env.REACT_APP_BASE_URL
                    }/certificate/${id}&certId=${id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="/img/en_US.png"
                      alt="Add Certificate to LinkedIn"
                    />
                  </a>
                </div>
              ) : auth ? (
                <button
                  className="btn enroll-btn"
                  onClick={() => navigate(`/quiz/${exam.course}`)}
                >
                  Take a Quiz for Free
                </button>
              ) : (
                <button
                  className="btn enroll-btn"
                  onClick={() => navigate("/register")}
                >
                  Enroll for Free
                </button>
              )}
            </div>
          </div>
        ) : (
          <div className="certificate-error">
            <FaExclamationTriangle color="orange" size={68} />
            <h3>{`${exam.username} did not yet pass the quiz for the ${exam.coursename} course!`}</h3>
          </div>
        )
      ) : (
        !isLoading && (
          <div className="certificate-error">
            <FaExclamationTriangle color="orange" size={68} />
            <h3>Invalid Certificate ID !</h3>
          </div>
        )
      )}
    </div>
  );
};

export default Certificate;
