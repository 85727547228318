import "./chartbarhorizontaldual.css";

//variant: dark, light, happy

const ChartBarHorizontalDual = ({ value1, value2, max }) => {
  return (
    <div className="outer">
      <div
        className="inner  left"
        style={{ width: `${max > 0 ? (100 * value1) / max : 0}%` }}
      >
        {value1 > 0 && value1}
      </div>
      <div
        className="inner  right"
        style={{ width: `${max > 0 ? (100 * value2) / max : 0}%` }}
      >
        {value2 > 0 && value2}
      </div>
    </div>
  );
};

export default ChartBarHorizontalDual;
