import "./testimonialmodal.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FaPen,
  FaPlus,
  FaQuoteLeft,
  FaRegPaperPlane,
  FaTimes,
} from "react-icons/fa";
import Rating from "../../../components/Rating/Rating";
import { updateUser } from "../../../app/features/user/userSlice";

const TestimonialModal = ({ user }) => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state.auth);
  const [review, setReview] = useState({});
  const [show, setShow] = useState(false);
  const messages = [];
  const minText = 30;
  const maxText = 150;

  useEffect(() => {
    if (!auth?.isAdmin && user.courses.length > 0 && !user.testimonial)
      setShow(true);
  }, [auth, user, dispatch]);

  if (!review.rate) messages.push("Please give us your stars rating.");
  if (!review.text) messages.push("Please write us your testimony comment.");

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setReview(user.testimonial || {});
    setShow(true);
  };

  const handleNewReview = (e) => {
    e.preventDefault();
    if (
      review.rate &&
      review.text &&
      (review.rate !== user.testimonial?.rate ||
        review.text !== user.testimonial?.text)
    ) {
      dispatch(
        updateUser({
          id: user._id,
          user: {
            testimonial: { ...review, date: Date.now() },
          },
        })
      );
      setTimeout(() => handleClose(), 1000);
    }
  };

  return (
    <>
      <button
        className="circle-btn"
        onClick={handleShow}
        title={user.testimonial ? "Edit testimony" : "Add testimony"}
      >
        {user.testimonial ? <FaPen /> : <FaPlus />}
      </button>

      <div className={show ? "modal show" : "modal"}>
        <div className="modal-container">
          <div className="modal-content">
            <div className="modal-header">
              <h2>{user.testimonial ? "Edit testimony" : "Add testimony"}</h2>
              {(user.testimonial || auth.isAdmin) && (
                <button className="close-btn" onClick={handleClose}>
                  <FaTimes />
                </button>
              )}
            </div>
            {messages.length > 0 && (
              <div className="message">
                {messages.map((msg, i) => (
                  <p key={i}>{msg}</p>
                ))}
              </div>
            )}
            <div className="modal-body">
              <form className="auth-form" onSubmit={(e) => handleNewReview(e)}>
                <div className="stars">
                  <Rating
                    count={5}
                    value={review.rate}
                    edit={true}
                    setNewRating={(newRate) => {
                      setReview({ ...review, rate: newRate });
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="text">
                    <FaQuoteLeft />
                  </label>
                  <textarea
                    id="text"
                    name="text"
                    placeholder="Please share your learning experience with us..."
                    autoFocus
                    rows="5"
                    required
                    value={review.text || ""}
                    onChange={(e) => {
                      setReview({ ...review, text: e.target.value });
                    }}
                  ></textarea>
                </div>
                <small className="reviewer-msg">
                  {review.text?.length < minText &&
                    `Write at least ${
                      minText - review.text.length
                    } more character${
                      minText - review.text.length > 1 ? "s" : ""
                    }...`}
                  {review.text?.length > maxText &&
                    `Remove ${review.text.length - maxText} character${
                      review.text.length - maxText > 1 ? "s" : ""
                    }...`}
                </small>

                <button
                  className="btn"
                  type="submit"
                  disabled={
                    !review.rate ||
                    !review.text ||
                    review.text?.length < minText ||
                    review.text?.length > maxText ||
                    (review.rate === user.testimonial?.rate &&
                      review.text === user.testimonial?.text)
                  }
                >
                  <FaRegPaperPlane />{" "}
                  {user.testimonial ? "Save my review" : "Add my review"}{" "}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestimonialModal;
