import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import {
  logoutUser,
  resendVerification,
} from "../../app/features/auth/authSlice";

const VerificationError = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { auth } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.quiz);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSendVerification = () => {
    dispatch(resendVerification(auth._id));
    dispatch(logoutUser(auth._id));
    navigate("/verification-sent");
  };

  if (auth && auth.isVerified) return <Navigate to={`/profile/${auth._id}`} />;

  return (
    <div className="container">
      {auth && !auth.isVerified && (
        <>
          {message && <div className="message">{message}</div>}

          {auth?.verificationToken ? (
            <>
              <h2>Account Verification Required!</h2>
              <br />
              <p>
                A verification email has been sent to your registered email
                address. Please check your inbox and follow the instructions to
                verify your account.
              </p>
              <br />
              <p>
                If you do not receive the email, it could mean that the email
                address you provided is either invalid or inactive. In this
                case, please update your account with a valid and active email
                address to proceed.
              </p>
              <br />
              <p>
                Additionally, please check your junk or spam box, as the email
                might have been filtered there by mistake.
              </p>
              <br />
              <p>Thank you!</p>
              <br />
            </>
          ) : (
            <>
              <h2>Account Verification Required!</h2>
              <br />
              <h2>Step by step procedure:</h2>
              <br />
              <ol>
                <li>
                  Make sure your registered email address is Valid and Active,
                  then click the button below to have your verification URL link
                  sent to your inbox.
                </li>
                <li>
                  If your current email address is not Valid or not Active,
                  please edit your Contact info in your profile page and provide
                  a valid email address.
                </li>
                <li>
                  Once you hit the below button, proceed to your email inbox and
                  follow the instruction we sent you in order to verify your
                  account.
                </li>
                <li>
                  Additionally, please check your junk or spam box, as the email
                  might have been filtered there by mistake.
                </li>
              </ol>
            </>
          )}

          <button className="btn" onClick={handleSendVerification}>
            {auth?.verificationToken
              ? "Resend me Verification Instruction"
              : "Send me Verification Instruction"}
          </button>
        </>
      )}
    </div>
  );
};

export default VerificationError;
