import "./addeditquestion.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FaArrowLeft, FaPlus, FaTimes } from "react-icons/fa";
import {
  addQuestion,
  clearMessage,
  getQuestion,
  updateQuestion,
} from "../../app/features/question/questionSlice";
import QuestionCard from "../../components/QuestionCard/QuestionCard";
import OptionAnswerInput from "./OptionAnswerInput";

const AddEditQuestion = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { message, question, courses } = useSelector(
    (state) => state.questions
  );
  const types = ["text", "radio", "select", "phrase", "number", "checkbox"];
  const points = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.25, 2.5];
  const optionsItem = { value: "", prefix: "", select: ["", ""], label: "" };
  const initialData = {
    type: "",
    points: 0.25,
    statement: "",
    inputs: [],
    image: "",
    extra: "",
    note: "",
    options: [optionsItem],
    courses: [],
    answer: [""],
  };
  const [errors, setErrors] = useState([]);
  const [questionData, setQuestionData] = useState(initialData);

  useEffect(() => {
    if (id) {
      dispatch(getQuestion(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        dispatch(clearMessage());
      }, 2000);
    }
  }, [message, dispatch]);

  useEffect(() => {
    if (question && id !== "new") {
      setQuestionData({
        type: question.type,
        points: question.points,
        statement: question.statement,
        inputs: [...question.inputs],
        image: question.image || "",
        extra: question.extra || "",
        note: question.note || "",
        options: [
          ...question.options.map((option) => ({
            value: option.value || "",
            prefix: option.prefix || "",
            select: option.select,
            label: option.label || "",
          })),
        ],
        courses: question.courses,
        answer: question.answer.map((el) =>
          el === "false" ? false : el === "true" ? true : el
        ),
      });
    }
  }, [id, question]);

  // An older version for handling answer array -- not really used now
  // Newer logic version has been implemented below -- thank you Noomen
  // Kept the code for reference only, just in case -- thank you Noomen :D
  // useEffect(() => {
  //   if (id === "new") {
  //     const answer =
  //       questionData.type === "radio"
  //         ? [""]
  //         : new Array(questionData.options.length).fill(
  //             questionData.type === "checkbox" ? false : ""
  //           );
  //     setQuestionData((prev) => ({ ...prev, answer }));
  //   }
  // }, [id, questionData.type, questionData.options.length]);

  // Handling courses array
  const handleQuestionCourses = (evt) => {
    const { selectedOptions } = evt.target;
    const courses = [...selectedOptions].map((opt) => opt.value);
    setQuestionData((prev) => ({ ...prev, courses }));
  };

  // Handling question input field change
  const handleChange = (evt) => {
    const { name, value } = evt.target;
    setQuestionData((prev) => ({ ...prev, [name]: value }));
    setErrors([]);
    if (name === "type") {
      // Ensuring minimum allowed options when question type changes for radio and checkbox min 2 opt.
      const options = [...questionData.options];
      if ((value === "radio" || value === "checkbox") && options.length === 1) {
        options.push(optionsItem);
      }
      // Resetting answer array to default blanks
      const answer =
        value === "radio"
          ? [""]
          : new Array(options.length).fill(value === "checkbox" ? false : "");
      setQuestionData((prev) => ({
        ...prev,
        points: 0.25 * answer.length,
        answer,
        options,
      }));
    }
  };

  // Handling inputs array
  const handleAddInput = () => {
    const inputs = [...questionData.inputs];
    inputs.push("");
    setQuestionData((prev) => ({ ...prev, inputs }));
  };
  const handleRemoveInput = (idx) => {
    const inputs = [...questionData.inputs];
    inputs.splice(idx, 1);
    setQuestionData((prev) => ({ ...prev, inputs }));
  };
  const handleInputChange = (evt, idx) => {
    const { value } = evt.target;
    const inputs = [...questionData.inputs];
    inputs[idx] = value;
    setQuestionData((prev) => ({ ...prev, inputs }));
  };

  // Handling options array
  const handleAddOption = () => {
    const answer = [...questionData.answer];
    const options = [...questionData.options];
    // Adding answer blank default value
    if (questionData.type !== "radio") {
      answer.push(questionData.type === "checkbox" ? false : "");
    }
    // Adding option input item
    options.push(optionsItem);
    setQuestionData((prev) => ({
      ...prev,
      points: 0.25 * answer.length,
      answer,
      options,
    }));
  };
  const handleRemoveOption = (idx) => {
    const answer = [...questionData.answer];
    const options = [...questionData.options];
    // Removing answer value
    if (questionData.type === "radio") {
      answer[0] = answer[0] === options[idx].value ? "" : answer[0];
    } else {
      answer.splice(idx, 1);
    }
    // Removing option input item
    options.splice(idx, 1);
    setQuestionData((prev) => ({
      ...prev,
      points: 0.25 * answer.length,
      answer,
      options,
    }));
  };
  const handleOptionChange = (evt, idx) => {
    const { name, value } = evt.target;
    const options = [...questionData.options];
    options[idx][name] = value;
    setQuestionData((prev) => ({ ...prev, options }));
  };
  const handleOptionSelect = (select, idx) => {
    const options = [...questionData.options];
    const option = { ...options[idx], select };
    options[idx] = option;
    const answer = [...questionData.answer];
    answer[idx] = options[idx].select.includes(answer[idx]) ? answer[idx] : "";
    setQuestionData((prev) => ({ ...prev, options, answer }));
  };

  // Handling answer array
  const handleOptionAnswerChange = (data, idx) => {
    const answer = [...questionData.answer];
    if (questionData.type === "radio") {
      answer[0] = data;
    } else {
      answer[idx] = data;
    }
    setQuestionData((prev) => ({ ...prev, answer }));
  };

  // Handling form submit
  const handleSubmit = (evt) => {
    evt.preventDefault();
    questionData.points = Number(questionData.points);

    // Handling errors if missing answers
    const errors2 = questionData.answer
      .map((el, i) =>
        el === "" ? `Please add the answer for the option ${i + 1}.` : ""
      )
      .filter((el) => el !== "");
    setErrors(errors2);

    // Sending data to db
    if (errors2.length === 0) {
      //console.log(questionData);
      if (id === "new") {
        dispatch(addQuestion(questionData));
      } else {
        dispatch(updateQuestion({ id, questionData }));
      }

      // Reset questionData
      setQuestionData(initialData);
    }
  };

  return (
    <div className="container">
      <h1>{id === "new" ? "Add new question" : "Edit question"}</h1>
      <header>
        <button className="circle-btn" onClick={() => navigate("/questions")}>
          <FaArrowLeft />
        </button>
      </header>

      <form className="auth-form" onSubmit={handleSubmit}>
        <div className="question-input">
          <label htmlFor="courses">Courses</label>
          <select
            id="courses"
            name="courses"
            required
            multiple
            size="5"
            value={questionData.courses}
            onChange={handleQuestionCourses}
          >
            <option value="">Choose courses...</option>
            {courses.map((course) => (
              <option key={course._id} value={course._id}>
                {course.title}
              </option>
            ))}
          </select>
        </div>

        <div className="question-input">
          <label htmlFor="type">Type</label>
          <select
            id="type"
            name="type"
            required
            value={questionData.type}
            onChange={handleChange}
          >
            <option value="">Choose type...</option>
            {types.map((type, idx) => (
              <option value={type} key={idx}>
                {type}
              </option>
            ))}
          </select>
        </div>

        <div className="question-input">
          <label htmlFor="points">Points</label>
          <select
            id="points"
            name="points"
            required
            value={questionData.points}
            onChange={handleChange}
          >
            <option value="">Choose points...</option>
            {points.map((point, idx) => (
              <option value={point} key={idx}>
                {point}
              </option>
            ))}
          </select>
        </div>

        <div className="question-input">
          <label htmlFor="statement">Statement</label>
          <textarea
            id="statement"
            name="statement"
            rows="3"
            required
            value={questionData.statement}
            placeholder="Add question statement..."
            onChange={handleChange}
          />
        </div>

        <div className="question-input">
          <label htmlFor="inputs">
            Inputs
            {questionData.inputs.length < 4 && (
              <button
                type="button"
                className="circle-btn"
                onClick={handleAddInput}
              >
                <FaPlus />
              </button>
            )}
          </label>
          <div id="inputs" className="question-input__inputs">
            {questionData.inputs.map((input, idx) => (
              <div key={idx} className="inputs__option">
                <input
                  type="text"
                  value={input}
                  placeholder="Add data input..."
                  onChange={(evt) => handleInputChange(evt, idx)}
                />
                <button type="button" onClick={() => handleRemoveInput(idx)}>
                  <FaTimes />
                </button>
              </div>
            ))}
          </div>
        </div>

        <div className="question-input">
          <label htmlFor="image">Image URL</label>
          <input
            id="image"
            name="image"
            type="file"
            onChange={(e) =>
              setQuestionData((prev) => ({
                ...prev,
                image: `/img/${e.target.files[0].name}`,
              }))
            }
          />
        </div>

        <div className="question-input">
          <label htmlFor="extra">Extra</label>
          <input
            id="extra"
            name="extra"
            type="text"
            value={questionData.extra}
            placeholder="Add extra information..."
            onChange={handleChange}
          />
        </div>

        <div className="question-input">
          <label htmlFor="note">Notes</label>
          <input
            id="note"
            name="note"
            type="text"
            value={questionData.note}
            placeholder="Add question note..."
            onChange={handleChange}
          />
        </div>

        <div className="question-input">
          <label htmlFor="options">
            Options
            {questionData.options.length < 10 && (
              <button
                type="button"
                className="circle-btn"
                onClick={handleAddOption}
              >
                <FaPlus />
              </button>
            )}
          </label>
          <div id="options" className="question-input__options">
            {questionData.options.map((option, idx) => (
              <div
                id={`option-${idx}`}
                key={`option-${idx}`}
                className="options__option"
              >
                <span>{idx + 1}. </span>
                {questionData.type === "radio" && (
                  <input
                    type="text"
                    name="value"
                    value={option.value}
                    placeholder="Add value..."
                    onChange={(evt) => handleOptionChange(evt, idx)}
                  />
                )}
                {questionData.type !== "radio" &&
                  questionData.type !== "checkbox" && (
                    <input
                      type="text"
                      name="prefix"
                      value={option.prefix}
                      placeholder="Add prefix..."
                      onChange={(evt) => handleOptionChange(evt, idx)}
                    />
                  )}
                <OptionAnswerInput
                  idx={idx}
                  type={questionData.type}
                  answer={questionData.answer}
                  option={option}
                  onOptionAnswerChange={(data) =>
                    handleOptionAnswerChange(data, idx)
                  }
                  onOptionSelectChange={(select) =>
                    handleOptionSelect(select, idx)
                  }
                />
                <input
                  type="text"
                  name="label"
                  value={option.label}
                  placeholder="Add label..."
                  onChange={(evt) => handleOptionChange(evt, idx)}
                />
                {(((questionData.type === "radio" ||
                  questionData.type === "checkbox") &&
                  questionData.options.length > 2) ||
                  (questionData.type !== "radio" &&
                    questionData.type !== "checkbox" &&
                    questionData.options.length > 1)) && (
                  <button
                    type="button"
                    className="circle-btn option-remove"
                    onClick={() => handleRemoveOption(idx)}
                  >
                    <FaTimes />
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>

        {message && <div className="msg-info">{message}</div>}
        {errors.length > 0 && (
          <div className="msg-error">
            {errors.map((error, idx) => (
              <p key={idx}>{error}</p>
            ))}
          </div>
        )}

        <button type="submit" className="btn">
          {id === "new" ? "Add question" : "Save changes"}
        </button>
      </form>
      <br />
      <h2>Sample</h2>
      {questionData && (
        <QuestionCard
          question={{ _id: id, ...questionData }}
          i={location?.state?.count}
        />
      )}
    </div>
  );
};

export default AddEditQuestion;
