import "./updatephotomodal.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearMessage,
  updatePhoto,
} from "../../../app/features/user/userSlice";
import { FaCamera, FaCrop, FaTimes, FaUpload } from "react-icons/fa";

const UpdatePhotoModal = ({ profile }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [photo, setPhoto] = useState(null);
  const { user, message } = useSelector((state) => state.users);

  useEffect(() => {
    setShow(false);
  }, [user.photo]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(clearMessage());
    }, 2000);
  }, [message, dispatch]);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    resetCanvas();
    setPhoto(null);
    setShow(true);
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    const canvas = document.querySelector("canvas");
    canvas.toBlob((blob) => {
      const imageFile = new File([blob], "photo.png", { type: "image/png" });
      const formData = new FormData();
      photo && formData.append("photo", imageFile);
      dispatch(updatePhoto({ id: profile._id, photo: formData }));
    }, "image/png");
  };

  const resetCanvas = () => {
    const canvas = document.querySelector("canvas");
    canvas.width = 300;
    canvas.height = 300;
  };

  const handleNewFile = (e) => {
    if (!e.target.files[0]) return;

    setPhoto(e.target.files[0]);

    const canvas = document.querySelector("canvas");
    const ctx = canvas.getContext("2d");
    const image = new Image();
    image.src = URL.createObjectURL(e.target.files[0]);
    // See individual pixels when zooming
    ctx.imageSmoothingEnabled = false;
    image.onload = () => {
      drawImageToCanvas();
    };

    const drawImageToCanvas = () => {
      // get the scale
      const scale = Math.max(
        canvas.width / image.width,
        canvas.height / image.height
      );
      // get the top left position of the image
      const x = canvas.width / 2 - (image.width / 2) * scale;
      const y = canvas.height / 2 - (image.height / 2) * scale;
      ctx.save();
      ctx.setTransform(1, 0, 0, 1, 0, 0);
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.restore();
      ctx.drawImage(image, x, y, image.width * scale, image.height * scale);
    };

    const getTransformedPoint = (x, y) => {
      const invTransform = ctx.getTransform().invertSelf();
      const transX = invTransform.a * x + invTransform.c * y + invTransform.e;
      const transY = invTransform.b * x + invTransform.d * y + invTransform.f;
      return { x: transX, y: transY };
    };

    const getTouchesDistance = (touch0, touch1) => {
      const dx = touch1.pageX - touch0.pageX;
      const dy = touch1.pageY - touch0.pageY;
      return Math.sqrt(dx * dx + dy * dy);
    };

    let isDragging = false;
    let startPos = { x: 0, y: 0 };
    let currentPos;
    let startD = 0;
    let currentD;

    //TOUCH START FUNCTION
    const onTouchStart = (event) => {
      event.preventDefault();
      if (event.touches.length >= 2) {
        startD = getTouchesDistance(event.touches[0], event.touches[1]);
      } else {
        isDragging = true;
        startPos = getTransformedPoint(
          event.touches[0].pageX,
          event.touches[0].pageY
        );
      }
    };

    //TOUCH MOVE FUNCTION
    const onTouchMove = (event) => {
      event.preventDefault();
      if (event.touches.length >= 2) {
        currentD = getTouchesDistance(event.touches[0], event.touches[1]);
        currentPos = getTransformedPoint(
          event.touches[0].pageX +
            (event.touches[1].pageX - event.touches[0].pageX) / 2,
          event.touches[0].pageY +
            (event.touches[1].pageY - event.touches[0].pageY) / 2
        );
        const deltaD = startD - currentD;
        const zoom = deltaD < 0 ? 1.01 : 0.99;
        ctx.translate(currentPos.x, currentPos.y);
        ctx.scale(zoom, zoom);
        ctx.translate(-currentPos.x, -currentPos.y);
        drawImageToCanvas();
      } else {
        currentPos = getTransformedPoint(
          event.touches[0]?.pageX,
          event.touches[0]?.pageY
        );
        if (isDragging) {
          ctx.translate(currentPos.x - startPos.x, currentPos.y - startPos.y);
          drawImageToCanvas();
        }
      }
    };

    //TOUCH END FUNCTION
    function onTouchEnd() {
      isDragging = false;
    }

    //////////////////////////////////////////////////////////

    //MOUSE DOWN FUNCTION
    const onMouseDown = (event) => {
      isDragging = true;
      startPos = getTransformedPoint(event.offsetX, event.offsetY);
    };

    //MOUSE MOVE FUNCTION
    const onMouseMove = (event) => {
      currentPos = getTransformedPoint(event.offsetX, event.offsetY);
      if (isDragging) {
        ctx.translate(currentPos.x - startPos.x, currentPos.y - startPos.y);
        drawImageToCanvas();
      }
    };

    //MOUSE UP FUNCTION
    const onMouseUp = () => {
      isDragging = false;
    };

    //WHEEL MOVE FUNCTION
    const onWheelZoom = (event) => {
      event.preventDefault();
      const zoom = event.deltaY < 0 ? 1.05 : 0.95;
      ctx.translate(currentPos.x, currentPos.y);
      ctx.scale(zoom, zoom);
      ctx.translate(-currentPos.x, -currentPos.y);
      drawImageToCanvas();
    };

    //touch move on mobile
    canvas.addEventListener("touchstart", onTouchStart);
    canvas.addEventListener("touchmove", onTouchMove);
    canvas.addEventListener("touchend", onTouchEnd);
    canvas.addEventListener("touchcancel", onTouchEnd);
    //mouse move on desktop
    canvas.addEventListener("mousedown", onMouseDown);
    canvas.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);
    canvas.addEventListener("wheel", onWheelZoom);
  };

  return (
    <>
      <button
        className="circle-btn absolute"
        onClick={handleShow}
        title={profile.photo?.source ? "Change photo" : "Add photo"}
      >
        <FaCamera />
      </button>

      <div className={show ? "modal show" : "modal"}>
        <div className="modal-container">
          <div className="modal-content">
            <div className="modal-header">
              <h2>
                {profile.photo?.source ? "Change" : "Add"} profile picture
              </h2>
              <button className="close-btn" onClick={handleClose}>
                <FaTimes />
              </button>
            </div>
            <div className="modal-body">
              {message && <div className="message">{message}</div>}
              <form className="auth-form" onSubmit={(e) => handleUpdate(e)}>
                <div className="input-group">
                  <label htmlFor="photo" className="file-upload">
                    <FaCamera />
                  </label>
                  <input
                    id="photo"
                    type="file"
                    name="photo"
                    required
                    onChange={(e) => handleNewFile(e)}
                    accept="image/jpeg, image/jpg, image/png, image/gif"
                  />
                </div>
                <canvas id="canvas" width="300" height="300"></canvas>
                {photo && (
                  <p className="muted">
                    <FaCrop /> Zoom and drag the image to get the best fit.
                  </p>
                )}
                <button type="submit" className="btn">
                  <FaUpload /> Upload profile picture
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdatePhotoModal;
