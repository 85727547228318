import "./support.css";
import { useState } from "react";
import { FaRadiationAlt } from "react-icons/fa";

const Barium = () => {
  const makeSource = (Date, Time, LE, HE, E356, TOTAL) => {
    return {
      Date: Date,
      Time: Time,
      LE: LE,
      HE: HE,
      E356: E356,
      TOTAL: TOTAL,
    };
  };

  const [lastDate, setLastDate] = useState("");
  const [lastTime, setLastTime] = useState("");
  const [lastLE, setLastLE] = useState("");
  const [lastHE, setLastHE] = useState("");
  const [lastE356, setLastE356] = useState("");
  const [lastTOTAL, setLastTOTAL] = useState("");

  const [currentDate, setCurrentDate] = useState("");
  const [currentTime, setCurrentTime] = useState("");
  const [currentLE, setCurrentLE] = useState("");
  const [currentHE, setCurrentHE] = useState("");
  const [currentE356, setCurrentE356] = useState("");
  const [currentTOTAL, setCurrentTOTAL] = useState("");

  const [expectedTitle, setExpectedTitle] = useState("");
  const [expectedValues, setExpectedValues] = useState({});

  const [variationTitle, setVariationTitle] = useState("");
  const [variationValues, setVariationValues] = useState({});

  const [message, setMessage] = useState([]);
  const [variant, setVariant] = useState("");
  const [expGroupOutput, setExpGroupOutput] = useState("");
  const [expOutputTitle, setExpOutputTitle] = useState("");
  const [expOutput, setExpOutput] = useState("");
  const [varGroupOutput, setVarGroupOutput] = useState("");
  const [varOutputTitle, setVarOutputTitle] = useState("");
  const [varOutput, setVarOutput] = useState("");
  const [srcLife, setSrcLife] = useState(0); //srcLife variable is required to set the message className dynamically to "msg-warning" or otherwise
  const [currentVal, setCurrentVal] = useState(false);

  const bariumSource = (e) => {
    e.preventDefault();
    let messages = [];
    if (!currentDate || !currentTime)
      messages.push("Actual EP date and time are required.");
    if (!lastDate || !lastTime)
      messages.push("Last EP / FAT Ref. date and time are required.");
    if (!lastLE) messages.push("Last EP / FAT Ref. LE is required.");
    if (!lastHE) messages.push("Last EP / FAT Ref. HE is required.");
    if (!lastE356) messages.push("Last EP / FAT Ref. E356 is required.");
    if (!lastTOTAL) messages.push("Last EP / FAT Ref. TOTAL is required.");
    if (messages.length > 0) {
      setVariant("msg-error w300");
      setMessage(messages);
      setSrcLife(0);

      setExpGroupOutput("");
      setExpOutputTitle("");
      setExpOutput("");
      setExpectedTitle("");
      setExpectedValues({});

      setVarGroupOutput("");
      setVarOutputTitle("");
      setVarOutput("");
      setVariationTitle("");
      setVariationValues({});
    } else {
      if (Number(lastLE) <= 0)
        messages.push("Last EP / FAT Ref. LE. must be greater than 0");
      if (Number(lastHE) <= 0)
        messages.push("Last EP / FAT Ref. HE must be greater than 0.");
      if (Number(lastE356) <= 0)
        messages.push("Last EP / FAT Ref. E356 must be greater than 0.");
      if (Number(lastTOTAL) <= 0)
        messages.push("Last EP / FAT Ref. TOTAL must be greater than 0.");
      if (messages.length > 0) {
        setVariant("msg-warning w300");
        setMessage(messages);
        setSrcLife(0);

        setExpGroupOutput("");
        setExpOutputTitle("");
        setExpOutput("");
        setExpectedTitle("");
        setExpectedValues({});

        setVarGroupOutput("");
        setVarOutputTitle("");
        setVarOutput("");
        setVariationTitle("");
        setVariationValues({});
      } else {
        const last = makeSource(
          lastDate,
          lastTime,
          lastLE,
          lastHE,
          lastE356,
          lastTOTAL
        );
        const current = makeSource(
          currentDate,
          currentTime,
          currentLE,
          currentHE,
          currentE356,
          currentTOTAL
        );
        //calculating source life in hrs from the last date and time to now
        const life =
          (Date.now() - Date.parse(last.Date + "T" + last.Time)) / 3600000;
        setSrcLife(life); //srcLife variable is required to set the message className dynamically to "msg-warning" or otherwise
        if (life >= 91980)
          messages.push(
            "This Ba-133 source has exceeded its half life (~10.5 years) and it should be replaced."
          );
        //console.log(life)
        //calculate expected values
        const expected = makeSource(
          currentDate,
          currentTime,
          Math.round(
            10 *
              last.LE *
              Math.exp(
                (-Math.LN2 *
                  (Date.parse(current.Date + "T" + current.Time) -
                    Date.parse(last.Date + "T" + last.Time))) /
                  (3600000 * 91980)
              )
          ) / 10,
          Math.round(
            10 *
              last.HE *
              Math.exp(
                (-Math.LN2 *
                  (Date.parse(current.Date + "T" + current.Time) -
                    Date.parse(last.Date + "T" + last.Time))) /
                  (3600000 * 91980)
              )
          ) / 10,
          Math.round(
            10 *
              last.E356 *
              Math.exp(
                (-Math.LN2 *
                  (Date.parse(current.Date + "T" + current.Time) -
                    Date.parse(last.Date + "T" + last.Time))) /
                  (3600000 * 91980)
              )
          ) / 10,
          Math.round(
            10 *
              last.TOTAL *
              Math.exp(
                (-Math.LN2 *
                  (Date.parse(current.Date + "T" + current.Time) -
                    Date.parse(last.Date + "T" + last.Time))) /
                  (3600000 * 91980)
              )
          ) / 10
        );
        //formatting expected value outputs
        const expected2 = { ...expected };
        for (let key in expected2) {
          expected2[key] = <input value={expected2[key]} disabled />;
        }
        if (!currentLE || !currentHE || !currentE356 || !currentTOTAL) {
          setCurrentVal(false);
          messages.push(
            `These are the expected theoritical decay values of a Ba-133 source at the current date ${expected.Date} @ ${expected.Time}.`
          );
          messages.push(
            "If you wish to check this source status, Please provide the Actual Empty Pipe energies levels."
          );
          if (!currentLE) messages.push("Actual EP LE is required.");
          if (!currentHE) messages.push("Actual EP HE is required.");
          if (!currentE356) messages.push("Actual EP E356 is required.");
          if (!currentTOTAL) messages.push("Actual EP TOTAL is required.");

          setExpGroupOutput("group-input");
          setExpOutputTitle("div-input div-title");
          setExpOutput("div-input");
          setExpectedTitle("Expected Values");
          setExpectedValues(expected2);

          setVarGroupOutput("");
          setVarOutputTitle("");
          setVarOutput("");
          setVariationTitle("");
          setVariationValues({});

          setVariant("msg-info w450");
          setMessage(messages);
        } else {
          if (Number(currentLE) <= 0)
            messages.push("Actual EP LE must be greater than 0.");
          if (Number(currentHE) <= 0)
            messages.push("Actual EP HE must be greater than 0.");
          if (Number(currentE356) <= 0)
            messages.push("Actual EP E356 must be greater than 0.");
          if (Number(currentTOTAL) <= 0)
            messages.push("Actual EP TOTAL must be greater than 0.");
          if (life < 91980 ? messages.length > 0 : messages.length > 1) {
            setCurrentVal(false);

            setExpGroupOutput("group-input");
            setExpOutputTitle("div-input div-title");
            setExpOutput("div-input");
            setExpectedTitle("Expected Values");
            setExpectedValues(expected2);

            setVarGroupOutput("");
            setVarOutputTitle("");
            setVarOutput("");
            setVariationTitle("");
            setVariationValues({});

            setVariant("msg-warning w450");
            setMessage(messages);
          } else {
            setCurrentVal(true);
            //calculation of variation values in case the user provide actual/current cvalues
            const variations = makeSource(
              currentDate,
              currentTime,
              Math.round(
                (1000 * Math.abs(expected.LE - current.LE)) / expected.LE
              ) / 10,
              Math.round(
                (1000 * Math.abs(expected.HE - current.HE)) / expected.HE
              ) / 10,
              Math.round(
                (1000 * Math.abs(expected.E356 - current.E356)) / expected.E356
              ) / 10,
              Math.round(
                (1000 * Math.abs(expected.TOTAL - current.TOTAL)) /
                  expected.TOTAL
              ) / 10
            );
            if (
              variations.LE <= 5 &&
              variations.HE <= 5 &&
              variations.E356 <= 5 &&
              variations.TOTAL <= 5
            ) {
              setVariant("msg-success w600");
              messages.push(
                "This Ba-133 source has a normal decay as theoritically expected."
              );
            } else {
              setVariant("msg-warning w600");
              messages.push(
                "This Ba-133 source is not decaying as normally expected."
              );
              messages.push(
                "You should consider replacing this source as soon as possible...!"
              );
            }
            const variations2 = {
              Date: <input value={variations.Date} disabled />,
              Time: <input value={variations.Time} disabled />,
              LE: (
                <input
                  className={variations.LE <= 5 ? "var-success" : "var-error"}
                  value={`${variations.LE}%`}
                  disabled
                />
              ),
              HE: (
                <input
                  className={variations.HE <= 5 ? "var-success" : "var-error"}
                  value={`${variations.HE}%`}
                  disabled
                />
              ),
              E356: (
                <input
                  className={variations.E356 <= 5 ? "var-success" : "var-error"}
                  value={`${variations.E356}%`}
                  disabled
                />
              ),
              TOTAL: (
                <input
                  className={
                    variations.TOTAL <= 5 ? "var-success" : "var-error"
                  }
                  value={`${variations.TOTAL}%`}
                  disabled
                />
              ),
            };
            setExpGroupOutput("group-input");
            setExpOutputTitle("div-input div-title");
            setExpOutput("div-input");
            setExpectedTitle("Expected Values");
            setExpectedValues(expected2);

            setVarGroupOutput("group-input");
            setVarOutputTitle("div-input div-title");
            setVarOutput("div-input");
            setVariationTitle("Variations Values");
            setVariationValues(variations2);

            setMessage(messages);
          }
        }
      }
    }
  };

  return (
    <section id="barium">
      <div className="container">
        <div className="section-title">
          <h1>
            <FaRadiationAlt /> Barium-133 Source Decay Calculator
          </h1>
        </div>
        <div className="service-card">
          <div className="service-image">
            <img src="../img/source.jpg" alt="barium-133 source" />
          </div>
          <div className="service-form">
            <form>
              <div
                className={
                  srcLife < 91980
                    ? variant
                    : `msg-warning ${currentVal ? "w600" : "w450"}`
                }
              >
                {message.map((el, i) => (
                  <div className="message-div" key={i}>{`${i + 1}. ${el}`}</div>
                ))}
              </div>
              <div className="input-container">
                <div className="group-input">
                  <div className="div-input div-title">
                    <span>Last EP / FAT Ref.</span>
                  </div>
                  <div className="div-input">
                    <input
                      type="date"
                      value={lastDate}
                      onChange={(e) => setLastDate(e.target.value)}
                    />
                  </div>
                  <div className="div-input">
                    <input
                      type="time"
                      value={lastTime}
                      onChange={(e) => setLastTime(e.target.value)}
                    />
                  </div>
                  <div className="div-input">
                    <input
                      type="number"
                      value={lastLE}
                      placeholder="FAT Ref. LE"
                      onChange={(e) => setLastLE(e.target.value)}
                    />
                  </div>
                  <div className="div-input">
                    <input
                      type="number"
                      value={lastHE}
                      placeholder="FAT Ref. HE"
                      onChange={(e) => setLastHE(e.target.value)}
                    />
                  </div>
                  <div className="div-input">
                    <input
                      type="number"
                      value={lastE356}
                      placeholder="FAT Ref. 356"
                      onChange={(e) => setLastE356(e.target.value)}
                    />
                  </div>
                  <div className="div-input">
                    <input
                      type="number"
                      value={lastTOTAL}
                      placeholder="FAT Ref. TOTAL"
                      onChange={(e) => setLastTOTAL(e.target.value)}
                    />
                  </div>
                </div>
                <div className="group-input">
                  <div className="div-input div-title">
                    <span>Actual EP Values</span>
                  </div>
                  <div className="div-input">
                    <input
                      type="date"
                      value={currentDate}
                      onChange={(e) => setCurrentDate(e.target.value)}
                    />
                  </div>
                  <div className="div-input">
                    <input
                      type="time"
                      value={currentTime}
                      onChange={(e) => setCurrentTime(e.target.value)}
                    />
                  </div>
                  <div className="div-input">
                    <input
                      type="number"
                      value={currentLE}
                      placeholder="Actual LE"
                      onChange={(e) => setCurrentLE(e.target.value)}
                    />
                  </div>
                  <div className="div-input">
                    <input
                      type="number"
                      value={currentHE}
                      placeholder="Actual HE"
                      onChange={(e) => setCurrentHE(e.target.value)}
                    />
                  </div>
                  <div className="div-input">
                    <input
                      type="number"
                      value={currentE356}
                      placeholder="Actual 356"
                      onChange={(e) => setCurrentE356(e.target.value)}
                    />
                  </div>
                  <div className="div-input">
                    <input
                      type="number"
                      value={currentTOTAL}
                      placeholder="Actual TOTAL"
                      onChange={(e) => setCurrentTOTAL(e.target.value)}
                    />
                  </div>
                </div>
                <div className={expGroupOutput}>
                  <div className={expOutputTitle}>
                    <span>{expectedTitle}</span>
                  </div>
                  <div className={expOutput}>{expectedValues.Date}</div>
                  <div className={expOutput}>{expectedValues.Time}</div>
                  <div className={expOutput}>{expectedValues.LE}</div>
                  <div className={expOutput}>{expectedValues.HE}</div>
                  <div className={expOutput}>{expectedValues.E356}</div>
                  <div className={expOutput}>{expectedValues.TOTAL}</div>
                </div>
                <div className={varGroupOutput}>
                  <div className={varOutputTitle}>
                    <span>{variationTitle}</span>
                  </div>
                  <div className={varOutput}>{variationValues.Date}</div>
                  <div className={varOutput}>{variationValues.Time}</div>
                  <div className={varOutput}>{variationValues.LE}</div>
                  <div className={varOutput}>{variationValues.HE}</div>
                  <div className={varOutput}>{variationValues.E356}</div>
                  <div className={varOutput}>{variationValues.TOTAL}</div>
                </div>
              </div>
              <div className="button-container">
                <button className="btn" onClick={(e) => bariumSource(e)}>
                  Check Source <FaRadiationAlt />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Barium;
